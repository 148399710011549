import * as React from 'react'
import { getInputBorderClassName, getInputClassName } from '../utils'
import {
  Border,
  HelperText,
  Label,
  LabeledHelperTextProps,
  Prefix,
  getInputPaddingLeft,
  getInputPaddingRight
} from '@toasttab/buffet-pui-text-base'
import { useUniqueId } from '@toasttab/buffet-utils'
import { SearchIcon } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { SharedInputProps } from '../types'

// https://doc.toasttab.com/internal/webexperience/buffet/index.html?path=/story/buffet-pui-text-input-searchinput--search-input-usage
export interface SearchInputProps
  extends TestIdentifiable,
    Omit<LabeledHelperTextProps, 'helperText' | 'helperIconButton'>,
    Omit<SharedInputProps, 'prefix' | 'prefixVariant' | 'changed'>,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  /** Whether or not the form input is disabled */
  disabled?: boolean
  name?: string
  label?: React.ReactNode
  icon?: React.ReactNode
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  function SearchInput(
    {
      name,
      id,
      label,
      onChange,
      onBlur,
      value,
      required,
      containerClassName,
      className,
      style,
      disabled,
      suffix,
      suffixVariant = 'iconButton',
      size = 'auto',
      autoComplete,
      type = 'text',
      role = 'searchbox',
      placeholder,
      invalid,
      errorText,
      testId,
      icon = <SearchIcon accessibility='decorative' />,
      ...restProps
    },
    ref
  ) {
    const uniqueTestId = useUniqueId(testId, 'search-input-')
    const uniqueId = useUniqueId(id, 'search-input-')

    const prefixProps = {
      prefix: icon || undefined,
      prefixVariant: icon ? Prefix.Variant.icon : undefined
    }
    return (
      <div className={containerClassName}>
        <Label name={uniqueId} disabled={disabled} required={required}>
          {label}
        </Label>
        <Border
          disabled={disabled}
          suffix={suffix}
          invalid={invalid}
          suffixVariant={suffixVariant}
          roundedFull
          testId={uniqueTestId}
          className={getInputBorderClassName({ size })}
          {...prefixProps}
        >
          <input
            ref={ref}
            id={uniqueId}
            name={name}
            data-testid={uniqueTestId}
            placeholder={placeholder}
            style={{
              ...style,
              paddingLeft: getInputPaddingLeft(prefixProps),
              paddingRight: getInputPaddingRight({
                suffix,
                suffixVariant
              })
            }}
            className={getInputClassName({
              disabled,
              suffix,
              suffixVariant,
              className,
              roundedClassName: 'rounded-full',
              ...prefixProps
            })}
            disabled={disabled}
            autoComplete={autoComplete}
            type={type}
            role={role}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            aria-invalid={invalid}
            required={required}
            aria-required={required}
            {...restProps}
          />
        </Border>
        <HelperText
          testId={`${uniqueTestId}-helper-text`}
          disabled={disabled}
          invalid={invalid}
          errorText={errorText}
        />
      </div>
    )
  }
)

SearchInput.displayName = 'SearchInput'
