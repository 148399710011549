import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek
} from '@toasttab/buffet-pui-date-utilities'

const defines = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1))
}

const staticRangeKeys = {
  today: 'today',
  yesterday: 'yesterday',
  thisWeek: 'this-week',
  lastWeek: 'last-week',
  thisMonth: 'this-month',
  lastMonth: 'last-month'
}

export const defaultStaticRanges = [
  {
    label: staticRangeKeys.today,
    range: {
      from: defines.startOfToday,
      to: defines.endOfToday
    }
  },
  {
    label: staticRangeKeys.yesterday,
    range: {
      from: defines.startOfYesterday,
      to: defines.endOfYesterday
    }
  },

  {
    label: staticRangeKeys.thisWeek,
    range: {
      from: defines.startOfWeek,
      to: defines.endOfWeek
    }
  },
  {
    label: staticRangeKeys.lastWeek,
    range: {
      from: defines.startOfLastWeek,
      to: defines.endOfLastWeek
    }
  },
  {
    label: staticRangeKeys.thisMonth,
    range: {
      from: defines.startOfMonth,
      to: defines.endOfMonth
    }
  },
  {
    label: staticRangeKeys.lastMonth,
    range: {
      from: defines.startOfLastMonth,
      to: defines.endOfLastMonth
    }
  }
]
