import { useMemo, useEffect } from 'react'
import { usePageItemsQuery } from '@local/api'
import { useCompanyCode } from '@toasttab/ec-session'
import { useTranslation } from '@local/translations'
import { useEcTeamSpaCompanyLocalStorageState } from './useEcTeamSpaCompanyLocalStorageState'
import map from 'lodash/map'
import each from 'lodash/each'
import { NavItem } from './types'

// recursively get all of the items by path, from a (nested) list of items
const getItemsByPath = (item: NavItem | undefined, prop: 'name' | 'path') => {
  const result: Record<string, NavItem> = {}

  if (!item) return result

  if (prop in item) {
    result[item[prop]] = item
  }

  each(item.children, (child) => {
    Object.assign(result, getItemsByPath(child, prop))
  })

  return result
}

type Param = { key: string; value: string }

const formatParams = (args: Record<string, string | undefined | null>) => {
  const result: Param[] = []

  map(args, (value, key) => {
    if (value) result.push({ key, value })
  })

  return result
}

export function useTeamPageItems() {
  const companyCode = useCompanyCode()

  const { language } = useTranslation()

  const variables = {
    input: {
      params: formatParams({ companyCode }),
      path: 'team'
    }
  }
  const query = usePageItemsQuery({
    variables,
    context: {
      headers: {
        'Accept-Language': language
      }
    }
  })

  const data = query.data

  useEffect(() => {
    if (data) {
      query.refetch(variables)
    }
  }, [language]) // eslint-disable-line react-hooks/exhaustive-deps

  const [
    fallbackNavItem, //
    setFallbackNavItem
  ] = useEcTeamSpaCompanyLocalStorageState('last-team-navigation-item')

  const loadedTeamNavItem = data?.navigation.result

  const teamNavItem = loadedTeamNavItem || fallbackNavItem || undefined

  useEffect(() => {
    if (loadedTeamNavItem) setFallbackNavItem(loadedTeamNavItem)
  }, [loadedTeamNavItem]) // eslint-disable-line react-hooks/exhaustive-deps

  // todo: convert to `label` once change is made in the graph
  const teamLabel = teamNavItem?.labelNew

  const { url: teamUrl } = teamNavItem || {}

  const teamPagesByName = useMemo(() => {
    return getItemsByPath(teamNavItem, 'name')
  }, [teamNavItem])

  const teamPagesByPath = useMemo(() => {
    return getItemsByPath(teamNavItem, 'path')
  }, [teamNavItem])

  return {
    teamPagesByName,
    teamPagesByPath,
    teamNavItem,
    teamUrl,
    teamLabel,
    query
  }
}
