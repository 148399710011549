import { getUnixTime } from '@toasttab/buffet-pui-date-utilities'
import * as React from 'react'
import { RowProps, useDayPicker } from 'react-day-picker'
import { BuffetDay } from './BuffetDay'

export function BuffetRow(props: RowProps): JSX.Element {
  const {
    styles,
    classNames,
    showWeekNumber,
    components: { WeekNumber, Day = BuffetDay } = {}
  } = useDayPicker()

  let weekNumberCell
  if (showWeekNumber && WeekNumber) {
    weekNumberCell = (
      <td className={classNames.cell} style={styles.cell}>
        <WeekNumber number={props.weekNumber} dates={props.dates} />
      </td>
    )
  }

  return (
    <tr className={classNames.row} style={styles.row}>
      {weekNumberCell}
      {props.dates.map((date, index) => (
        <td
          className={classNames.cell}
          style={styles.cell}
          key={getUnixTime(date)}
        >
          <Day
            displayMonth={props.displayMonth}
            date={date}
            isFirstInWeek={index === 0}
            isLastInWeek={index === props.dates.length - 1}
          />
        </td>
      ))}
    </tr>
  )
}
