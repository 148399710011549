import { EmployeeTab } from './types'

export type Url = string | undefined

const getRedirectUrl = async (url: Url) => {
  if (!url) return undefined
  const resp = await fetch(url)

  if (resp.redirected) {
    const redirectUrl = resp.url
    const base = window.location.protocol + '//' + window.location.host
    if (redirectUrl.startsWith(base)) {
      return redirectUrl.slice(base.length)
    }
  }

  return undefined
}

type Tab = EmployeeTab | undefined

const getEmployeeTabUrl = (redirectUrl: Url, employeeTab: Tab) => {
  if (!redirectUrl) return undefined

  if (
    !employeeTab ||
    employeeTab === '/employment' ||
    employeeTab === '/profile'
  ) {
    return redirectUrl
  }

  if (redirectUrl.endsWith('/employment')) {
    return redirectUrl.replace('/employment', employeeTab)
  }

  if (redirectUrl.endsWith('/profile')) {
    return redirectUrl.replace('/profile', employeeTab)
  }

  return redirectUrl
}

export { getRedirectUrl, getEmployeeTabUrl }
