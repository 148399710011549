import { SelectOption } from '@toasttab/buffet-pui-select'

const PAGE_SIZES = [5, 10, 25, 50, 100, 200]
const PAGE_SIZE_OPTIONS: SelectOption<number>[] = PAGE_SIZES.map((size) => {
  return {
    label: `${size}`,
    value: size
  }
})

type PageSize = (typeof PAGE_SIZES)[number]

const DEFAULT_PAGE_SIZE = 25

type Filters = {
  page: number
  locationIds: number[]
  positionIds: number[]
  statusIds: number[]
  from: string
  until: string
  pageSize: PageSize
}

export { PAGE_SIZES, PAGE_SIZE_OPTIONS, DEFAULT_PAGE_SIZE }

export type { Filters }
