import * as React from 'react'
import {
  PortalManagement,
  FloatingContext,
  Overlay,
  useFloatingComponentZIndexClass
} from '@toasttab/buffet-pui-floating-ui-base'

interface ISelectListContainerContentsProps {
  isOpen: boolean
  inlineBlock?: boolean
  isFocusShadowDisabled?: boolean
  styles?: React.CSSProperties
  attributes?:
    | {
        [key: string]: string
      }
    | undefined
  testId?: string
  required?: boolean
  children: React.ReactNode
  setFloatingRef: FloatingContext<HTMLElement>['refs']['setReference']
  floatingStyles: React.CSSProperties
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement> | undefined
  ) => Record<string, unknown>
}

const SelectListContainerContents = ({
  testId,
  children,
  required,
  setFloatingRef,
  getFloatingProps,
  floatingStyles
}: Omit<ISelectListContainerContentsProps, 'isOpen'>) => {
  return (
    <div
      data-testid={testId}
      aria-required={required}
      ref={setFloatingRef}
      {...getFloatingProps({
        className:
          'focus:outline-none overflow-hidden border-none dropdown-base',
        style: {
          minWidth: '7.5rem',
          ...floatingStyles
        }
      })}
    >
      {children}
    </div>
  )
}

interface ISelectListContainerProps extends ISelectListContainerContentsProps {
  context: FloatingContext
  hasAdditionalActions?: boolean
}

export const SelectListContainer = ({
  context,
  hasAdditionalActions,
  testId = 'select-panel',
  isOpen,
  ...props
}: ISelectListContainerProps) => {
  const zIndexClass = useFloatingComponentZIndexClass() // elevation: z-40 for floating components (boosted to z-50 when in modals etc)

  if (!isOpen) {
    return null
  }

  return (
    <PortalManagement
      context={context}
      isModal={hasAdditionalActions}
      disableInitialFocus={hasAdditionalActions}
    >
      <Overlay className={zIndexClass} testId={`overlay-${testId}`} lockScroll>
        <SelectListContainerContents {...props} />
      </Overlay>
    </PortalManagement>
  )
}
