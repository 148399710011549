import * as React from 'react'
import { useMemo } from 'react'
import compact from 'lodash/compact'
import { DatePicker } from '@toasttab/buffet-pui-date-picker'
import { MenuDropdown } from '@toasttab/buffet-pui-dropdowns'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { MoreVertIcon } from '@toasttab/buffet-pui-icons'
import { DateTime } from 'luxon'
import ErrorBoundary from './ErrorBoundary'

type Range = {
  from: string
  until: string
}

type Props = {
  range: Range
  defaultRange: Range
  onRangeChange: (range: Range) => void
}

const useDate = (dateStr: string) => {
  return useMemo(() => {
    if (dateStr) return new Date(dateStr)
    return undefined
  }, [dateStr])
}

const pad = (val: string | number, len = 2) => `${val}`.padStart(len, '0')

const parseDate = (date: Date | undefined) => {
  if (!date) return ''
  const year = pad(date.getFullYear(), 4)
  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  return `${month}/${day}/${year}`
}

type Args = {
  now?: number
  range: Range
  defaultRange: Range
}

const format = (dt: DateTime | string) => {
  if (typeof dt === 'string') return dt
  return dt.toFormat('MM/dd/yyyy')
}

const getDefaults = ({ now, range, defaultRange }: Args) => {
  const defaultFrom = defaultRange.from
  const defaultUntil = defaultRange.until

  const today = DateTime.fromJSDate(new Date(now || Date.now()))

  const oneMonthAgo = today.minus({ months: 1 })

  const oneYearAgo = today.minus({ years: 1 })

  const fromDate = defaultFrom
    ? DateTime.fromJSDate(new Date(defaultFrom))
    : undefined

  const defaults = compact([
    {
      label: 'Today',
      from: today,
      until: today
    },
    {
      label: 'Yesterday',
      from: today.minus({ days: 1 }),
      until: today.minus({ days: 1 })
    },
    {
      label: 'Last 7 Days',
      from: today.minus({ days: 7 }),
      until: today
    },
    {
      label: 'Last 30 Days',
      from: today.minus({ days: 30 }),
      until: today
    },
    {
      label: `This Month (${today.monthLong})`,
      from: today.startOf('month'),
      until: today.endOf('month')
    },
    {
      label: `Last Month (${oneMonthAgo.monthLong})`,
      from: oneMonthAgo.startOf('month'),
      until: oneMonthAgo.endOf('month')
    },

    {
      label: `This Year (${today.year})`,
      from: today.startOf('year'),
      until: today.endOf('year')
    },
    {
      label: `Last Year (${oneYearAgo.year})`,
      from: oneYearAgo.startOf('year'),
      until: oneYearAgo.endOf('year')
    },
    fromDate && {
      label: `Initial year (${fromDate.year})`,
      from: fromDate,
      until: fromDate.plus({ years: 1 })
    },
    defaultFrom &&
      defaultUntil && {
        label: 'Default',
        from: defaultFrom,
        until: defaultUntil
      }
  ])

  return defaults.map((val) => {
    const from = format(val.from)
    const until = format(val.until)
    const isActive = from === range.from && until === range.until
    return { label: val.label, from, until, isActive }
  })
}

const useStableRange = (range: Range) => {
  const { from, until } = range
  return useMemo(() => ({ from, until }), [from, until])
}

const EmployeeListDateRangeSelect = (props: Props) => {
  const { onRangeChange } = props

  const range = useStableRange(props.range)
  const defaultRange = useStableRange(props.defaultRange)

  const defaults = useMemo(() => {
    return getDefaults({ range, defaultRange })
  }, [range, defaultRange])

  const fromDate = useDate(range.from)

  const untilDate = useDate(range.until)

  const onFromDateChange = (date: Date | undefined) => {
    onRangeChange({ from: parseDate(date), until: range.until })
  }

  const onUntilDateChange = (date: Date | undefined) => {
    onRangeChange({ from: range.from, until: parseDate(date) })
  }

  return (
    <div className='flex flex-row items-center justify-between'>
      <div>
        <span className='inline-block pr-4'>{`From `}</span>

        <DatePicker
          inlineBlock
          value={fromDate}
          onChange={onFromDateChange}
          mask='mm/dd/yyyy'
          size='auto'
        />

        <span className='inline-block px-4'>{` to `}</span>

        <DatePicker
          inlineBlock
          value={untilDate}
          onChange={onUntilDateChange}
          mask='mm/dd/yyyy'
          size='base'
        />
      </div>
      <MenuDropdown
        renderToggle={(props) => (
          <IconButton
            {...props}
            icon={<MoreVertIcon className='text-secondary' />}
          />
        )}
      >
        {defaults.map((val) => {
          const { label, from, until, isActive } = val
          return (
            <Button
              className='flex justify-between w-full text-default'
              key={label}
              variant='link'
              onClick={() => onRangeChange({ from, until })}
              disabled={isActive}
            >
              {label}
            </Button>
          )
        })}
      </MenuDropdown>
    </div>
  )
}

export default EmployeeListDateRangeSelect
