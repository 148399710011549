import trim from 'lodash/trim'
import toNumber from 'lodash/toNumber'
import map from 'lodash/map'
import compact from 'lodash/compact'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useMemo, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { PAGE_SIZES, DEFAULT_PAGE_SIZE } from './employee-list-filters-types'
import type { Filters } from './employee-list-filters-types'
import type { InitialFilters } from '../../../data/types'
import useEmployeeListFilterStorage from './useEmployeeListFilterStorage'
import { guardDate } from './guardDate'

function arrayWithDefault<T>(list: T[] | null | undefined, def: T[]): T[] {
  if (!list) return def
  if (!isEmpty(def) && isEmpty(list)) {
    return def
  }
  return list
}

const getIds = (str: string | null, defaults: number[] = []): number[] => {
  if (!str) return defaults
  const ids = str.split(',').map(trim).map(toNumber)
  return arrayWithDefault(ids, defaults)
}

const getString = (str: string | null) => str || ''

const getPage = (str: string | null) => toNumber(str) || 1

const getPageSize = (str: string | null) => {
  if (!str) return DEFAULT_PAGE_SIZE
  const num = toNumber(str)
  if (PAGE_SIZES.includes(num)) return num
  return DEFAULT_PAGE_SIZE
}

const formatSearch = (filters: Filters) => {
  const segments = map(filters, (val, key) => {
    const formattedVal = isArray(val) ? val.join(',') : `${val}`
    return formattedVal ? `${key}=${formattedVal}` : undefined
  })
  const str = compact(segments).join('&')
  return str ? `?${str}` : ''
}

const parseFilters = (partialFilters?: Partial<Filters>): Filters => {
  return {
    page: partialFilters?.page || 1,
    locationIds: partialFilters?.locationIds || [],
    positionIds: partialFilters?.positionIds || [],
    statusIds: partialFilters?.statusIds || [],
    from: partialFilters?.from || '',
    until: partialFilters?.until || '',
    pageSize: partialFilters?.pageSize || DEFAULT_PAGE_SIZE
  }
}

const fallbackFilters = parseFilters({ statusIds: [1] })

const getNumFiltersApplied = (fil: Filters, def: Filters) => {
  const date = fil.from !== def.from || fil.until !== def.until
  const loc = !isEqual(fil.locationIds, def.locationIds)
  const pos = !isEqual(fil.positionIds, def.positionIds)
  const status = !isEqual(fil.statusIds, def.statusIds)

  return compact([date, loc, pos, status]).length
}

type Args = {
  companyCode: string | undefined
  initialFilters: InitialFilters | undefined
}

const useEmployeeListFiltersState = (args: Args) => {
  const { companyCode, initialFilters } = args

  const { search } = useLocation()

  const [, setSearchParams] = useSearchParams()

  const { getStoredFilters, storeFilters } =
    useEmployeeListFilterStorage(companyCode)

  const parsedInitialFilters = useMemo(() => {
    if (initialFilters) {
      return {
        page: 1,
        locationIds: initialFilters.locationIds,
        positionIds: initialFilters.positionIds,
        statusIds: initialFilters.statusIds,
        from: guardDate(initialFilters.from),
        until: initialFilters.until,
        pageSize: DEFAULT_PAGE_SIZE
      }
    }
    return undefined
  }, [initialFilters])

  const assignedFilters = useMemo<Filters | undefined>(() => {
    if (search) {
      // since there are
      const urlSearch = new URLSearchParams(search)

      return parseFilters({
        page: getPage(urlSearch.get('page')),
        locationIds: getIds(urlSearch.get('locationIds')),
        positionIds: getIds(urlSearch.get('positionIds')),
        statusIds: getIds(urlSearch.get('statusIds')),
        from: getString(urlSearch.get('from')),
        until: getString(urlSearch.get('until')),
        pageSize: getPageSize(urlSearch.get('pageSize'))
      })
    }

    // since there was not search check session storage
    const storedFilters = getStoredFilters()

    if (storedFilters) {
      return parseFilters(storedFilters)
    }

    if (parsedInitialFilters) return parsedInitialFilters

    return undefined
  }, [search, getStoredFilters, parsedInitialFilters])

  const setFilters = (newFilters: Partial<Filters>) => {
    const fullFilters = { ...filters, page: 1, ...newFilters }
    storeFilters(fullFilters)
    setSearchParams(formatSearch(fullFilters))
  }

  useEffect(() => {
    if (!search && assignedFilters) {
      setSearchParams(formatSearch(assignedFilters), { replace: true })
    }
  }, [search, assignedFilters, setSearchParams])

  const clearFilters = () => {
    storeFilters(null)
    setSearchParams({})
  }

  const filters = assignedFilters || fallbackFilters

  const defaultFilters = parsedInitialFilters || fallbackFilters

  const numActiveFilters = getNumFiltersApplied(filters, defaultFilters)

  const canClearFilters = !isEqual(filters, defaultFilters)

  return {
    filters,
    setFilters,
    clearFilters,
    defaultFilters,
    numActiveFilters,
    canClearFilters
  }
}

export default useEmployeeListFiltersState
