import React from 'react'
import { MenuDropdown, NavLink, ListItem } from '@toasttab/buffet-pui-dropdowns'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AddIcon, ArrowDropDownIcon } from '@toasttab/buffet-pui-icons'
import { useFeature } from '@toasttab/ec-features'
import { useTeamPageItems, useTranslation } from '../../hooks'
import { getLabel } from '../../helpers'
import { NavItem } from '../../helpers/types'
import { useTracking } from '@toasttab/ec-track'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

const LOADING_INDICATOR_DURATION = 5000

type ItemProps =
  | {
      item: NavItem | undefined | null
      label?: string
    }
  | { onClick: () => void; label: string }

const Item = (props: ItemProps) => {
  const { label: stringLabel } = props

  if ('item' in props) {
    const { item } = props
    const to = item?.url || ''
    const displayLabel = stringLabel || getLabel(item)
    if (!to) {
      return (
        <ListItem
          disabled
          label={displayLabel}
          onResize={undefined}
          onResizeCapture={undefined}
        />
      )
    }
    return <NavLink to={to} label={displayLabel} external />
  }

  if ('onClick' in props) {
    return (
      <ListItem
        onResize={undefined}
        onResizeCapture={undefined}
        onClick={props.onClick}
        label={stringLabel}
      />
    )
  }

  return (
    <ListItem
      onResize={undefined}
      onResizeCapture={undefined}
      label={stringLabel}
      disabled
    />
  )
}

type Props = {
  isCompact?: boolean
  onOpenAddEmployeeModal?: () => void
  showTeamActionDropdown?: boolean
  isButtonVariantSecondary?: boolean
}

const TeamActions = (props: Props) => {
  const {
    isCompact,
    onOpenAddEmployeeModal,
    showTeamActionDropdown = true,
    isButtonVariantSecondary
  } = props
  const [isLoadingAddEmployeeModal, setIsLoadingAddEmployeeModal] =
    React.useState(false)

  const { teamPagesByName } = useTeamPageItems()

  const { t } = useTranslation()

  const hasNewAddEmployeeFF = useFeature('ec-elm-add-employee')
  const { track: onTrack } = useTracking()

  const hasNewAddEmployee = !!(hasNewAddEmployeeFF && onOpenAddEmployeeModal)

  const payChange = teamPagesByName['payChange']
  const incident = teamPagesByName['incident']
  const addEmployee = teamPagesByName['addEmployee']

  const teamActions = (
    <>
      <Item item={payChange} label={t('bulkPayChange')} />

      {incident && <Item item={incident} label={t('reportEmployeeIncident')} />}
    </>
  )

  const addEmployeeActions = (
    <>
      {hasNewAddEmployee ? (
        <Item
          onClick={() => {
            onTrack('add-employee.new-hire')
            onOpenAddEmployeeModal()
          }}
          label={t('addTeamMember')}
        />
      ) : (
        <Item item={addEmployee} label={t('addTeamMember')} />
      )}
    </>
  )

  if (isCompact) {
    return (
      <MenuDropdown
        renderToggle={(props) => (
          <Button
            size='sm'
            iconRight={<ArrowDropDownIcon accessibility='decorative' />}
            {...props}
          >
            {t('actions')}
          </Button>
        )}
      >
        {teamActions}
        {addEmployeeActions}
      </MenuDropdown>
    )
  }

  return (
    <div>
      {isLoadingAddEmployeeModal && (
        <MerryGoRound
          size='xxs'
          testId='team-actions-add-merrygoround'
          className='inline-block align-middle'
        />
      )}
      {showTeamActionDropdown && (
        <MenuDropdown
          renderToggle={(props) => (
            <Button
              variant='link'
              iconRight={<ArrowDropDownIcon accessibility='decorative' />}
              {...props}
            >
              {t('teamActions')}
            </Button>
          )}
        >
          {teamActions}
        </MenuDropdown>
      )}
      <Button
        variant={isButtonVariantSecondary ? 'secondary' : 'primary'}
        testId='team-actions-add'
        disabled={isLoadingAddEmployeeModal}
        iconLeft={<AddIcon accessibility='decorative' />}
        onClick={() => {
          if (hasNewAddEmployee) {
            setIsLoadingAddEmployeeModal(true)

            // there's no callback when the modal is loaded so we'll just use a timer to reset
            setTimeout(() => {
              setIsLoadingAddEmployeeModal(false)
            }, LOADING_INDICATOR_DURATION)

            onTrack('add-employee.new-hire')
            onOpenAddEmployeeModal()
          } else {
            //todo: replace with ecNavigate
            window.location.href = addEmployee?.url ?? ''
          }
        }}
      >
        {t('addTeamMember')}
      </Button>
    </div>
  )
}
export default TeamActions
