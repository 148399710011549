import { DateRange } from 'react-day-picker'
import { DefaultStaticRange } from '../DatePickerWithDefinedRanges'
import {
  DatePickerValueType,
  defaultDatePickerContext
} from './useDatePickerContext'
import React from 'react'
import { format, Formats, isSameDay } from '@toasttab/buffet-pui-date-utilities'
import { isFullSingleMonthDateRange } from '../utils'
import { t, DefaultStrings } from '../defaultStrings'

interface UseRangeDescriptionProps {
  value?: DatePickerValueType
  definedRanges?: DefaultStaticRange[]
  showDefinedRanges?: boolean
}

/**
 * A defined-range gets first priority for what should be shown in the
 * select button when a date range is selected, e.g. "This Week".
 * If there's no applicable defined range label for the currently selected
 * range then we can check if the range is a full week (Mon-Sun) or a
 * full month (1st - 31st etc), and if that's the case then we can use
 * a nicer label. Failing that, we can fall back to the default range label.
 */
export const useRangeDescription = ({
  value,
  definedRanges,
  showDefinedRanges
}: UseRangeDescriptionProps) =>
  React.useMemo(() => {
    const newValue = value as DateRange

    if (
      !definedRanges ||
      !showDefinedRanges ||
      !newValue ||
      (newValue && (!newValue?.from || !newValue?.to))
    ) {
      return defaultDatePickerContext.selectedRangeLabel
    }

    const definedRangeLabel = definedRanges.find(
      (d) =>
        newValue?.from &&
        isSameDay(d.range.from, newValue.from) &&
        newValue?.to &&
        isSameDay(d.range.to, newValue?.to)
    )?.label

    const translatedRangeLabel = t(definedRangeLabel as keyof DefaultStrings)

    // PreDefined range name from the list of selectable ranges
    if (translatedRangeLabel) return translatedRangeLabel

    // Month specific ranges "December 14th"
    if (isFullSingleMonthDateRange(value))
      return format(
        (value as DateRange).from as Date,
        Formats.date.long_without_days
      ) as string

    // Fallback
    return t('custom') // Default range label, translated
  }, [definedRanges, showDefinedRanges, value])
