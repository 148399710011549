import { useState } from 'react'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import { Select, SelectMultiple } from '@toasttab/buffet-pui-select'
import { Location, Position, Status } from '../../data/types'
import {
  EmployeeListModal,
  EmployeeListDateRangeSelect,
  Field
} from '../../components'
import { PAGE_SIZE_OPTIONS } from './useEmployeeListFiltersState/employee-list-filters-types'
import type { Filters } from './useEmployeeListFiltersState/employee-list-filters-types'
import { useTranslation } from '../../hooks'

type Props = {
  filters: Filters
  defaultFilters: Filters
  onCancel: () => void
  onApply: (filters: Filters) => void
  locations?: Location[]
  positions?: Position[]
  statuses?: Status[]
}

const EmployeeListFiltersModal = (props: Props) => {
  const {
    filters: currentFilters,
    defaultFilters,
    onCancel,
    onApply,
    locations,
    positions,
    statuses
  } = props

  const { t } = useTranslation()

  // this is a dumb trick to get the uncontrolled part of SelectMultiple to flush
  // this is likely fixed in a new version of the component
  const [key, setKey] = useState<number>(1)

  const [newFilters, setNewFilters] = useState<Filters>(() => ({
    ...currentFilters
  }))

  const locationOptions = map(locations, ({ id, name }) => {
    return { value: id, label: name }
  })

  const positionOptions = map(positions, ({ id, name }) => {
    return { value: id, label: name }
  })

  const statusOptions = map(statuses, ({ id, name }) => {
    return { value: id, label: name }
  })

  const defaultRange = {
    from: defaultFilters.from,
    until: defaultFilters.until
  }

  const canClearFilters = !isEqual(newFilters, defaultFilters)

  const handleApply = () => onApply(newFilters)
  const onClearFilters = () => {
    setNewFilters({ ...defaultFilters })
    setKey((key) => key + 1)
  }

  return (
    <EmployeeListModal
      title={t('filter')}
      onCancel={onCancel}
      onSubmit={handleApply}
      submitLabel={t('apply')}
      clearFilters={true}
      onClearFilters={onClearFilters}
      canClearFilters={canClearFilters}
    >
      <div className='h-4' />

      <Field label={t('startDate')}>
        <EmployeeListDateRangeSelect
          defaultRange={defaultRange}
          range={{ from: newFilters.from, until: newFilters.until }}
          onRangeChange={({ from, until }) => {
            setNewFilters({ ...newFilters, from, until })
          }}
        />
      </Field>

      <Field label={t('location')}>
        <SelectMultiple
          key={key}
          placeholder={t('selectLocations')}
          options={locationOptions}
          value={newFilters.locationIds}
          aria-label=''
          onChange={(val) => {
            const locationIds = (val || []) as Filters['locationIds']
            setNewFilters({ ...newFilters, locationIds })
          }}
        />
      </Field>

      <Field label={t('positions')}>
        <SelectMultiple
          key={key}
          placeholder={t('selectPositions')}
          options={positionOptions}
          value={newFilters.positionIds}
          aria-label=''
          onChange={(val) => {
            const positionIds = (val || []) as Filters['positionIds']
            setNewFilters({ ...newFilters, positionIds })
          }}
        />
      </Field>

      <Field label={t('status')}>
        <SelectMultiple
          key={key}
          placeholder={t('selectStatuses')}
          options={statusOptions}
          value={newFilters.statusIds}
          aria-label=''
          onChange={(val) => {
            const statusIds = (val || []) as Filters['statusIds']
            setNewFilters({ ...newFilters, statusIds })
          }}
        />
      </Field>

      <Field label={t('employeesPerPage')}>
        <Select
          placeholder={t('employeesPerPage')}
          options={PAGE_SIZE_OPTIONS}
          value={newFilters.pageSize}
          aria-label=''
          onChange={(val) => {
            const pageSize = (val || 25) as Filters['pageSize']
            setNewFilters({ ...newFilters, pageSize })
          }}
        />
      </Field>
    </EmployeeListModal>
  )
}

export default EmployeeListFiltersModal
