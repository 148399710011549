import * as React from 'react'
import { useState, useCallback } from 'react'
import TeamActions from './TeamActions'
import { useTeamPageItems, useTranslation } from '../../hooks'
import { EcPageHeader } from '@toasttab/ec-ui-page-header'
import { EcNavigationTabs } from '@toasttab/ec-ui-navigation-tabs'
import { getLabel } from '../../helpers'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import useResizeObserver from 'use-resize-observer'
import { useFeature } from '@toasttab/ec-features'

import AddEmployeeModalWrapper from '../../AddEmployeeModal/AddEmployeeModalWrapper'

const TeamPagesNavBar = () => {
  const { teamPagesByPath, teamLabel } = useTeamPageItems()

  const hasAddEmployee = useFeature('ec-elm-add-employee')

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onOpen = useCallback(() => setIsOpen(true), [])

  const onClose = useCallback(() => setIsOpen(false), [])

  const { ref, width = Infinity } = useResizeObserver({ box: 'border-box' })

  const isCompact = width < 500

  const { t } = useTranslation()

  const tabItems = compact([
    teamPagesByPath['team.employees'],
    teamPagesByPath['team.onboarding'],
    teamPagesByPath['team.training']
  ])

  const tabs = !isEmpty(tabItems) ? (
    <EcNavigationTabs ecTitle={getLabel(teamLabel) || t('team')}>
      {tabItems.map((navItem) => {
        const { path, url, labelNew, matchUrls } = navItem
        return (
          <EcNavigationTabs.Tab key={path} href={url} matchUrls={matchUrls}>
            {getLabel(labelNew)}
          </EcNavigationTabs.Tab>
        )
      })}
    </EcNavigationTabs>
  ) : undefined

  return (
    <>
      <div ref={ref}>
        <EcPageHeader
          title={t('team')}
          actions={
            <TeamActions
              isCompact={isCompact}
              onOpenAddEmployeeModal={onOpen}
            />
          }
          tabs={tabs}
        />
      </div>

      {hasAddEmployee && (
        <AddEmployeeModalWrapper isOpen={isOpen} onClose={onClose} />
      )}
    </>
  )
}

export default TeamPagesNavBar
