import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const PayrollEmployeeIcon = React.forwardRef(
  function PayrollEmployeeIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'sm'].className
              )}
            >
              <path
                d='M9.763 17.497h-5.46A2.301 2.301 0 012 15.198V5.3A2.301 2.301 0 014.303 3h8.583a2.301 2.301 0 012.303 2.299v2.253m-4.675.417a1.54 1.54 0 00-.406-.55 1.96 1.96 0 00-1.338-.463 2.215 2.215 0 00-1.31.373 1.282 1.282 0 00-.572 1.094 1.24 1.24 0 00.5 1.056c.393.258.828.442 1.286.545l.03.008c.463.104.902.294 1.295.56a1.298 1.298 0 01.525 1.098 1.319 1.319 0 01-.59 1.136 2.256 2.256 0 01-1.328.38 2.529 2.529 0 01-1.398-.383 1.327 1.327 0 01-.542-.671m2.07-6.195v.93m.002 6.346v.93M22 21a6.913 6.913 0 00-10.71 0m9.229-6.392a3.854 3.854 0 01-3.858 3.85 3.854 3.854 0 01-3.858-3.85 3.854 3.854 0 013.858-3.85 3.854 3.854 0 013.857 3.85z'
                stroke='currentColor'
                strokeWidth={sizes[props.size || 'sm'].strokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
