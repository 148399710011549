import * as React from 'react'
import { useUniqueId } from '@toasttab/buffet-utils'
import { Dropdown, DropdownProps } from '../Dropdown'
import { variants } from '../utils'
import { MenuDropdownContext } from './MenuDropdownContext'

export type Variant = 'white' | 'gray'

export interface MenuDropdownProps
  extends Omit<DropdownProps, 'disableInitialFocus' | 'role'> {
  /** Background color to use for the dropdown*/
  variant?: Variant
  /** when you select an item, should the dropdown close */
  preventCloseOnClick?: boolean
  /**
   * List of <li> that will appear in the dropdown menu. They must have their `action`
   * property filled, and can include a `key` attribute. `role` attribute will
   * be defaulted to `option` unless defined in each child.
   */
  children?: React.ReactNode
}

export const MenuDropdown = ({
  testId,
  preventCloseOnClick = false,
  variant = 'white',
  maxHeight = 256,
  children,
  id,
  ...props
}: MenuDropdownProps) => {
  testId = useUniqueId(testId, 'menu-dropdown-')
  const _id = useUniqueId(id, 'menu-dropdown-')

  return (
    <Dropdown
      testId={testId}
      variant={variant}
      id={_id}
      maxHeight={maxHeight}
      {...props}
      role='menu'
      disableInitialFocus={false}
    >
      {({ closeDropdown }: { closeDropdown: Function }) => (
        <MenuDropdownContext.Provider
          value={{ variant, preventCloseOnClick, closeDropdown }}
        >
          <div className='p-0 m-0' aria-labelledby={_id}>
            {children}
          </div>
        </MenuDropdownContext.Provider>
      )}
    </Dropdown>
  )
}

MenuDropdown.variant = Object.freeze(variants)
