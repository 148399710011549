import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const CheckboxIndeterminateIcon = React.forwardRef(
  function CheckboxIndeterminateIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'sm'].className
              )}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14zm-3.2 8H8.2c-.5 0-1 .4-1 1s.4 1 1 1h7.6c.5 0 1-.4 1-1-.1-.6-.5-1-1-1z'
                fill='currentColor'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
