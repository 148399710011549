import * as React from 'react'
import cx from 'classnames'
import { isSameMonth } from '@toasttab/buffet-pui-date-utilities'
import { CaptionProps, useDayPicker, useNavigation } from 'react-day-picker'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ChevronLeftIcon, ChevronRightIcon } from '@toasttab/buffet-pui-icons'

export const BuffetCaption = (props: CaptionProps): JSX.Element => {
  const { displayMonth, id } = props
  const context = useDayPicker()
  const {
    locale,
    numberOfMonths,
    disableNavigation,
    onMonthChange,
    dir,
    labels: { labelPrevious, labelNext },
    formatters: { formatCaption }
  } = context

  const { previousMonth, nextMonth, goToMonth, displayMonths } = useNavigation()

  const handlePreviousClick: React.MouseEventHandler = React.useCallback(() => {
    if (!previousMonth) return
    goToMonth(previousMonth)
    onMonthChange?.(previousMonth)
  }, [goToMonth, onMonthChange, previousMonth])

  const handleNextClick: React.MouseEventHandler = React.useCallback(() => {
    if (!nextMonth) return
    goToMonth(nextMonth)
    onMonthChange?.(nextMonth)
  }, [goToMonth, nextMonth, onMonthChange])

  const displayIndex = displayMonths.findIndex((month) =>
    isSameMonth(displayMonth, month)
  )
  let isFirst = displayIndex === 0
  let isLast = displayIndex === displayMonths.length - 1
  if (dir === 'rtl') {
    ;[isLast, isFirst] = [isFirst, isLast]
  }

  const captionLabel = (
    <div
      key='caption'
      className={cx('font-semibold text-default type-default', {
        'w-full text-center': numberOfMonths > 1
      })}
      aria-live='polite'
      aria-atomic='true'
    >
      {formatCaption(props.displayMonth, { locale })}
    </div>
  )
  const hideNext = numberOfMonths > 1 && (isFirst || !isLast)
  const hidePrevious = numberOfMonths > 1 && (isLast || !isFirst)

  const previousLabel = labelPrevious(previousMonth, { locale })
  const nextLabel = labelNext(nextMonth, { locale })
  const previousButton = React.useMemo(() => {
    const PreviousButtonIcon =
      dir === 'rtl' ? ChevronRightIcon : ChevronLeftIcon
    return (
      <IconButton
        key='prev'
        aria-label={previousLabel}
        disabled={!previousMonth}
        icon={<PreviousButtonIcon aria-label={previousLabel} />}
        size='sm'
        textClassName='text-gray-100'
        onClick={handlePreviousClick}
        className='mx-6'
      />
    )
  }, [dir, handlePreviousClick, previousLabel, previousMonth])

  const nextButton = React.useMemo(() => {
    const NextButtonIcon = dir === 'rtl' ? ChevronLeftIcon : ChevronRightIcon
    return (
      <IconButton
        key='next'
        aria-label={nextLabel}
        disabled={!nextMonth}
        icon={<NextButtonIcon aria-label={nextLabel} />}
        size='sm'
        textClassName='text-gray-100'
        onClick={handleNextClick}
        className='mx-6'
      />
    )
  }, [dir, handleNextClick, nextLabel, nextMonth])

  return (
    <div
      className={cx('flex items-center p-0 mb-1.5 h-10', {
        'justify-between': nextMonth || previousMonth,
        'justify-center': !nextMonth && !previousMonth
      })}
      id={id}
    >
      {disableNavigation || (!nextMonth && !previousMonth) ? (
        <>{captionLabel}</>
      ) : (
        <>
          {hidePrevious ? <HiddenButton /> : previousButton}
          {captionLabel}
          {hideNext ? <HiddenButton /> : nextButton}
        </>
      )}
    </div>
  )
}

const HiddenButton = () => <div className='w-10 h-10 mx-6 flex-none' />
