import type { TranslationLoader } from '@toasttab/buffet-pui-translation-utilities'
import type { Translations } from './en-US'
import { translations as esUs } from '../locales/es'

import { translations as enUS } from './en-US'

type Awaited<T> = T extends PromiseLike<infer U> ? U : T

type Result = Awaited<ReturnType<TranslationLoader>>

type Resp = {
  translations: Translations
}

const wrapResp = (resp: Resp | undefined): Result => {
  return {
    local: resp || undefined,
    fallback: { translations: enUS }
  }
}

const defaultValue = wrapResp({ translations: enUS })

const translationLoader: TranslationLoader = async (language: string) => {
  if (language === 'en-US') {
    return defaultValue
  }

  try {
    switch (language) {
      case 'es': {
        return wrapResp({ translations: esUs })
      }
      default: {
        return defaultValue
      }
    }
  } catch {
    return defaultValue
  }
}

export type { Translations }

export { translationLoader }
