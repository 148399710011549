import * as React from 'react'
import { useTeamPageItems } from '../hooks'
import { TeamPageHeader } from '../components'
import { withNavV3Page } from './withNavV3Page'

const TeamAddTrainingEventNavigation = () => {
  const { teamPagesByName } = useTeamPageItems()
  const backHref = teamPagesByName['training']?.url

  // todo: match this up with the right node in the nav config
  return <TeamPageHeader backHref={backHref} title={'Create training event'} />
}

export default withNavV3Page(TeamAddTrainingEventNavigation)
