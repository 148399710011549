import { gql } from '@apollo/client'

export const POS_UNMAPPED_EMPLOYEES = gql`
  query GetUnmappedPosUsers {
    getUnmappedPosUsers {
      userName {
        first
        last
      }
      email
      userGuid
      restaurantUserGuid
      locationGuid
      archived
      disabled
      payrollLocationId
    }
  }
`
