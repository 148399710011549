import * as React from 'react'
import useResizeObserver from 'use-resize-observer'
import cx from 'classnames'
import map from 'lodash/map'
import { EmployeeTable, EmployeeLink, EmployeeEmail } from '../../components'
import { formatTenure } from '../../helpers'
import { useTranslation } from '../../hooks'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { WarningOutlineIcon, MoreHorizIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { Badge } from '@toasttab/buffet-pui-badge'
import { MenuDropdown, ListItem } from '@toasttab/buffet-pui-dropdowns'
import { Employee } from '../../data/types'
import { PosRawEmployee } from '@local/api'
import { useEcProps } from '@toasttab/ec-banquet-props'
import { IconButtonWithTooltip } from '@toasttab/buffet-pui-tooltip'

const useNumColumns = (width: number) => {
  const screenSize = useScreenSize()

  if (screenSize > ScreenSize.MD) return 4

  if (width >= 960) return 4
  if (width >= 700) return 3
  if (width >= 500) return 2
  return 1
}

const Stack = ({ children }: { children: React.ReactNode }) => {
  return <div className='flex flex-col'>{children}</div>
}

type ItemProps = {
  sub?: boolean
  children: React.ReactNode
}

const Item = ({ sub, children }: ItemProps) => {
  return (
    <div
      className={cx(
        !sub ? 'py-1' : 'py-0.5',
        sub && 'text-secondary type-subhead'
      )}
    >
      {children}
    </div>
  )
}

type Props = {
  employees?: Employee[]
  posEmployees?: PosRawEmployee[]
  isLoading?: boolean
  mappingStatusFeature?: boolean
  ignoreMappingFeature: boolean
  hrAndAboveUser: boolean
  companyCode: string
  hirePosEmployee: (
    locationGuid: string,
    userGuid: string,
    name: string
  ) => void
  ignorePosEmployee: (guid: string, name: string) => void
}

const ResponsiveEmployeeTable = (props: Props) => {
  const {
    employees,
    isLoading,
    mappingStatusFeature,
    hrAndAboveUser,
    companyCode,
    ignoreMappingFeature,
    hirePosEmployee,
    ignorePosEmployee
  } = props
  const { ecNavigate } = useEcProps()
  const navigateToMapping = () => {
    ecNavigate(`/mvc/${companyCode}/Company/SystemSettings/ToastEmployees`)
  }

  const posEmployees = employees?.filter((value) => {
    return value.isPOSEmployee === true
  })

  const payrollEmployees = employees?.filter((value) => {
    return value.lengthOfService !== ''
  })

  const { ref, width = 0 } = useResizeObserver({ box: 'border-box' })

  const numCols = useNumColumns(width)

  const { t } = useTranslation()

  const name = t('name')
  const pos = t('position')
  const tenure = t('lengthOfEmployment')
  const phone = t('phoneNumber')

  const getCols = (num: number) => {
    if (num === 4) return [name, pos, tenure, phone]
    if (num === 3) return [name, tenure, pos]
    if (num === 2) return [name, pos]
    return undefined
  }

  const COLUMNS = getCols(numCols)

  const employeeTable = (
    id: number | string,
    name: string | React.JSX.Element,
    email: string | React.JSX.Element | null,
    position: string | null,
    phoneNumber: string | null,
    tenure: string | null,
    options?: React.JSX.Element
  ) => {
    if (numCols === 4) {
      return (
        <EmployeeTable.Row key={id}>
          <Stack>
            {name}
            <Item sub>{email}</Item>
          </Stack>
          {position}
          {tenure}
          {phoneNumber}
          <div className='float-right'>{options}</div>
        </EmployeeTable.Row>
      )
    }

    if (numCols === 3) {
      return (
        <EmployeeTable.Row key={id}>
          <Stack>
            {name}
            <Item sub>{phoneNumber}</Item>
            <Item sub>{email}</Item>
          </Stack>
          <Item>{tenure}</Item>
          <Item>{position}</Item>
          <div className='float-right'>{options}</div>
        </EmployeeTable.Row>
      )
    }

    if (numCols === 2) {
      return (
        <EmployeeTable.Row key={id}>
          <Stack>
            {name}
            <Item sub>{phoneNumber}</Item>
            <Item sub>{email}</Item>
          </Stack>
          <Stack>
            <Item>{position}</Item>
            <Item sub>{tenure}</Item>
          </Stack>
          <div className='float-right'>{options}</div>
        </EmployeeTable.Row>
      )
    }

    return (
      <EmployeeTable.Row key={id}>
        <Stack>
          {name}
          <Item sub>{position}</Item>
          <Item sub>{phoneNumber}</Item>
          <Item sub>{email}</Item>
          <Item sub>{tenure}</Item>
        </Stack>
        <div className='float-right'>{options}</div>
      </EmployeeTable.Row>
    )
  }

  const rows = map(payrollEmployees, (employees) => {
    const {
      id,
      fullName,
      link,
      position,
      lengthOfService,
      email: emailAddress,
      phoneNumber
    } = employees

    const tenure = formatTenure(lengthOfService)

    const name = (
      <EmployeeLink
        mappingStatusFeature={mappingStatusFeature}
        recordLink={link}
      >
        {fullName}
      </EmployeeLink>
    )

    const email = (
      <EmployeeEmail
        mappingStatusFeature={mappingStatusFeature}
        recordLink={link}
        email={emailAddress}
      />
    )
    return employeeTable(id, name, email, position, phoneNumber, tenure)
  })

  const posEmployeesRows = map(posEmployees, (posEmployees) => {
    const {
      fullName,
      email: emailAddress,
      restaurantUserGuid,
      userGuid,
      locationGuid
    } = posEmployees

    const customedName = (fullName: string) => {
      return (
        <>
          <div>
            <Badge color='error' testId='unmapped-pos-employee-badge' size='sm'>
              {t('unpaidEmployee')}
            </Badge>
          </div>
          <div
            className='flex flex-wrap items-center space-x-1'
            data-testid={`unmapped-pos-employee-${restaurantUserGuid}`}
          >
            <IconButtonWithTooltip
              testId={'tooltip-info'}
              aria-label='Warning'
              icon={
                <WarningOutlineIcon
                  testId='unmapped-pos-employee-warning'
                  accessibility='decorative'
                  className='pr-1 text-error-hover'
                  size='xs'
                />
              }
              tooltipAccessibility='label'
              tooltipContent={t('errorTooltip')}
              tooltipPlacement='bottom'
            />
            <div className='font-semibold'>{fullName}</div>
          </div>
        </>
      )
    }
    const formattedEmail = (
      <div className='break-all'>
        {emailAddress.includes('@example.com') ? null : emailAddress}
      </div>
    )
    const unmappedEmployeeOptions = (
      <>
        {mappingStatusFeature && (
          <MenuDropdown
            testId={`unmapped-pos-menu-${restaurantUserGuid}`}
            {...props}
            renderToggle={(props) => (
              <IconButton
                icon={
                  <MoreHorizIcon
                    className='text-secondary'
                    accessibility='decorative'
                  />
                }
                {...props}
              />
            )}
          >
            {hrAndAboveUser && (
              <ListItem
                onClick={() => navigateToMapping()}
                label={t('mergeEmployee')}
                data-testid={`unmapped-pos-merge-employee-${restaurantUserGuid}`}
              />
            )}
            {ignoreMappingFeature && (
              <ListItem
                onClick={() =>
                  userGuid && ignorePosEmployee(userGuid, fullName)
                }
                label={t('ignoreEmployee')}
                data-testid={`unmapped-pos-ignore-employee-${restaurantUserGuid}`}
              />
            )}
            <ListItem
              onClick={() =>
                locationGuid &&
                userGuid &&
                hirePosEmployee(locationGuid, userGuid, fullName)
              }
              label={t('createNewProfile')}
              data-testid={`unmapped-pos-hire-employee-${restaurantUserGuid}`}
            />
          </MenuDropdown>
        )}
      </>
    )
    return (
      restaurantUserGuid &&
      employeeTable(
        restaurantUserGuid,
        customedName(fullName),
        formattedEmail,
        null,
        null,
        null,
        unmappedEmployeeOptions
      )
    )
  })

  return (
    <div ref={ref}>
      <EmployeeTable loading={isLoading} columns={COLUMNS}>
        {mappingStatusFeature && posEmployeesRows}
        {rows}
      </EmployeeTable>
    </div>
  )
}

export default ResponsiveEmployeeTable
