import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import * as React from 'react'
import {
  DateRange,
  format,
  Masks,
  parse,
  selectMask
} from '@toasttab/buffet-pui-date-utilities'
import { sanitizeFormattedValue } from './helpers'

export const useFormatter = (locale: Locale) => {
  const pattern = React.useMemo(() => {
    return (
      selectMask(Masks.short, locale)?.['parseFormat']['output'] || 'yyyy-MM-dd'
    )
  }, [locale])

  const formatter = (date?: Date) => {
    return date // eslint-disable-next-line @toasttab/buffet/date-formats
      ? String(format(date, sanitizeFormattedValue(pattern), locale))
      : ''
  }
  const parser = (date?: string | null, isFormattedValue = false) => {
    const parsPattern = isFormattedValue
      ? pattern
      : sanitizeFormattedValue(pattern)
    return date ? parse(date, parsPattern, new Date()) : undefined
  }
  const rangeFormatter = (date: DateRange) => {
    return {
      from: formatter(date?.from),
      to: formatter(date?.to)
    }
  }

  const rangeParser = (date: { from?: string; to?: string }) => {
    return {
      from: parser(date?.from),
      to: parser(date?.to)
    }
  }

  return {
    dateFormatter: formatter,
    dateParser: parser,
    rangeFormatter,
    rangeParser
  }
}
