import { DateTime } from 'luxon'

const MIN_DATE = '01/01/0100'

const FORMAT = 'LL/mm/yyyy'

const parse = (date: string) => DateTime.fromFormat(date, FORMAT)

const format = (dateTime: DateTime) => dateTime.toFormat(FORMAT)

const minDateTime = parse(MIN_DATE)

// api returns an default 'from' value of something like toady - 2000 years,
// but the JS date parser interprets `0012` as `12` meaning `2012`.
// as we appear to only want to a year that definitely includes all hires,
// this shifts years from the first century to the second century
// so the js date parser will be well behaved

const getGuradedDateTime = (str: string): DateTime | undefined => {
  if (!str) return undefined

  const parsedDateTime = parse(str)

  if (!parsedDateTime.isValid) return undefined

  if (parsedDateTime < minDateTime) return undefined

  return parsedDateTime
}

const guardDate = (str: string): string => {
  const dateTime = getGuradedDateTime(str)

  if (dateTime?.isValid) {
    return format(dateTime)
  }
  return MIN_DATE
}

export { guardDate, MIN_DATE }
