import * as React from 'react'
import cx from 'classnames'
import {
  CheckboxSelectedIcon,
  CheckboxUnselectedIcon
} from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export type SelectMultipleItemContainerProps = TestIdentifiable &
  React.LiHTMLAttributes<HTMLLIElement> & {
    index: number
    highlightedIndex: number | null
    itemProps: any
    children: React.ReactNode
    truncate?: boolean
    listRef: React.MutableRefObject<(HTMLElement | null)[]>
    listContentRef: React.MutableRefObject<(string | null)[]>
  }

export const SelectMultipleItemContainer: React.FC<
  React.PropsWithChildren<SelectMultipleItemContainerProps>
> = ({
  testId = 'selectMultipleItemContainer',
  index,
  highlightedIndex,
  itemProps,
  children,
  truncate,
  listRef,
  listContentRef
}) => {
  const { disabled: isDisabled, checked: isChecked } = itemProps

  return (
    <li
      data-testid={`${testId}-option-${index}`}
      className={cx(
        'px-3 py-0.5 md:py-0 type-default font-normal',
        'flex flex-row items-top space-x-1',
        'focus:outline-none',
        {
          'bg-darken-4': highlightedIndex === index,
          'text-disabled': isDisabled,
          'cursor-pointer text-default': !isDisabled
        }
      )}
      {...itemProps}
      ref={(node: any) => {
        listRef.current[index] = node
        listContentRef.current[index] = node?.textContent ?? null
      }}
    >
      <div className='px-2 py-2.5 leading-none'>
        {isChecked && (
          <CheckboxSelectedIcon
            accessibility='decorative' // selected state described by aria-selected
            className={cx(isDisabled ? 'text-disabled' : 'text-primary-75')}
          />
        )}
        {!isChecked && (
          <CheckboxUnselectedIcon
            accessibility='decorative' // selected state described by aria-selected
            className={cx(isDisabled ? 'text-disabled' : 'text-gray-100')}
          />
        )}
      </div>
      <div className={cx(truncate && 'truncate', 'py-2.5')}>{children}</div>
    </li>
  )
}
