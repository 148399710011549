import * as React from 'react'
import { useTeamPageItems } from '../hooks'
// import { useHideEsxElement } from '@toasttab/ec-legacy-esx-hooks'
import { TeamPageHeader } from '../components'
import { withNavV3Page } from './withNavV3Page'
import { getLabel } from '../helpers'

const TeamAddEmployeeStepsNavigation = () => {
  const { teamPagesByName, teamUrl } = useTeamPageItems()

  /*
  Jun 26, 2023 - william.lubelski@toasttab.com
  the documents step of the add employee flow
  has a button in the 'panel-heading' which we cannot hide
  so for now we cannot disable the headings.
  we can likely itemize the routes and hide each other one exempting documents,
  but we cannot categorically hide them all.

  in Q3, HR will be releasing the new add employee (wizard modal based) flow
  which will hopefully result in deprecation of these pages anyway
  */

  // useHideEsxElement({ selector: '.panel-heading' })

  const title = getLabel(teamPagesByName['addEmployee'])

  return <TeamPageHeader backHref={teamUrl} title={title || 'Add employee'} />
}

export default withNavV3Page(TeamAddEmployeeStepsNavigation)
