import { IconProps } from '@toasttab/buffet-pui-icons'
import { Tooltip, TooltipProps } from '@toasttab/buffet-pui-tooltip'
import { SizeTypes } from '../Size'
import cx from 'classnames'
import * as React from 'react'

type StepperButtonDirection = 'prev' | 'next'

export interface StepperButtonProps {
  label: string
  direction: StepperButtonDirection
  icon: React.FunctionComponentElement<IconProps>
  disabled?: boolean
  disabledBorder?: boolean
  tooltip?: string | null
  tooltipProps?: Partial<TooltipProps>
  testId?: string
  size?: SizeTypes
  onClick: () => void
}

const defaultTooltipProps: Partial<TooltipProps> = {
  mobileTimeout: 5000,
  placement: 'top',
  variant: 'dark'
}

export const StepperButton = ({
  label,
  direction,
  icon,
  disabled,
  disabledBorder,
  tooltip,
  tooltipProps,
  onClick,
  size = 'auto',
  testId = 'date-stepper'
}: StepperButtonProps) => {
  return (
    <button
      type='button' // default is Submit, which will cause any wrapping form [e.g. when using DatePickerField] to submit when clicked.
      aria-label={label}
      data-testid={`${testId}-button-${direction}`}
      disabled={disabled}
      onClick={onClick}
      className={cx(
        zIndexBoostOnFocusOrHoverCx,
        'border',
        'h-auto',
        getWidth(size),
        'flex shrink-0 items-center justify-center',
        disabledBorder && 'border-disabled',
        disabled ? 'text-disabled' : 'text-secondary',
        {
          // < Prev
          'border-r-transparent': direction === 'prev',
          'rounded-l-input': direction === 'prev',
          // > Next
          'border-l-transparent': direction === 'next',
          'rounded-r-input': direction === 'next',
          //
          'cursor-default': disabled
        },
        disabled ? [] : [focusCx, hoverCx, activeCx] // Only apply interaction effects if not disabled
      )}
    >
      {disabled || !tooltip ? (
        <div className={cx('inline-flex')}>{icon}</div>
      ) : (
        <Tooltip
          {...defaultTooltipProps}
          {...tooltipProps}
          className='flex h-full items-center align-top'
          content={() => tooltip}
          children={icon}
        />
      )}
    </button>
  )
}

const zIndexBoostOnFocusOrHoverCx = [
  'hover:z-5',
  'focus:z-5',
  'focus-visible:z-5',
  'focus-within:z-5'
]

const activeCx = ['active:shadow-focus', 'active:border-2']

const focusCx = [
  'focus:outline-none',
  'focus-visible:outline-none',
  'focus-visible:border-2',
  'focus-visible:border-primary-75',
  'focus-visible:shadow-focus',
  'focus-visible:border-primary-75'
]

const hoverCx = ['hover:border-primary-75', 'hover:shadow-focus-within']

const getWidth = (size: SizeTypes) => {
  switch (size) {
    case 'auto':
      return 'w-12 md:w-10'
    case 'sm':
      return 'w-10'
    default:
      return 'w-12'
  }
}
