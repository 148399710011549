import * as React from 'react'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { DateRange, isValid } from '@toasttab/buffet-pui-date-utilities'
import type { SchemaOf } from 'yup'
import { useFormatter } from './useFormatter'
import { sanitizeFormattedValue } from './helpers'

export interface useCustomRangeFormProps {
  locale: Locale
  initialValue: DateRange
  onChange: (name: string, value: Date) => void
  onSubmit: (range: DateRange) => void
  validationSchema: SchemaOf<DateRange>
}

const INITIAL_VALUE = { from: undefined, to: undefined }
const INITIAL_ERROR_STATE = {
  from: false,
  to: false,
  range: false
}

export const useCustomRangeForm = ({
  locale,
  initialValue = INITIAL_VALUE,
  onChange,
  onSubmit,
  validationSchema
}: useCustomRangeFormProps) => {
  const { rangeParser, rangeFormatter, dateParser } = useFormatter(locale)

  const initialValueRange = React.useMemo(() => {
    return rangeFormatter(initialValue)
  }, [initialValue, rangeFormatter])

  const [values, setValues] = React.useState<{
    from: string
    to: string
  }>({
    from: initialValueRange.from,
    to: initialValueRange.to
  })
  const [errors, setErrors] = React.useState(INITIAL_ERROR_STATE)

  const resetErrors = () => {
    setErrors(INITIAL_ERROR_STATE)
  }

  const isValidForm = React.useCallback(
    (value: DateRange) => {
      const isValid = validationSchema.isValidSync(value, {
        abortEarly: false
      })
      if (!isValid) {
        try {
          validationSchema.validateSync(value, {
            abortEarly: false
          })
        } catch (err: any) {
          const errors = err.inner.reduce(
            (
              acc: Record<string, string>,
              error: { path: string; message: string }
            ) => {
              return {
                ...acc,
                [error.path]: error.message
              }
            },
            {}
          )
          setErrors(errors)
        }
      } else {
        resetErrors()
      }

      return isValid
    },
    [validationSchema]
  )

  const handleOnSubmit = React.useCallback(() => {
    const range = rangeParser(values)
    const isValid = isValidForm(range)
    if (isValid) {
      onSubmit(range)
    }
  }, [isValidForm, onSubmit, rangeParser, values])

  const handleOnKeyUp = React.useCallback(
    //TODO: Fix this. It's used where it's expected a keyboard event but the
    // keyboard event has no target
    (e: any) => {
      if (e.key === 'Enter') {
        handleOnSubmit()
        return
      }
      e.persist()
      const { name, value } = e.target
      const newRange = {
        ...values,
        [name]: sanitizeFormattedValue(value)
      }

      setValues(newRange)

      const date = dateParser(value, true)
      if (!date || !isValid(date)) {
        return
      }
      const currentRange = rangeParser(newRange)

      if (!currentRange?.from || !currentRange?.to) {
        return onChange(name, date)
      }

      if (isValidForm(currentRange)) {
        onChange(name, date)
      }
    },
    [values, dateParser, rangeParser, isValidForm, handleOnSubmit, onChange]
  )

  const handleOnClick = React.useCallback(() => {
    handleOnSubmit()
  }, [handleOnSubmit])

  // Set values when they are changed via the calendar/ui
  React.useEffect(() => {
    setValues((prev) => ({
      ...prev,
      from: initialValueRange.from
    }))
  }, [initialValueRange.from])

  // Set values when they are changed via the calendar/ui
  React.useEffect(() => {
    setValues((prev) => ({
      ...prev,
      to: initialValueRange.to
    }))
  }, [initialValueRange.to])

  return { handleOnKeyUp, values, errors, handleOnClick }
}
