import { SelectItem } from '../types'
import { itemToValueFn, ItemToValueFn, isSelectOption } from '../utils'

export interface UseSelectAllProps<
  TItem extends SelectItem<TValue>,
  TValue extends any = string
> {
  onChange: (arg: TValue[]) => void
  itemToValue?: ItemToValueFn<TValue, TItem>
  options?: TItem[]
  selectedItems: TItem[]
}

export const useSelectAll = <TItem extends SelectItem<TValue>, TValue>({
  onChange,
  itemToValue = itemToValueFn,
  options = [],
  selectedItems
}: UseSelectAllProps<TItem, TValue>) => {
  const handleSelectAll = (items: TItem[]) => {
    if (!items.length) {
      onChange([])
    } else {
      const selectedValues = items.map((item) => itemToValue(item))
      onChange(selectedValues)
    }
  }

  const isAllSelected =
    selectedItems.length > 0 && selectedItems.length === options.length

  const onSelectAllClick = () => {
    const enabledOptions = options.filter(
      (option) => !isSelectOption(option) || !option.disabled
    )
    const areAllEnabledSelected =
      selectedItems.length > 0 && selectedItems.length === enabledOptions.length
    areAllEnabledSelected
      ? handleSelectAll([] as TItem[])
      : handleSelectAll(enabledOptions as TItem[])
  }

  return {
    handleSelectAll,
    isAllSelected,
    onSelectAllClick
  }
}
