import * as React from 'react'
import cx from 'classnames'

import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { getBuffetConfig } from '@toasttab/buffet-utils'

import { SelectButtonDefault } from './SelectButtonDefault'
import { SelectButtonContained } from './SelectButtonContained'

export type Size =
  | 'lg'
  | 'sm'
  | 'base' // deprecated in favor of 'lg'
  | 'auto'

export interface SelectButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    TestIdentifiable {
  selected?: boolean
  disabled?: boolean
  invalid?: boolean
  inlineBlock?: boolean
  iconLeft?: React.ReactNode
  isOpen?: boolean
  disableFocusShadow?: boolean
  props?: any
  children?: React.ReactNode
  size?: Size
  align?: 'top' | 'center'
  customHeight?: boolean
  containerClassName?: string
  containerRef?: React.Ref<HTMLDivElement>
  transparentBackground?: boolean
  borderRadiusClassName?: string
  changed?: boolean
  label?: React.ReactNode
  hideLabel?: boolean
}

export const SelectButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<SelectButtonProps>
>((props, ref) => {
  const inputStyle = getBuffetConfig().inputStyle

  if (inputStyle === 'contained') {
    return <SelectButtonContained {...props} ref={ref} />
  }

  return <SelectButtonDefault {...props} ref={ref} />
})

export const getToggleButtonSizeClass = (size?: Size) => {
  switch (size) {
    case 'sm':
      return 'my-2'
    case 'lg':
    case 'base':
      return 'my-3'
    case 'auto':
    default:
      return 'my-3 md:my-2'
  }
}

/**
 * Use this class to provide the appropriate padding
 */
export const SelectButtonContents = ({
  size,
  children
}: {
  size?: Size
  children: React.ReactNode
}) => {
  const toggleButtonSizeClass = getToggleButtonSizeClass(size)
  return <div className={cx('truncate', toggleButtonSizeClass)}>{children}</div>
}
