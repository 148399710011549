import * as React from 'react'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import {
  TeamDashboardPage,
  TeamEmployeesPage,
  TeamOnboardingPage,
  TeamTrainingPage,
  TeamAddEmployeePage,
  TeamAddEmployeeStepsPage,
  TeamAddTrainingEventPage,
  TeamAddIncidentPage,
  TeamIncidentPage,
  TeamBulkPayChangePage,
  TeamBulkPayChangeSetupPage
} from '../pages'

const router = createBrowserRouter([
  {
    path: 'mvc/:companyCode/Team/Dashboard/*',
    element: <TeamDashboardPage />
  },

  {
    path: ':companyCode/team',
    children: [
      {
        index: true,
        element: <Navigate to='employees' replace />
      },
      {
        path: 'employees',
        element: <TeamEmployeesPage />
      }
    ]
  },
  {
    path: 'mvc/:companyCode/Management/NewHire/Index',
    element: <TeamOnboardingPage />
  },
  {
    path: 'mvc/:companyCode/Management/NewHire',
    element: <TeamOnboardingPage />
  },
  {
    path: 'mvc/:companyCode/Management/NewHire/OnboardingChecklist',
    element: <TeamOnboardingPage />
  },
  {
    path: 'Employee/TrainingEvents.aspx',
    element: <TeamTrainingPage />
  },
  {
    path: 'mvc/:companyCode/Management/NewHire/Hire',
    element: <TeamAddEmployeePage />
  },
  {
    path: 'mvc/:companyCode/Management/NewHire/*',
    element: <TeamAddEmployeeStepsPage />
  },
  {
    path: 'Employee/TrainingEventAdd.aspx',
    element: <TeamAddTrainingEventPage />
  },
  {
    path: 'Customer/IncidenceReporting.aspx',
    element: <TeamIncidentPage />
  },
  {
    path: 'Customer/IncidenceAdd.aspx',
    element: <TeamAddIncidentPage />
  },
  {
    path: 'mvc/:companyCode/Company/Payroll/PayChange',
    element: <TeamBulkPayChangePage />
  },
  {
    path: 'mvc/:companyCode/Company/Payroll/PayChangeSetup',
    element: <TeamBulkPayChangeSetupPage />
  },
  {
    path: '*',
    element: <></>
  }
])

const AppRouter = () => {
  return <RouterProvider router={router} />
}

export default AppRouter
