import { useEffect, useState } from 'react'

function useLastDefinedValue<T>(value: T): T {
  const [val, setVal] = useState<T>(value)

  useEffect(() => {
    if (value) {
      setVal(value)
    }
  }, [value])

  return val
}

export default useLastDefinedValue
