import * as React from 'react'
import { Portal } from '@toasttab/ec-layout'
import { TeamPagesNavBar } from '../../components'
import { useHideEsxElement } from '@toasttab/ec-legacy-esx-hooks'

const TeamOnboardingNavigation = () => {
  useHideEsxElement({ selector: '.panel-heading' })

  return <Portal id='page-header' component={<TeamPagesNavBar />} />
}

export default TeamOnboardingNavigation
