import * as React from 'react'
import { useNavigationVersion } from '@toasttab/ec-features'
import apolloClient from '../apolloClient'
import { ApolloProvider } from '@apollo/client'

// _usually_ HOCs are a terrible idea,
// but we constantly need to get the apollo provider wrapped around
// our nav components, and to conditionally render based on the nav setting
// so in this case the ergonomic benefits are worth it

const withNavV3Page = (Component: React.FC) => {
  const WrappedComponent = () => {
    const { version } = useNavigationVersion()

    if (version === 'v3') {
      return (
        <ApolloProvider client={apolloClient}>
          <Component />
        </ApolloProvider>
      )
    }

    return null
  }

  return WrappedComponent
}

export { withNavV3Page }
