import * as React from 'react'
import cx from 'classnames'
import {
  PlacementWithAuto,
  PortalManagement,
  Overlay,
  useDropdownCommon,
  useFloatingComponentZIndexClass
} from '@toasttab/buffet-pui-floating-ui-base'
import { getBuffetConfig } from '@toasttab/buffet-utils'
import {
  DateInput,
  DateInputProps,
  NumberFormatValues
} from '@toasttab/buffet-pui-text-input'

import {
  SelectButton,
  SelectButtonProps
} from '@toasttab/buffet-pui-select-button'
import {
  ArrowDropDownIcon,
  CalendarTodayIcon
} from '@toasttab/buffet-pui-icons'
import {
  HelperText,
  Label,
  LabeledHelperTextProps,
  LabelProps
} from '@toasttab/buffet-pui-text-base'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import {
  DateRange,
  isSameDay,
  isValid,
  Masks,
  parse,
  selectMask
} from '@toasttab/buffet-pui-date-utilities'
import { ScreenSize } from '@toasttab/use-screen-size'
import {
  DatePickerWithDefinedRanges,
  DefaultStaticRange
} from '../DatePickerWithDefinedRanges'
import {
  DatePickerState,
  useDatePickerContext,
  DatePickerValueType,
  OnSelectEventType
} from '../useDatePickerContext'
import { DateStepperButtons } from '../DateStepperButtons'
import { PresetWidthKey } from '../DateStepperButtons/DateStepperContainer'
import { isDateRange } from 'react-day-picker'
import { CustomRangeForm } from '../DatePickerWithDefinedRanges/CustomRangeForm'
import { t } from '../defaultStrings'

export interface DayPickerContainerBaseProps<T>
  extends LabeledHelperTextProps,
    TestIdentifiable {
  value?: T
  formatValue: (value: T) => string | null | undefined
  /** Apply a className to the root element. For example, adding 'mt-4' will add 16px of top margin; adding 'fs-unmask' will unmask the label, input, and helper text for full story  */
  containerClassName?: string
  placement?: PlacementWithAuto
  /** Set this to true to make the container inline block */
  inlineBlock?: boolean
  placeholder?: string
  /**
   * @deprecated Please use the default portal behavior to ensure the tooltip contents are correctly css scoped
   */
  modalParentSelector?: () => HTMLElement
  size?: SelectButtonProps['size']
  /** Allows for a callback when the open state of the select changes */
  onOpenChange?: (isOpen: boolean) => void
  id?: string
}

type renderCalendarProps<T extends DatePickerValueType> = (
  value: T | undefined,
  closeDatePicker: () => void,
  props: {
    onSelect: OnSelectEventType<T>
    containerContext: Partial<DatePickerState<T>>
    required?: boolean
  }
) => React.ReactNode

export interface DatePickerContainerProps<T extends DatePickerValueType>
  extends DayPickerContainerBaseProps<T>,
    Omit<DatePickerContainerInputProps<T>, 'locale'> {
  /** Allows DatePicker to receive date input */
  allowInput?: boolean
  onKeyDown?: (event: React.KeyboardEvent) => void
  onClose?: () => void
  renderCalendar: renderCalendarProps<T>
  mask?: string
  showDefinedRanges?: boolean
  showDateStepperButtons?: boolean
  definedRanges?: DefaultStaticRange[]
  onSelect?: OnSelectEventType<T>
  onCustomDateChange?: (name: string, value: Date) => void
  ariaLabel?: string
  minDate?: Date
  maxDate?: Date
}

export interface DatePickerContainerInputProps<T> {
  onBlur?: DateInputProps['onBlur']
  /** @deprecated onValueChange will be removed in the future, use onChange instead */
  onValueChange?: (value: T | undefined) => void
  locale?: Locale
  mask?: string
}

export const DatePickerContainer = <T extends DatePickerValueType>({
  testId,
  containerClassName,
  value,
  renderCalendar,
  disabled,
  invalid,
  required,
  inlineBlock,
  placeholder,
  size,
  formatValue,
  minDate,
  maxDate,
  name,
  id,
  label,
  ariaLabel,
  preserveHelpSpace,
  helperText,
  helperIconButton,
  errorText,
  placement = 'auto',
  allowInput,
  onBlur,
  mask = Masks.short,
  showDateStepperButtons = false,
  onKeyDown,
  onCustomDateChange,
  onClose: _onClose,
  onOpenChange: _onOpenChange
}: DatePickerContainerProps<T>) => {
  const zIndexClass = useFloatingComponentZIndexClass() // elevation: z-40 for floating components (boosted to z-50 when in modals etc)
  const adjustedPlacement = placement === 'auto' ? undefined : placement
  const {
    datePickerState,
    screenSize,
    setDatePickerState,
    locale,
    selectedRangeLabel,
    onSelect,
    showDefinedRanges,
    definedRanges
  } = useDatePickerContext<T>()
  const { inputStyle } = getBuffetConfig()

  const {
    enableManualInput,
    isMobileManualInput,
    manualInputValue,
    activeInput
  } = datePickerState

  const defaultEnableManualInput = Boolean(
    value &&
      screenSize >= ScreenSize.MD &&
      !isDefinedRange(value, definedRanges, showDefinedRanges)
  )

  const onOpenChange = React.useCallback(
    (isOpen: boolean) => {
      if (isMobileManualInput.current && !isOpen) {
        // This is a special case where the dropdown closes but a modal opens
        // we'll call onOpenChange again when the modal closes
        return
      }
      setDatePickerState((prev) => ({
        // reset the manual input state for date range picker with defined ranges
        ...prev,
        ...getDefaultState<T>(value, defaultEnableManualInput)
      }))
      _onOpenChange?.(isOpen)
      if (!isOpen) {
        _onClose?.()
      }
    },
    [
      _onOpenChange,
      _onClose,
      isMobileManualInput,
      setDatePickerState,
      defaultEnableManualInput,
      value
    ]
  )

  const {
    isOpen,
    setIsOpen,
    refs,
    context,
    floatingStyles,
    testId: _testId,
    getFloatingProps,
    getReferenceProps,
    getLabelProps,
    closeMenu
  } = useDropdownCommon<SelectButtonProps, LabelProps>({
    placement: adjustedPlacement,
    testId,
    id,
    matchReferenceWidth: false,
    hasLabel: !!label,
    ariaLabel,
    disabled,
    onOpenChange,
    // We will explicitly handle bubbling the `onClose` event handler in onOpenChange
    // onClose,
    contentRole: 'dialog',
    idBase: 'datepicker'
  })

  const _value = enableManualInput ? manualInputValue : value

  const onKeyDownHandler = React.useCallback(
    (event: any) => {
      if (event.key === 'ArrowDown') {
        setIsOpen(true)
        event.preventDefault()
      }
      onKeyDown?.(event)
    },
    [onKeyDown, setIsOpen]
  )

  const parseOutputFormattedDate = React.useCallback(
    (formattedDate: string): Date => {
      const parseFormat =
        selectMask(mask, locale)?.['parseFormat']['output'] || 'yyyy-MM-dd'

      return parse(formattedDate, parseFormat, new Date())
    },
    [locale, mask]
  )

  /**
   * Handler for each change to the `DatePickerInput` version of the date picker
   */
  const onValueChangeHandler = React.useCallback(
    (newValue: NumberFormatValues) => {
      if (!newValue.formattedValue) {
        onSelect?.(undefined)
        return
      }

      const date = parseOutputFormattedDate(newValue.formattedValue)
      if (isValid(date)) {
        !enableManualInput && onSelect?.(date as T)
        setDatePickerState((prev) => ({
          ...prev,
          enableManualInput: false,
          activeInput: undefined
        }))

        // Fixes the focus jumping to the input when using keyboard with steppers.
        // closeMenu also sets the focus back on the main input, but we don't
        // want that if we're using keyboard input on any stepper buttons, so here
        // we only trigger close if isOpen is true.
        isOpen && closeMenu()
      }
    },
    [
      parseOutputFormattedDate,
      onSelect,
      enableManualInput,
      setDatePickerState,
      isOpen,
      closeMenu
    ]
  )

  /**
   * Handler for changes via the calendar
   */
  const handleCalendarSelect: OnSelectEventType<T> = React.useCallback(
    (date, selectedDay, modifiers, e) => {
      if (!showDefinedRanges && onSelect) {
        onSelect(date, selectedDay, modifiers, e)
      }
      setDatePickerState((prev) => ({
        ...prev,
        manualInputValue: date as T,
        enableManualInput: showDefinedRanges || false
      }))
    },
    [showDefinedRanges, onSelect, setDatePickerState]
  )

  const formattedValue = React.useMemo(() => {
    return value && formatValue(value)
  }, [value, formatValue])

  const commonReferenceProps = React.useMemo(
    () => ({
      ...getReferenceProps({}),
      onKeyDown: onKeyDownHandler
    }),
    [getReferenceProps, onKeyDownHandler]
  )

  // TODO: The subLabel kind of theoretically fits on a 40px button
  // TODO: Do we want a showSubLabelInButton prop like the Select component?
  const selectButtonLabel = React.useMemo(() => {
    return showDefinedRanges && value ? (
      <div
        className={`type-subhead truncate ${
          disabled ? 'text-disabled' : 'text-default'
        }`}
      >
        {selectedRangeLabel || t('custom')}
        <div
          className={`type-caption truncate ${
            disabled ? 'text-disabled' : 'text-secondary'
          } `}
        >
          {formattedValue}
        </div>
      </div>
    ) : (
      <>{formattedValue ?? placeholder}</>
    )
  }, [
    showDefinedRanges,
    value,
    disabled,
    selectedRangeLabel,
    formattedValue,
    placeholder
  ])

  /**
   * Sets a fixed width for DateStepper container only.
   * This prevents the right side stepper button from shifting when the
   * lengths of the date-range string varies as you step through the dates.
   * The keys are mapped to opinionated widths within DateStepperContainer.
   */
  const dateStepperFixedWidthMode: PresetWidthKey = React.useMemo(() => {
    if (showDefinedRanges) return 'defined'
    if (isDateRange(value) && !!value.to) return 'range'
  }, [showDefinedRanges, value])

  const calendar = renderCalendar(_value, closeMenu, {
    containerContext: {
      activeInput,
      manualInputValue,
      enableManualInput
    },
    onSelect: handleCalendarSelect,
    required
  })

  return (
    <div
      data-testid={testId}
      className={cx('relative', containerClassName, {
        'inline-block': inlineBlock
      })}
    >
      {label && (
        <div>
          <Label
            {...getLabelProps({
              useInInlineBlock: inlineBlock,
              helperIconButton: helperIconButton,
              required
            })}
          >
            {label}
          </Label>
        </div>
      )}

      <DateStepperButtons
        showDateStepperButtons={showDateStepperButtons}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        size={size}
        widthMode={dateStepperFixedWidthMode}
        disabled={disabled}
      >
        {allowInput ? (
          <DateInput
            suffix={<ArrowDropDownIcon accessibility='decorative' />}
            prefix={<CalendarTodayIcon accessibility='decorative' />}
            autoComplete='off'
            suffixVariant='icon'
            prefixVariant='icon'
            testId={`${testId}-dateinput-field`}
            locale={locale}
            mask={mask}
            name={name}
            id={id}
            required={required}
            value={formattedValue || placeholder}
            onBlur={onBlur}
            onValueChange={onValueChangeHandler}
            ref={refs.setReference}
            borderRadiusClassName={
              showDateStepperButtons ? 'rounded-none' : undefined
            }
            label={inputStyle === 'contained' ? label : undefined}
            {...commonReferenceProps}
            role='combobox'
          />
        ) : (
          <SelectButton
            id={id}
            name={name}
            testId={testId}
            disabled={disabled}
            invalid={invalid}
            isOpen={isOpen}
            size={size}
            selected={!!formattedValue}
            inlineBlock={inlineBlock}
            iconLeft={<CalendarTodayIcon accessibility='decorative' />}
            ref={refs.setReference}
            borderRadiusClassName={
              showDateStepperButtons ? 'rounded-none' : undefined
            }
            label={label}
            {...commonReferenceProps}
          >
            {selectButtonLabel}
          </SelectButton>
        )}
      </DateStepperButtons>

      {isOpen && (
        <PortalManagement context={context} isModal disableInitialFocus>
          <Overlay
            testId={`overlay-${testId}`}
            lockScroll
            className={zIndexClass}
          >
            <div
              tabIndex={-1}
              data-testid={`${testId}-content-wrapper`}
              {...getFloatingProps({
                style: {
                  ...floatingStyles,
                  maxHeight: 'calc(100vh - 16px)',
                  maxWidth: 'calc(100vw - 16px)'
                },
                className:
                  'inline-block outline-none overflow-auto dropdown-base'
              })}
              ref={refs.setFloating}
            >
              {showDefinedRanges ? (
                <DatePickerWithDefinedRanges
                  definedRanges={definedRanges}
                  onCustomDateChange={onCustomDateChange}
                  closeMenu={closeMenu}
                >
                  {calendar}
                </DatePickerWithDefinedRanges>
              ) : (
                calendar
              )}
            </div>
          </Overlay>
        </PortalManagement>
      )}
      {enableManualInput && isMobileManualInput.current && (
        <div className='flex flex-col'>
          <CustomRangeForm
            onChange={onCustomDateChange}
            closeMenu={() => {
              isMobileManualInput.current = false
              onOpenChange(false)
            }}
          >
            {calendar}
          </CustomRangeForm>
        </div>
      )}

      <HelperText
        testId={`${testId}-helper-text`}
        helperText={helperText}
        disabled={disabled}
        errorText={errorText}
        invalid={invalid}
        preserveHelpSpace={preserveHelpSpace}
      />
    </div>
  )
}

function getDefaultState<T>(
  value: T | undefined,
  enableManualInput: boolean
): Partial<DatePickerState<T>> {
  if (value) {
    return {
      enableManualInput,
      manualInputValue: value
    }
  } else {
    return {
      enableManualInput
    }
  }
}

const isDefinedRange = (
  value: any,
  definedRanges: DefaultStaticRange[] | undefined,
  showDefinedRanges: boolean | undefined
) => {
  if (definedRanges && showDefinedRanges && value) {
    value as DateRange
    return (
      definedRanges.find(
        (d) =>
          value?.from &&
          isSameDay(d.range.from, value.from) &&
          value?.to &&
          isSameDay(d.range.to, value?.to)
      ) !== undefined
    )
  }
  return false
}
