import * as React from 'react'
import { IconButton, IconButtonProps } from '@toasttab/buffet-pui-buttons'
import { Tooltip, TooltipProps } from '../Tooltip'

const defaultElement = 'button'

export type IconButtonWithTooltipProps<
  TElementType extends React.ElementType = typeof defaultElement
> = IconButtonProps<TElementType> & {
  tooltipContent?: React.ReactNode
  tooltipPlacement?: TooltipProps['placement']
  tooltipVariant?: TooltipProps['variant']
  tooltipId?: TooltipProps['id']
  tooltipMobileTimeout?: TooltipProps['mobileTimeout']
  tooltipAccessibility?: TooltipProps['accessibility']

  /** will default to the same value as `disabled` prop */
  tooltipContentDisabled?: boolean
}

export const IconButtonWithTooltip: <
  TElementType extends React.ElementType = typeof defaultElement
>(
  props: IconButtonWithTooltipProps<TElementType>
) => React.ReactElement | null = React.forwardRef(
  <TElementType extends React.ElementType = typeof defaultElement>(
    {
      tooltipContent,
      tooltipPlacement,
      tooltipVariant,
      tooltipAccessibility = 'label',
      tooltipId,
      tooltipMobileTimeout,
      testId,
      disabled,
      tooltipContentDisabled = disabled,
      ...buttonProps
    }: IconButtonWithTooltipProps<TElementType>,
    ref: typeof buttonProps.ref
  ) => {
    if (!tooltipContent) {
      return (
        <IconButton
          ref={ref}
          {...(buttonProps as IconButtonProps<TElementType>)}
          disabled={disabled}
          testId={testId}
        />
      )
    }
    if (disabled && tooltipContentDisabled === false) {
      // Then we have a button that is not keyboard navigable so we should us a wrapper trigger?
      return (
        <Tooltip
          id={tooltipId}
          placement={tooltipPlacement}
          variant={tooltipVariant}
          accessibility={tooltipAccessibility}
          mobileTimeout={tooltipMobileTimeout}
        >
          <Tooltip.Trigger className='inline-block outline-none rounded-full focus-visible:shadow-focus-inset'>
            <IconButton
              ref={ref}
              {...(buttonProps as IconButtonProps<TElementType>)}
              disabled={disabled}
              testId={testId}
            />
          </Tooltip.Trigger>
          <Tooltip.Content
            disabled={tooltipContentDisabled}
            testId={`${testId}-contents`}
          >
            {tooltipContent}
          </Tooltip.Content>
        </Tooltip>
      )
    }
    return (
      <Tooltip
        id={tooltipId}
        placement={tooltipPlacement}
        variant={tooltipVariant}
        accessibility={tooltipAccessibility}
        mobileTimeout={tooltipMobileTimeout}
      >
        <Tooltip.Trigger.IconButton
          ref={ref}
          {...(buttonProps as IconButtonProps<TElementType>)}
          disabled={disabled}
          testId={testId}
        />
        <Tooltip.Content
          disabled={tooltipContentDisabled}
          testId={`${testId}-contents`}
        >
          {tooltipContent}
        </Tooltip.Content>
      </Tooltip>
    )
  }
)
