import * as React from 'react'
import { useNavigationVersion } from '@toasttab/ec-features'
import { useHideEsxElement } from '@toasttab/ec-legacy-esx-hooks'
import { AddEmployeeModal } from '@toasttab/ec-employee-management'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const AddEmployeeModalWrapper = (props: Props) => {
  const { isOpen, onClose } = props

  const { version } = useNavigationVersion()

  // we hide the panel that says `New Hire Dashboard`
  // and has the `Import⬆️` and `Add➕` buttons
  // as those buttons are now handled by the team page navigation actions
  useHideEsxElement({
    selector: '.panel-heading',
    enabled: version === 'v3'
  })

  return (
    <AddEmployeeModal isOpen={isOpen} onCancel={onClose} onSuccess={() => {}} />
  )
}

export default AddEmployeeModalWrapper
