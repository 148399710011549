import * as React from 'react'
import { Link } from 'react-router-dom'
import { EmployeeTab } from './types'
import { Url } from './helpers'
import useEmployeeRedirectUrlQuery from './useEmployeeRedirectUrlQuery'
import { getEmployeeTabUrl } from './helpers'

// this component deals with the old format of link that is included in an employee payload on the list page

type PrefetchLinkProps = {
  url: Url
  fallback: React.ReactNode
  children: React.ReactNode
  employeeTab?: EmployeeTab
  mappingStatusFeature?: Boolean
}

const PrefetchLink = (props: PrefetchLinkProps) => {
  const { url, fallback, children, employeeTab, mappingStatusFeature } = props

  const query = useEmployeeRedirectUrlQuery(url)
  const redirectUrl = query.data
  const tabRedirectUrl = getEmployeeTabUrl(redirectUrl, employeeTab)

  if (tabRedirectUrl) {
    return (
      <Link
        to={tabRedirectUrl}
        className={mappingStatusFeature && 'text-link font-semibold'}
      >
        {children}
      </Link>
    )
  }

  return <>{fallback}</>
}

export { PrefetchLink }
