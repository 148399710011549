import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const CheckboxSelectedIcon = React.forwardRef(
  function CheckboxSelectedIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'sm'].className
              )}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9.7 14.8l7.7-7.5c.4-.4 1-.4 1.3 0 .4.4.4.9 0 1.3l-8.4 8.2c-.4.4-1 .4-1.3 0l-3.7-3.6c-.4-.4-.4-.9 0-1.3s1-.4 1.3 0l3.1 2.9z'
                fill='currentColor'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
