import * as React from 'react'
import classnames from 'classnames'
import { useIntersection } from 'react-use'
import { shouldLogWarning, useUniqueId } from '@toasttab/buffet-utils'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import styles from './styles.module.css'
import {
  useFloating,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  Placement,
  PortalManagement,
  FloatingTree,
  Overlay
} from '@toasttab/buffet-pui-floating-ui-base'
import { IconButton } from '@toasttab/buffet-pui-buttons'

const cx = classnames.bind(styles)
//#region Shared
export type ModalPin =
  | 'pin-center'
  | 'pin-flex'
  | 'pin-left'
  | 'pin-right'
  | 'pin-top'
  | 'pin-bottom'
export type ModalOverflowBehavior = 'body' | 'modal' | 'none'
export type ModalSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'full'

type CloseIconAttributes = {
  hideCloseIcon?: boolean
  hideCloseIconForDesktop?: boolean
  closeButtonLabel?: string
}

const MapModalPinToPlacement: Record<ModalPin, Partial<Placement> | undefined> =
  {
    'pin-bottom': 'bottom',
    'pin-center': undefined,
    'pin-left': 'left',
    'pin-right': 'right',
    'pin-flex': undefined,
    'pin-top': 'top'
  }

type ModalOverflow = {
  isOverflowTop: boolean
  isOverflowBottom: boolean
}

function useModal({
  isOpen,
  onRequestClose,
  onRequestOpen,
  overflowBehavior = 'body',
  hideCloseIcon,
  hideCloseIconForDesktop,
  closeButtonLabel = 'Close',
  ...rest
}: BuffetModalProps): BuffetModalProps &
  ReturnType<typeof useDismiss> &
  ReturnType<typeof useInteractions> &
  ReturnType<typeof useFloating> &
  ReturnType<typeof useRole> & {
    labelId?: string
    setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>
    descriptionId?: string
    setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>
    overflow: ModalOverflow
    setOverflow: React.Dispatch<React.SetStateAction<ModalOverflow>>
    setCloseIconAttributes: React.Dispatch<
      React.SetStateAction<CloseIconAttributes>
    >
    closeIconAttributes: CloseIconAttributes
    isHeaderRendered: boolean
    setIsHeaderRendered: React.Dispatch<React.SetStateAction<boolean>>
  } {
  const [labelId, setLabelId] = React.useState<string | undefined>()
  const [overflow, setOverflow] = React.useState<ModalOverflow>({
    isOverflowBottom: false,
    isOverflowTop: false
  })
  const [descriptionId, setDescriptionId] = React.useState<string | undefined>()
  const [closeIconAttributes, setCloseIconAttributes] =
    React.useState<CloseIconAttributes>({
      hideCloseIcon,
      hideCloseIconForDesktop,
      closeButtonLabel
    })
  const [isHeaderRendered, setIsHeaderRendered] = React.useState(false)

  const data = useFloating({
    open: isOpen,
    onOpenChange: onRequestClose,
    placement: rest.position && MapModalPinToPlacement[rest.position]
  })

  const context = data.context
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    escapeKey: rest.shouldCloseOnEsc,
    outsidePress: (event) => {
      if (!!onRequestClose && rest.shouldCloseOnOverlayClick) {
        // The overlay is the only reason we know of to dismiss this modal on pressing outside of it
        if (
          event.target !== data.refs.floating.current?.closest('#modal-overlay')
        ) {
          if (shouldLogWarning()) {
            console.warn(
              'received outsidePress from a target that was not this modal overlay. The only way this is known to happen is when one modal is on top of another. Buffet may drop support for this in the future. Please consider other designs that do not nest modals.'
            )
          }
          return false
        }
        event.stopPropagation()
        event.preventDefault()
        return true
      }
      return false
    }
  })
  const role = useRole(context)

  const interactions = useInteractions([dismiss, role])

  return React.useMemo(
    () => ({
      isOpen,
      onRequestClose,
      onRequestOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
      overflowBehavior,
      overflow,
      setOverflow,
      closeIconAttributes,
      setCloseIconAttributes,
      setIsHeaderRendered,
      isHeaderRendered,
      ...rest
    }),
    [
      isOpen,
      onRequestClose,
      onRequestOpen,
      interactions,
      data,
      labelId,
      descriptionId,
      overflowBehavior,
      overflow,
      closeIconAttributes,
      isHeaderRendered,
      rest
    ]
  )
}

type ContextType = ReturnType<typeof useModal> | null

const ModalContext = React.createContext<ContextType>(null)

const useModalContext = () => {
  const context = React.useContext(ModalContext)

  if (context == null) {
    throw new Error('Modal components must be wrapped in <Modal />')
  }

  return context
}

const getSizeClassName = (size: ModalSize, position: ModalPin) => {
  if (size === 'full') {
    return 'w-full md:mx-6 '
  }
  if (['pin-bottom', 'pin-top'].includes(position)) {
    switch (size) {
      case 'sm':
        return 'h-1/4 w-full'
      case 'md':
        return 'h-1/3 w-full'
      case 'lg':
        return 'h-1/2 w-full'
      case 'xl':
        return 'h-3/4 w-full'
      case 'xxl':
        return 'h-5/6 w-full'
      default:
        return 'h-1/2 w-full'
    }
  }
  switch (size) {
    case 'xxl':
      return 'md:max-w-2xl'
    case 'xl':
      return 'md:max-w-xl'
    case 'lg':
      return 'md:max-w-lg'
    default:
    case 'md':
      return 'md:max-w-md'
    case 'sm':
      return 'md:max-w-sm'
  }
}
//#endregion

//#region Modal Header
export interface ModalHeaderProps {
  /**
   * Hide the close icon for all screen sizes;
   * note that the modal must specify an onRequestClose function
   * @deprecated Please apply this prop on the outer Modal component
   */
  hideCloseIcon?: boolean
  /**
   * Hide the close icon only for medium screen sizes and higher;
   * There is a school of thought that the close icon should be hidden on desktop
   * when we have a cancel button in the footer;
   * note that the modal must specify an onRequestClose function
   * @deprecated Please apply this prop on the outer Modal component
   */
  hideCloseIconForDesktop?: boolean
  /**
   * The accessible label for the close button/icon
   * @deprecated Please apply this prop on the outer Modal component
   */
  closeButtonLabel?: string
  /**
   * The id of the heading element for the modal (use this for your aria.labelledby property on the Modal component)
   */
  headingId?: string
}

export const ModalHeader = ({
  children,
  hideCloseIcon: deprecatedHideCloseIcon, // eslint-disable-line
  hideCloseIconForDesktop: deprecatedHideCloseIconForDesktop, // eslint-disable-line
  closeButtonLabel: deprecatedCloseButtonLabel, // eslint-disable-line
  headingId
}: React.PropsWithChildren<ModalHeaderProps>) => {
  const {
    overflowBehavior,
    overflow,
    setLabelId,
    setCloseIconAttributes,
    closeIconAttributes,
    onRequestClose,
    setIsHeaderRendered,
    hideCloseIcon,
    hideCloseIconForDesktop,
    closeButtonLabel
  } = useModalContext()
  const generatedId = useUniqueId(headingId, 'modal-heading-')

  const id = headingId || generatedId

  React.useEffect(() => {
    if (
      deprecatedHideCloseIcon ||
      deprecatedHideCloseIconForDesktop ||
      deprecatedCloseButtonLabel
    ) {
      setCloseIconAttributes({
        hideCloseIcon: deprecatedHideCloseIcon || hideCloseIcon,
        hideCloseIconForDesktop:
          deprecatedHideCloseIconForDesktop || hideCloseIconForDesktop,
        closeButtonLabel: deprecatedCloseButtonLabel || closeButtonLabel
      })
    }
  }, [
    deprecatedHideCloseIcon,
    hideCloseIcon,
    deprecatedHideCloseIconForDesktop,
    hideCloseIconForDesktop,
    deprecatedCloseButtonLabel,
    closeButtonLabel,
    setCloseIconAttributes
  ])

  React.useEffect(() => {
    setIsHeaderRendered(true)
  }, [setIsHeaderRendered])

  const hasCloseIcon = React.useMemo(() => {
    return !!onRequestClose && !closeIconAttributes.hideCloseIcon
  }, [onRequestClose, closeIconAttributes.hideCloseIcon])

  // Only sets `aria-labelledby` on the Modal root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setLabelId(id)
    return () => setLabelId(undefined)
  }, [id, setLabelId])
  return (
    <>
      <div
        className={cx(
          'pb-2 font-bold type-headline-5 text-default px-4 md:px-6 flex-none box-border',
          {
            'mr-14 pr-1': hasCloseIcon
          }
        )}
        style={{ lineHeight: '1.25' }}
      >
        <div id={id}>{children}</div>
      </div>
      {overflowBehavior === 'body' && (
        // elevation: Sticky content headers use z-10 according to our guidelines
        <div
          className={cx('h-0 z-10 overflow-visible w-full', {
            hidden: !overflow?.isOverflowTop
          })}
        >
          <div
            style={{
              height: '4px',
              background:
                'linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.0))'
            }}
          />
        </div>
      )}
    </>
  )
}

//#endregion

//#region Modal Body
export interface ModalBodyProps {
  className?: string
}

export const ModalScroller = ({
  children
}: React.PropsWithChildren<unknown>) => {
  const { setOverflow } = useModalContext()

  const topElRef = React.useRef<HTMLDivElement>(null)
  const bottomElRef = React.useRef<HTMLDivElement>(null)
  const modalIntersectionOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  }
  const topIntersection = useIntersection(topElRef, modalIntersectionOptions)
  const bottomIntersection = useIntersection(
    bottomElRef,
    modalIntersectionOptions
  )

  React.useEffect(() => {
    setOverflow({
      isOverflowTop: Boolean(
        topIntersection && !topIntersection.isIntersecting
      ),
      isOverflowBottom: Boolean(
        bottomIntersection && !bottomIntersection.isIntersecting
      )
    })
  }, [topIntersection, bottomIntersection, setOverflow])

  return (
    <>
      <div ref={topElRef} />
      {children}
      <div ref={bottomElRef} />
    </>
  )
}

export const ModalBody = ({
  children,
  className,
  ...props
}: React.PropsWithChildren<ModalBodyProps>) => {
  const {
    overflowBehavior,
    noHeader,
    noFooter,
    setDescriptionId,
    onRequestOpen
  } = useModalContext()
  const id = useUniqueId(null, 'modal-body').toString()
  // Only sets `aria-describedby` on the Modal root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setDescriptionId(id.toString())
    return () => setDescriptionId(undefined)
  }, [id, setDescriptionId])

  React.useEffect(() => {
    onRequestOpen?.()
  }, [onRequestOpen])

  return (
    <div
      className={cx(
        'flex flex-col flex-grow px-4 md:px-6 box-border',
        className,
        {
          'pt-6': noHeader,
          'pb-6': noFooter,
          'overflow-auto': overflowBehavior === 'body'
        }
      )}
      {...props}
      id={id}
      data-overflow-behavior={overflowBehavior}
    >
      {overflowBehavior === 'body' ? (
        <ModalScroller>{children}</ModalScroller>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

//#endregion

//#region Modal Footer

export interface ModalFooterProps {
  stackOnMobile?: boolean
  buttonSize?:
    | 'lg'
    | 'sm'
    | 'base' // deprecated in favor of 'lg'
    | 'auto'
}

export const ModalFooter = ({
  stackOnMobile = false,
  buttonSize = 'auto',
  children
}: React.PropsWithChildren<ModalFooterProps>) => {
  const { overflowBehavior, overflow } = useModalContext()

  return (
    <>
      {overflowBehavior === 'body' && (
        // elevation: Sticky content headers use z-10 according to our guidelines
        <div
          className={cx('h-0  z-10 overflow-visible w-full relative', {
            hidden: !overflow?.isOverflowBottom
          })}
        >
          <div
            className='absolute bottom-0 w-full'
            style={{
              height: '4px',
              background:
                'linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.12))'
            }}
          />
        </div>
      )}
      <div
        className={cx(
          'flex items-center justify-end flex-none h-auto p-4 md:p-6 box-border -mb-6',
          {
            'space-x-4': buttonSize === 'base' && !stackOnMobile,
            'space-x-2': buttonSize === 'sm' && !stackOnMobile,
            'space-x-4 md:space-x-2': buttonSize === 'auto' && !stackOnMobile,
            'flex-row': !stackOnMobile,
            'space-y-4 sm:space-y-0 sm:space-x-2':
              (buttonSize === 'base' || buttonSize === 'auto') && stackOnMobile,
            'space-y-2 sm:space-y-0 sm:space-x-2':
              buttonSize === 'sm' && stackOnMobile,
            'flex-col sm:flex-row': stackOnMobile
          }
        )}
      >
        {children}
      </div>
    </>
  )
}
//#endregion

//#region Modal

interface BaseModalProps {
  /** Sizes based on max-w-* tailwind classes */
  size?: ModalSize

  // The following properties override the inherited ReactModal.Props
  // This is done to improve the documentation in storybook

  /**
   * Boolean describing if the modal should be shown or not. Defaults to false.
   */
  isOpen: boolean

  /** String or object className to be applied to the modal content. */
  className?: string

  /** String or object className to be applied to the overlay. */
  overlayClassName?: string

  /** Function that will be run when the modal is requested to be closed, prior to actually closing. */
  onRequestClose?: () => void

  /** Function that will be run when the modal is first opened. */
  onRequestOpen?: () => void

  /** Boolean indicating if the overlay should close the modal. Defaults to true. */
  shouldCloseOnOverlayClick?: boolean

  /** Boolean indicating if pressing the esc key should close the modal */
  shouldCloseOnEsc?: boolean

  /** Function that will be called to get the parent element that the modal will be attached to.
   * @deprecated Please use the default portal behavior to ensure the tooltip contents are correctly css scoped
   */
  parentSelector?: () => HTMLElement | null

  /** String value of data-test-id attribute to be applied to to the modal content. */
  testId?: string

  /**
   * Hide the close icon for all screen sizes;
   * note that the modal must specify an onRequestClose function
   */
  hideCloseIcon?: boolean

  /**
   * Hide the close icon only for medium screen sizes and higher;
   * There is a school of thought that the close icon should be hidden on desktop
   * when we have a cancel button in the footer;
   * note that the modal must specify an onRequestClose function
   */
  hideCloseIconForDesktop?: boolean

  /**
   * The accessible label for the close button/icon
   */
  closeButtonLabel?: string

  /**
   * By default a modal will auto-focus to the first focusable element in the modal body.
   * This is particularly useful when you have input fields in your modal body. But there
   * could be times when you need to disable this behavior and if you find yourself in such
   * a situation, set `disableInitialFocus` to true. Note that you can use the `onRequestOpen`
   * prop to manually place the focus on an element of your choice if you find yourself in this
   * situation.
   */
  disableInitialFocus?: boolean
}

interface BuffetModalProps extends BaseModalProps {
  innerClassName: string
  overflowBehavior: ModalOverflowBehavior
  noHeader?: boolean
  noFooter?: boolean
  position: ModalPin
}

// Unstyled buffet modal
const BuffetModal = React.forwardRef(
  (
    {
      children,
      disableInitialFocus,
      ...props
    }: React.PropsWithChildren<BuffetModalProps>,
    propRef
  ) => {
    const modal = useModal(props)
    const ref = useMergeRefs([modal.refs.setFloating, propRef])
    const {
      isOpen,
      overlayClassName,
      labelId,
      descriptionId,
      getFloatingProps,
      context: floatingContext,
      innerClassName,
      parentSelector, // eslint-disable-line
      className,
      testId: providedTestId
    } = modal
    const testId = useUniqueId(providedTestId, 'modal-')
    const _parentSelector = parentSelector?.() || null
    return (
      <ModalContext.Provider value={modal}>
        <FloatingTree>
          {isOpen && (
            <PortalManagement
              context={floatingContext}
              isModal={true}
              portalContainerEl={_parentSelector}
              disableInitialFocus={disableInitialFocus || false}
            >
              <Overlay
                className={overlayClassName}
                testId={`overlay-${testId}`}
                lockScroll
                id='modal-overlay'
              >
                <div
                  data-testid={testId}
                  ref={ref}
                  aria-labelledby={labelId}
                  aria-describedby={descriptionId}
                  aria-modal
                  {...getFloatingProps({ className: className })}
                >
                  <div className={innerClassName}>
                    {children}
                    <BuffetModalCloseIconButton />
                  </div>
                </div>
              </Overlay>
            </PortalManagement>
          )}
        </FloatingTree>
      </ModalContext.Provider>
    )
  }
)

const BuffetModalCloseIconButton = () => {
  const { onRequestClose, closeIconAttributes, testId, isHeaderRendered } =
    useModalContext()

  const hasCloseIcon = React.useMemo(() => {
    return (
      !!onRequestClose && !closeIconAttributes.hideCloseIcon && isHeaderRendered
    )
  }, [onRequestClose, closeIconAttributes.hideCloseIcon, isHeaderRendered])

  if (!hasCloseIcon) {
    return null
  }

  return (
    <div
      className={cx('absolute top-0 right-0 pt-3 pr-3', {
        'md:hidden': closeIconAttributes.hideCloseIconForDesktop
      })}
    >
      <IconButton
        icon={<CloseIcon aria-label={closeIconAttributes.closeButtonLabel} />}
        iconColor='secondary'
        onClick={onRequestClose}
        data-testid={`${testId}-close-icon`}
      />
    </div>
  )
}

const CLASS_NAME_COMMON = cx(
  'w-full outline-none pointer-events-none box-border',
  'absolute'
)
// elevation: modals use z-40 according to our guidelines
const OVERLAY_CLASS_NAME_COMMON = 'bg-darken-56 inset-0 z-40'
const INNER_CLASS_NAME_COMMON = cx(
  'bg-white',
  'text-secondary type-default',
  'pointer-events-auto'
)

export interface ModalProps extends BaseModalProps {
  /**
   * Use "body" to overflow just the body, fixing the header and footer;
   * Use "modal" use inline header and footer;
   * Use "none" to prevent any overflow -- only use this if you don't have the ability to portal stuff and you are _certain_ your body content will never be taller than the screen
   * @deprecated: This prop is being removed in a future version. The body overflow behavior is the default and the behavior we want to use.
   */
  overflowBehavior?: ModalOverflowBehavior
  /**
   * pin-flex also pins to the center, but using flexbox. There is some hesitation to use it broadly.
   * However, it may  solve some issues in the body of the modal related to drag and drop caused by pin-center
   */
  position?: ModalPin
  /**
   * A flag that you can set to help the modal component layout
   * its children when you use a Modal with no ModalHeader.
   * It shifts the top padding from the Modal to the ModalBody
   * to allow the body to scroll flush up against the top of the modal
   */
  noHeader?: boolean
  /**
   * A flag that you can set to help the modal component layout
   * its children when you use a Modal with no ModalFooter.
   * It shifts the bottom padding from the Modal to the ModalBody
   * to allow the body to scroll flush up against the bottom of the modal
   */
  noFooter?: boolean
}

export const Modal = ({
  overflowBehavior = 'body', // eslint-disable-line
  size = 'md',
  position = 'pin-center',
  testId,
  className,
  overlayClassName,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  parentSelector, // eslint-disable-line
  noHeader,
  noFooter,
  ...props
}: React.PropsWithChildren<ModalProps>) => (
  <BuffetModal
    position={position}
    className={cx(
      CLASS_NAME_COMMON,
      'max-h-screen',
      {
        'flex md:py-6': position === 'pin-center' || position === 'pin-flex',
        'md:pin-center md:h-auto': position === 'pin-center',
        'items-center': position === 'pin-flex'
      },
      styles.container,
      className
    )}
    overlayClassName={cx(
      OVERLAY_CLASS_NAME_COMMON,
      {
        'flex flex-col justify-center': position === 'pin-flex'
      },
      overlayClassName
    )}
    innerClassName={cx(
      INNER_CLASS_NAME_COMMON,
      'w-full max-h-full box-border',
      { 'h-full': ['pin-left', 'pin-right', 'pin-flex'].includes(position) },
      { relative: position === 'pin-center' || position === 'pin-flex' },
      {
        'pt-6': !noHeader,
        'pb-6': !noFooter,
        'md:h-auto md:rounded-modal mx-auto md:shadow-3xl': [
          'pin-center',
          'pin-flex'
        ].includes(position),
        'ml-auto': position === 'pin-right',
        'absolute bottom-0': position === 'pin-bottom',
        'flex flex-col': overflowBehavior !== 'modal',
        'overflow-auto': overflowBehavior === 'modal'
      },
      getSizeClassName(size, position),
      className
    )}
    overflowBehavior={overflowBehavior}
    size={size}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    shouldCloseOnEsc={shouldCloseOnEsc}
    parentSelector={parentSelector}
    noHeader={noHeader}
    noFooter={noFooter}
    testId={testId}
    {...props}
  />
)

export interface AlertModalProps extends BaseModalProps {}

const getAlertSizeClassName = (size: ModalSize) => {
  switch (size) {
    case 'xxl':
      return 'max-w-2xl'
    case 'xl':
      return 'max-w-xl'
    case 'lg':
      return 'max-w-lg'
    default:
    case 'md':
      return 'max-w-md'
    case 'sm':
      return 'max-w-sm'
  }
}
export const AlertModal = ({
  // Buffet Props
  size = 'md',

  // ReactModal Props
  className,
  overlayClassName,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  parentSelector, // eslint-disable-line
  testId,
  ...props
}: React.PropsWithChildren<AlertModalProps>) => (
  <BuffetModal
    position='pin-center'
    className={cx(
      CLASS_NAME_COMMON,
      'absolute flex pin-center',
      'p-6 h-auto max-h-screen',
      className
    )}
    overlayClassName={cx(
      OVERLAY_CLASS_NAME_COMMON,
      'flex flex-col justify-center',
      overlayClassName
    )}
    innerClassName={cx(
      INNER_CLASS_NAME_COMMON,
      'py-6',
      'w-full max-h-full box-border',
      'mx-auto rounded-modal shadow-3xl h-auto',
      'flex flex-col',
      'relative',
      getAlertSizeClassName(size),
      className
    )}
    overflowBehavior='none'
    size={size}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    shouldCloseOnEsc={shouldCloseOnEsc}
    parentSelector={parentSelector}
    testId={testId}
    {...props}
  />
)

//#endregion

/**
 * The top portion of a modal, designed to be inside of a <Modal /> component
 *
 * @example
 *
 * <Modal isOpen={true} onRequestClose={() => hide()}>
 *  <Modal.Header>
 *    Edit Employee
 *  </Modal.Header>
 * </Modal>
 */
Modal.Header = ModalHeader

/**
 * The inner portion of a modal, designed to be inside of a <Modal /> component. Should not encapsulate a <Modal.Footer />
 *
 * @example
 *
 * <Modal isOpen={true} onRequestClose={() => hide()}>
 *  <Modal.Body>Hello!</Modal.Body>
 * </Modal>
 */
Modal.Body = ModalBody

/**
 * The bottom portion of a modal, designed to be inside of a <Modal /> component
 *
 * @example
 *
 * <Modal isOpen={true} onRequestClose={() => hide()}>
 *  <Modal.Footer>
 *    <Button>Close</Button>
 *  </Modal.Footer>
 * </Modal>
 */
Modal.Footer = ModalFooter

/**
 * This is a jsx 'type' only; for typscript applications, it's preferred to use the type-safe strings directly
 */
Modal.OverflowBehavior = Object.freeze({
  body: 'body',
  modal: 'modal',
  none: 'none'
})

/**
 * This is a jsx 'type' only; for typscript applications, it's preferred to use the type-safe strings directly
 */
Modal.Size = Object.freeze({
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl'
})
