import { CalendarProps } from '@toasttab/buffet-pui-calendar'
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear
} from '@toasttab/buffet-pui-date-utilities'

import React from 'react'

type UseCalendarConstraintsProps = Pick<
  CalendarProps,
  // https://react-day-picker.js.org/api/interfaces/DayPickerDefaultProps#fromdate <--
  'fromDate' | 'toDate' | 'fromMonth' | 'toMonth' | 'fromYear' | 'toYear'
>

interface UseCalendarConstraintsOutput {
  minDate?: Date
  maxDate?: Date
}

/**
  useCalendarConstraints:
  Unify any min/max dates that might be set on the DatePicker - we want to mimic the 
  lib behaviour so that our min/max always aligns with the Calendar component.
  Consumers shouldn't be using multiple from props or multiple to props, but just in case...
  We'll emulate what the calendar library does here when props are passed for multiple types of date limits.
  I tested all of these combinations against the DayPicker to see what props win. Rock, paper, scissors style.
  ----------------------------------------------------------------
  fromMonth beats fromDate: with both set, fromDate gets ignored
  fromYear  beats fromDate: with both set, fromDate gets ignored
  fromMonth beats fromYear: with both set, fromYear gets ignored
  toMonth   beats toDate: with both set, toDate gets ignored
  toYear    beats toDate: with both set, toDate gets ignored
  toMonth   beats toYear: with both set, toYear gets ignored
  ----------------------------------------------------------------
  With all three set, month always seems to win.
*/
export function useCalendarConstraints({
  fromDate,
  toDate,
  fromMonth,
  toMonth,
  fromYear,
  toYear
}: UseCalendarConstraintsProps): UseCalendarConstraintsOutput {
  const unifiedFromDate: Date | undefined = React.useMemo(() => {
    if (fromMonth) return startOfMonth(fromMonth)
    if (fromYear) return startOfYear(fromYear)
    if (fromDate) return startOfDay(fromDate)
  }, [fromDate, fromMonth, fromYear])

  const unifiedToDate: Date | undefined = React.useMemo(() => {
    if (toMonth) return endOfMonth(toMonth)
    if (toYear) return endOfYear(toYear)
    if (toDate) return endOfDay(toDate)
  }, [toDate, toMonth, toYear])

  return {
    minDate: unifiedFromDate,
    maxDate: unifiedToDate
  }
}
