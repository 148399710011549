type LocalizableMessage = {
  key: string
  message: string
}

const isLocalizabaleMessage = (msg: any): msg is LocalizableMessage => {
  return !!(typeof msg?.message === 'string')
}

const getLabel = (item: any): string => {
  if (!item) return ''

  if (isLocalizabaleMessage(item?.label)) {
    return item.label.message
  }

  if (isLocalizabaleMessage(item?.labelNew)) {
    return item.labelNew.message
  }

  if (typeof item === 'string') return item

  if (typeof item?.label === 'string') return item.label

  if (isLocalizabaleMessage(item)) {
    return item.message
  }

  return ''
}

export { getLabel }
