import * as React from 'react'
import { Portal } from '@toasttab/ec-layout'
import { EcPageHeader } from '@toasttab/ec-ui-page-header'
import { useEcTitle } from '@toasttab/ec-title'
import { useTeamPageItems } from '../../hooks'
import { getLabel } from '../../helpers'

type Props = {
  backHref?: string
  title: string
  subTitle?: string
}

const TeamPageHeader = (props: Props) => {
  const { backHref, title } = props

  const { teamLabel } = useTeamPageItems()

  const subTitle = getLabel(
    'subTitle' in props ? props.subTitle : teamLabel || 'Team'
  )

  useEcTitle(title)

  return (
    <Portal
      id='page-header'
      component={
        <EcPageHeader backHref={backHref} title={title} subTitle={subTitle} />
      }
    />
  )
}

export default TeamPageHeader
