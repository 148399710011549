import * as React from 'react'

import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { RenderItemProps, SelectItem, TruncateOption } from '../types'
import { SelectAll, SelectAllProps } from '../SelectAll'
import { SelectItemDefaultContent } from '../Select/SelectItemDefaultContent'
import { selectAllKey } from '../useSelect'
import { SelectMultipleItemContainer } from './SelectMultipleItemContainer'
import { isSelectOption, ItemToStringFn, shouldTruncate } from '../utils'

export type SelectMultipleListOptionsProps<
  TValue,
  TItem extends SelectItem<TValue>
> = TestIdentifiable & {
  highlightedIndex: number | null
  options: TItem[]
  selectedItems: TItem[]
  /** Set to true to hide the label but still apply as aria-label */
  itemToValue: (arg: TItem) => TValue
  /** a function that determines how each item (option) will be rendered */
  renderItem?: (arg: RenderItemProps<TValue, TItem>) => React.ReactElement
  /** a function used to determine what the text value of an object is*/
  itemToString: ItemToStringFn<TValue, TItem>
  /** Alternative label for select all*/
  selectAllLabel?: string
  getItemProps: (a: any) => any
  showSelectAll?: boolean
  isAllSelected: boolean
  isOpen: boolean
  truncateOption?: TruncateOption
  handleSelect: (item: TItem) => void
  listRef: React.MutableRefObject<(HTMLElement | null)[]>
  listContentRef: React.MutableRefObject<(string | null)[]>
  enableSearch?: boolean
}

export const SelectMultipleListOptions = <
  TValue,
  TItem extends SelectItem<TValue>
>({
  testId = 'selectMultipleListOptionsContainer',
  highlightedIndex,
  options,
  itemToString,
  itemToValue,
  selectedItems,
  getItemProps,
  renderItem,
  selectAllLabel,
  isAllSelected,
  truncateOption,
  handleSelect,
  listRef,
  listContentRef,
  enableSearch
}: SelectMultipleListOptionsProps<TValue, TItem>) => {
  return (
    // a11y guidelines suggest adding a tabIndex here (because the ul can be scrollable). But a) that breaks the tab order and b) the up and down
    // arrows move up and down the options (i.e. they determin scroll by selection rather than acting directly on the ul)
    <ul
      className='py-2 overflow-auto'
      // 16px matches the "shift: { padding: 8 }" middleware in floating-ui
      style={{ maxHeight: 'min(100vh - 16px, 16rem)' }}
      role='group'
    >
      {options.map((item, i) => {
        const index: number = i
        const label = itemToString(item)
        const val = itemToValue(item)
        const checked = !!selectedItems.find(
          (selectedItem) => itemToValue(selectedItem) === val
        )
        const itemDisabled = isSelectOption(item) ? item.disabled : false

        const itemProps = {
          ...getItemProps({
            // Handle pointer select.
            onClick() {
              if (!itemDisabled) {
                handleSelect(item)
              }
            },
            // Handle keyboard select.
            onKeyDown(event: any) {
              if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault()
                handleSelect(item)
              }
            },
            disabled: itemDisabled,
            // with search enabled we never put the focus on the items themselves
            tabIndex: index === highlightedIndex && !enableSearch ? 0 : -1,
            'aria-checked': checked,
            checked,
            role: 'option'
          })
        }

        if (itemToString(item) === selectAllKey) {
          // derive the checkbox status
          let checkStatus: SelectAllProps['checkStatus'] = 'unchecked'
          if (isAllSelected) {
            checkStatus = 'checked'
          } else if (selectedItems.length > 0) {
            checkStatus = 'partial'
          }
          // render with status
          return (
            <SelectAll
              checkStatus={checkStatus}
              highlightedIndex={highlightedIndex}
              index={index}
              itemProps={itemProps}
              key={`${label}-select-all`}
              listRef={listRef}
            >
              <SelectItemDefaultContent
                label={selectAllLabel || 'Select all'}
              />
            </SelectAll>
          )
        }
        if (renderItem) {
          return renderItem({
            item,
            index,
            itemProps,
            highlightedIndex,
            listRef,
            listContentRef
          })
        }

        const itemSubLabel = isSelectOption(item)
          ? `${item.subLabel || ''}`
          : null

        const enableTruncate = shouldTruncate(
          truncateOption || 'none',
          label,
          itemSubLabel || ''
        )

        return (
          <SelectMultipleItemContainer
            testId={testId}
            index={index}
            itemProps={itemProps}
            highlightedIndex={highlightedIndex}
            key={`${label}${index}`}
            truncate={enableTruncate}
            listRef={listRef}
            listContentRef={listContentRef}
          >
            <SelectItemDefaultContent
              label={label}
              subLabel={itemSubLabel}
              showSubLabel={!!itemSubLabel}
              disabled={itemDisabled}
              truncateOption={truncateOption}
            />
          </SelectMultipleItemContainer>
        )
      })}
    </ul>
  )
}
