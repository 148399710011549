import * as React from 'react'
import { Variant } from './MenuDropdown'

interface MenuDropdownContextProps {
  variant: Variant
  preventCloseOnClick: boolean
  closeDropdown: Function
}

export const MenuDropdownContext =
  React.createContext<MenuDropdownContextProps>({
    variant: 'white',
    preventCloseOnClick: false,
    closeDropdown: () => null
  })

export const useMenuDropdownContext = () => {
  return React.useContext(MenuDropdownContext)
}
