import * as React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button, LinkButton, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from '../hooks'

type Props = {
  title?: React.ReactNode
  onSubmit: () => void
  onCancel: () => void
  onClearFilters?: () => void
  submitLabel?: React.ReactNode
  children: React.ReactNode
  danger?: boolean
  clearFilters?: boolean
  canClearFilters?: boolean
}

const EmployeeListModal = (props: Props) => {
  const {
    title,
    onCancel,
    onSubmit,
    submitLabel,
    danger,
    children,
    clearFilters,
    onClearFilters,
    canClearFilters
  } = props

  const { t } = useTranslation()

  return (
    <Modal size='xxl' isOpen onRequestClose={onCancel} overflowBehavior='none'>
      {title && <Modal.Header>{title}</Modal.Header>}

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        <ButtonGroup>
          {clearFilters && (
            <Button
              variant='link'
              onClick={onClearFilters}
              disabled={!canClearFilters}
            >
              {t('clearFilters')}
            </Button>
          )}

          <LinkButton onClick={onCancel}>{t('cancel')}</LinkButton>

          <Button
            variant={danger ? 'destructive' : undefined}
            onClick={onSubmit}
          >
            {submitLabel || t('submit')}
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  )
}

export default EmployeeListModal
