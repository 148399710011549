import { Url } from './helpers'
import { useQuery } from '@tanstack/react-query'
import { getRedirectUrl } from './helpers'

const useEmployeeRedirectUrlQuery = (url: Url) => {
  return useQuery({
    queryFn: async () => getRedirectUrl(url),

    queryKey: ['employeeLink', url],

    enabled: !!url,

    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: Infinity
  })
}

export default useEmployeeRedirectUrlQuery
