import * as React from 'react'
import {
  nextEnabledSibling,
  previousEnabledSibling,
  Sibling
} from '@toasttab/buffet-pui-dropdown-utilities'
import { useMenuDropdownContext } from './MenuDropdownContext'

export function useListItemOnKeyDown<T extends HTMLElement>({
  onEnter
}: {
  onEnter: (event: React.KeyboardEvent<T>) => void
}) {
  const { closeDropdown } = useMenuDropdownContext()

  return (event: React.KeyboardEvent<T>) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault() // prevent scrolling
        const nextSibling = nextEnabledSibling(
          (event.currentTarget as Element)?.nextSibling as Sibling
        )
        nextSibling && nextSibling.focus()
        break
      case 'ArrowUp':
        event.preventDefault() // prevent scrolling
        const previousSibling = previousEnabledSibling(
          (event.currentTarget as Element)?.previousSibling as Sibling
        )
        previousSibling && previousSibling.focus()
        break
      case 'Enter':
        onEnter(event)
        break
      case 'Escape':
        closeDropdown()
        break
      case 'Tab':
        closeDropdown()
        break
      default:
        break
    }
  }
}
