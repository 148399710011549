import * as React from 'react'
import { IconProps } from '@toasttab/buffet-pui-icons'
import cx from 'classnames'
import {
  getIconButtonStyle,
  GetIconButtonStyleProps
} from '../IconButton/getIconButtonStyle'

export interface IconLinkButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    GetIconButtonStyleProps {
  targetBlank?: boolean
  testId?: string | number
  icon: React.FunctionComponentElement<IconProps>
}

/**
 * @deprecated - use `<IconButton as='a' ...` instead.
 *
 * This component predated the `as` prop on IconButton.
 * We are deprecating because react-router-dom has a Link component,
 * which makes this IconLinkButton component name ambiguous.
 **/
export const IconLinkButton = React.forwardRef<
  HTMLAnchorElement,
  IconLinkButtonProps
>(({ testId, icon, href = '#', targetBlank, className, ...props }, ref) => {
  const { button, icon: _icon } = getIconButtonStyle(props)
  // Avoid spreading non button props in button element, that could cause warnings
  const {
    contained,
    cropToIcon,
    cropToIconMarginClassName,
    disabled,
    iconColor,
    size,
    textClassName,
    ...buttonProps
  } = props
  return (
    <a
      href={href}
      {...(targetBlank && {
        target: '_blank',
        rel: 'noopener noreferrer'
      })}
      ref={ref}
      data-testid={testId}
      className={cx(button.className, className)}
      style={button.style}
      {...buttonProps}
    >
      {React.cloneElement<IconProps>(icon, {
        ...icon.props,
        className: cx(icon?.props?.className, _icon.className)
      })}
    </a>
  )
})

// eslint-disable-next-line
IconLinkButton.displayName = 'IconLinkButton'
