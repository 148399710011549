import { useEffect } from 'react'
import { useEcProps } from '@toasttab/ec-banquet-props'

type Args = {
  href: string
  enabled: boolean
}

const useRedirect = (args: Args) => {
  const { href } = args

  const enabled = !!(href && args.enabled)

  const { ecNavigate } = useEcProps()

  useEffect(() => {
    if (enabled) {
      ecNavigate(href)
    }
  }, [enabled]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useRedirect
