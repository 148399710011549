import * as React from 'react'
import { Suspense } from 'react'
import { ErrorBoundary } from '../components'
import { PortalProvider } from '@toasttab/ec-layout'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { TranslationProvider } from '@local/translations'
import { useEcProps } from '@toasttab/ec-banquet-props'

const SECONDS = 1000

const MINUTES = 60 * SECONDS

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * MINUTES,
      cacheTime: 5 * MINUTES,
      refetchOnMount: 'always'
    }
  }
})

type Props = {
  children: React.ReactNode
}

const TeamAppProviders = ({ children }: Props) => {
  const { i18n } = useEcProps()

  return (
    <ErrorBoundary>
      <Suspense fallback={<></>}>
        <TranslationProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <PortalProvider value='data-pui-2'>{children}</PortalProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </TranslationProvider>
      </Suspense>
    </ErrorBoundary>
  )
}

export default TeamAppProviders
