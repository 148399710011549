/* eslint-disable @toasttab/buffet/date-formats */
import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  differenceInMonths,
  endOfMonth,
  format,
  formatRange,
  Formats,
  isAfter,
  isBefore,
  isDate,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  startOfMonth,
  subDays,
  subMonths
} from '@toasttab/buffet-pui-date-utilities'

import React from 'react'
import { DateRange, isDateRange } from 'react-day-picker'
import { DatePickerValueType } from '../useDatePickerContext'

interface UseDateRangesProps<T extends DatePickerValueType> {
  dateValue?: T
  minDate?: Date
  maxDate?: Date
}

interface UseDateRangesOutput {
  prev: DatePickerValueType
  next: DatePickerValueType
  prevDescription: string | null
  nextDescription: string | null
}

export function useDateRanges<T extends DatePickerValueType>({
  dateValue,
  minDate,
  maxDate
}: UseDateRangesProps<T>): UseDateRangesOutput {
  const isRange = isDateRange(dateValue)

  /**
   * rangeLength: The length of the current selection, inclusive,
   * that we need to base the length of the prev/next range on.
   */
  const rangeLength: number = React.useMemo(() => {
    if (!isRange) return ONE_DAY
    const { from, to } = dateValue
    if (!from || !to) return ONE_DAY
    return differenceInCalendarDays(to, from) + ONE_DAY
  }, [dateValue, isRange])

  /**
   * Prev range based on currently selected range
   */
  const prevDateOrRange: DateRange | Date = React.useMemo(() => {
    if (!dateValue) return EMPTY_DATE_RANGE
    if (isRange) {
      const { from, to } = dateValue
      const toWithFromAsAFallback = to ?? from ?? undefined
      return {
        from: from ? subDays(from, rangeLength) : undefined,
        // We need to set "to" to match "from" if it's undefined here so we can make a range
        to: toWithFromAsAFallback
          ? subDays(toWithFromAsAFallback, rangeLength)
          : undefined
      }
    }
    // If it's not a range here it has to be a single Date, so go back one day
    return subDays(dateValue, ONE_DAY)
  }, [isRange, rangeLength, dateValue])

  /**
   * Next range based on currently selected range
   */
  const nextDateOrRange: DateRange | Date = React.useMemo(() => {
    if (!dateValue) return EMPTY_DATE_RANGE
    if (isRange) {
      const { from, to } = dateValue
      const toWithFromAsAFallback = to ?? from ?? undefined
      return {
        from: from ? addDays(from, rangeLength) : undefined,
        // We need to set "to" to match "from" if it's undefined here so we can make a range
        to: toWithFromAsAFallback
          ? addDays(toWithFromAsAFallback, rangeLength)
          : undefined
      }
    }
    // If it's not a range here it has to be a single Date, so go fwd one day
    return addDays(dateValue, ONE_DAY)
  }, [dateValue, isRange, rangeLength])

  /**
   * Does the range start on the first and end on the last day of any month(s)
   * (e.g.) should the previous and next ranges automatically snap to month(s)
   */
  const isRangeAlignedWithMonths: boolean = React.useMemo(() => {
    if (!isRange || rangeLength === 1) return false
    const { from, to } = dateValue
    if (!from || !to || !isDate(from) || !isDate(to)) return false
    return isFirstDayOfMonth(from) && isLastDayOfMonth(to) // Note: Can still be TRUE if from and to are in different months
  }, [isRange, rangeLength, dateValue])

  /**
   * If the selected range aligns with full months then
   * we return how many months are included.
   */
  const numberOfMonthsInMonthlyAlignedRange: number = React.useMemo(() => {
    if (!isRangeAlignedWithMonths) return 0
    const { from, to } = dateValue as DateRange
    return differenceInMonths(to!, from!) + 1
  }, [dateValue, isRangeAlignedWithMonths])

  /**
   * Range of prev full month
   */
  const prevMonthRange: DateRange = React.useMemo(() => {
    if (!isRangeAlignedWithMonths) return EMPTY_DATE_RANGE // Short circuit unless we're dealing with ranges
    const { from } = dateValue as DateRange
    return {
      from: startOfMonth(subMonths(from!, numberOfMonthsInMonthlyAlignedRange)),
      to: endOfMonth(subDays(from!, 1))
    }
  }, [dateValue, isRangeAlignedWithMonths, numberOfMonthsInMonthlyAlignedRange])

  /**
   * Range of next full month
   */
  const nextMonthRange: DateRange = React.useMemo(() => {
    if (!isRangeAlignedWithMonths) return EMPTY_DATE_RANGE // Short circuit unless we're dealing with ranges
    const { to } = dateValue as DateRange
    return {
      from: addDays(to!, 1),
      to: endOfMonth(addMonths(to!, numberOfMonthsInMonthlyAlignedRange))
    }
  }, [dateValue, isRangeAlignedWithMonths, numberOfMonthsInMonthlyAlignedRange])

  /**
   * validPrev
   * Final validation of date/range value against any constraints
   */
  const validPrev: DatePickerValueType = React.useMemo(() => {
    const dateOrRange = isRangeAlignedWithMonths
      ? prevMonthRange
      : prevDateOrRange
    if (!minDate) return dateOrRange
    if (
      isDate(dateOrRange) &&
      isAfter(dateOrRange as Date, subDays(minDate, ONE_DAY))
    ) {
      return dateOrRange
    }
    if (isRange) {
      const { from } = dateOrRange as DateRange
      // To will always be after from in our ranges, so no need to check it
      if (from && isAfter(from, subDays(minDate, ONE_DAY))) return dateOrRange
    }
    return null
  }, [
    isRange,
    isRangeAlignedWithMonths,
    minDate,
    prevDateOrRange,
    prevMonthRange
  ])

  /**
   * validNext
   * Final validation of date/range value against any constraints
   */
  const validNext: DatePickerValueType = React.useMemo(() => {
    const dateOrRange = isRangeAlignedWithMonths
      ? nextMonthRange
      : nextDateOrRange
    if (!maxDate) return dateOrRange
    if (isDate(dateOrRange) && isBefore(dateOrRange as Date, maxDate)) {
      return dateOrRange
    }
    if (isRange) {
      const { from, to } = dateOrRange as DateRange
      // It's possible to just have `from` here
      if (from && to && isBefore(from, maxDate) && isBefore(to, maxDate)) {
        return dateOrRange
      }
    }
    return null
  }, [
    isRange,
    isRangeAlignedWithMonths,
    maxDate,
    nextDateOrRange,
    nextMonthRange
  ])

  /**
   * rangeLabels for tooltips/buttons
   *
   * Note: the difference here with prevMonthRange vs prevDateOrRange,
   * and nextMonthRange vs nextDateOrRange. The month-specific ones will
   * be aligned to the start and end of a month, wheras the others will be
   * based on the length of days in the range and won't snap to a month.
   */
  const [prevDescription, nextDescription]: (string | null)[] =
    React.useMemo(() => {
      if (isRange) {
        if (isRangeAlignedWithMonths) {
          if (numberOfMonthsInMonthlyAlignedRange === 1) {
            // If it's a single month we'll use the month name and year as the label
            return [
              format(prevMonthRange.from!, Formats.date.long_without_days),
              format(nextMonthRange.from!, Formats.date.long_without_days)
            ]
          } else {
            // With multiple months we should show the date too to avoid confusion
            return [formatRange(prevMonthRange), formatRange(nextMonthRange)]
          }
        }
        return [
          formatRange(prevDateOrRange as DateRange),
          formatRange(nextDateOrRange as DateRange)
        ]
      }
      if (isDate(prevDateOrRange) && isDate(nextDateOrRange)) {
        return [
          format(prevDateOrRange as Date),
          format(nextDateOrRange as Date)
        ]
      }
      return ['Previous', 'Next']
    }, [
      isRange,
      isRangeAlignedWithMonths,
      nextDateOrRange,
      nextMonthRange,
      numberOfMonthsInMonthlyAlignedRange,
      prevDateOrRange,
      prevMonthRange
    ])

  return {
    prev: validPrev,
    prevDescription: validPrev ? prevDescription : 'Min date reached',
    next: validNext,
    nextDescription: validNext ? nextDescription : 'Max date reached'
  }
}

const ONE_DAY = 1
export const EMPTY_DATE_RANGE: DateRange = { from: undefined, to: undefined }
