type Args<T = any> = {
  url: string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  headers?: Record<string, string>
  body: string | T
}

declare function $(str: string): any

const getToken = () => {
  try {
    return $(
      '#__ECAjaxAntiForgeryForm input[name="__RequestVerificationToken"]'
    ).val()
  } catch {
    return ''
  }
}

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

const apiCall = async <T = any>(args: Args) => {
  const { url, method = 'GET', headers, body } = args

  const token = getToken()

  const parsedBody =
    typeof body === 'string' ? body : !body ? null : JSON.stringify(body)

  const options = {
    method,
    headers: { ...DEFAULT_HEADERS, ...headers },
    ...(token ? { __ECRequestVerificationToken: token } : undefined),
    ...(parsedBody !== null ? { body: parsedBody } : undefined)
  } as const

  const fetchResp = await fetch(url, options)

  if (fetchResp.ok) {
    const resp = (await fetchResp.json()) as T

    return resp
  }

  throw new Error(fetchResp.statusText || 'Unknown Error')
}

export { apiCall }
