import * as React from 'react'
import {
  useFloating,
  useClick,
  useDismiss,
  useInteractions,
  offset,
  size as sizeFloatingUI,
  useRole,
  shift,
  flip,
  limitShift,
  autoPlacement,
  autoUpdate,
  arrow
} from '@toasttab/buffet-pui-floating-ui-base'
import { useUniqueId } from '@toasttab/buffet-utils'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { DropdownCommonProps } from '../types'
import { focusOnFirstFocusableElement } from '../utils'

export type UseDropdownProps = Omit<DropdownCommonProps, 'renderToggle'> &
  TestIdentifiable

export const useDropdown = ({
  testId,
  id,
  placement,
  forceShow, // eslint-disable-line
  showArrow,
  matchWidth,
  role,
  onClose
}: UseDropdownProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const adjustedPlacement = placement === 'auto' ? undefined : placement

  // setting up unique ids for aria attribute matching
  const _testId = useUniqueId(testId, 'dropdown-')
  const _id = useUniqueId(id, 'dropdown-')
  const _contentsId = `${_id}-contents`

  // arrow dimensions
  const arrowRef = React.useRef(null)
  const arrowHeight = 16
  const arrowWidth = 24
  const arrowPadding = 12
  const arrowOffset = 2

  // floating-ui setup
  const {
    refs,
    floatingStyles,
    placement: floatingPlacement,
    context,
    elements,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} }
  } = useFloating({
    placement: adjustedPlacement,
    // (forceShow is deprecated)
    open: forceShow || isOpen, // eslint-disable-next-line
    onOpenChange: (isOpen) => {
      setIsOpen(isOpen)
      if (!isOpen) {
        // sets the focus back on the button when the dropdown closes
        // (the button is inside the ref)
        elements.reference &&
          focusOnFirstFocusableElement(elements.reference as HTMLElement)
        onClose?.()
      }
    },
    whileElementsMounted: autoUpdate, // ensures position updates with resize etc
    middleware: [
      offset(showArrow ? arrowHeight + arrowOffset : 4),
      !adjustedPlacement ? autoPlacement({ padding: 8 }) : flip({ padding: 8 }),
      shift({ padding: 8, limiter: limitShift(), crossAxis: true }),
      arrow({ element: arrowRef, padding: arrowPadding }),
      sizeFloatingUI({
        apply({ rects, elements }) {
          if (matchWidth) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`
            })
          }
        },
        padding: 10
      })
    ]
  })

  // floating-ui interactions (for the floating and ref props)
  const click = useClick(context)
  const dismiss = useDismiss(context, {
    outsidePress: (event) => {
      event.stopPropagation()
      event.preventDefault()
      return true
    },
    outsidePressEvent: 'mousedown'
  })
  const _role = useRole(context, { role })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    click,
    _role
  ])

  return {
    testId: _testId,
    id: _id,
    contentsId: _contentsId,
    refs,
    elements,
    context,
    getReferenceProps,
    getFloatingProps,
    floatingStyles,
    floatingPlacement,
    isOpen,
    setIsOpen,
    arrowRef,
    arrowParams: {
      x: arrowX,
      y: arrowY,
      width: arrowWidth,
      height: arrowHeight
    }
  }
}
