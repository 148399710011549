import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export interface BorderProps extends TestIdentifiable {
  invalid?: boolean
  disabled?: boolean
  className?: string
  isOpen?: boolean
  disableFocusShadow?: boolean
  borderRadiusClassName?: string
}

export const Border = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BorderProps>
>(
  (
    {
      children,
      invalid,
      disabled,
      className,
      isOpen,
      disableFocusShadow,
      testId,
      borderRadiusClassName
    },
    ref
  ) => {
    const borderRadius = cx('rounded-input', borderRadiusClassName)

    return (
      <div
        data-testid={`${testId}-border-outer`}
        className={cx(
          'p-0',
          borderRadius,
          {
            'shadow-focus': isOpen && !disableFocusShadow,
            'focus-within:shadow-focus': !disableFocusShadow
          },
          className
        )}
      >
        <div
          data-testid={`${testId}-border-inner`}
          ref={ref}
          className={cx(
            'p-0',
            'flex items-center border h-full',
            borderRadius,
            {
              'border-error': invalid && !isOpen,
              'hover:border-primary-75 hover:shadow-focus-within': !disabled,
              'focus-within:border-primary-75 focus-within:shadow-focus-within':
                !disabled && !disableFocusShadow,
              'border-primary-75 shadow-focus-within': isOpen,
              'border-disabled': disabled
            }
          )}
        >
          <div
            className='flex -m-px'
            style={{ width: 'calc(100% + 2px)', height: 'calc(100% + 2px)' }}
          >
            {children}
          </div>
        </div>
      </div>
    )
  }
)
