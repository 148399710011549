import cx from 'classnames'
import {
  Prefix,
  Suffix,
  SizeTypes,
  Variant
} from '@toasttab/buffet-pui-text-base'
import * as React from 'react'

export const getInputBorderClassName = ({
  size,
  readOnly,
  transparentBackground,
  changed = false
}: {
  size: SizeTypes
  readOnly?: boolean
  transparentBackground?: boolean
  changed?: boolean
}) => {
  return cx(
    'box-border',
    {
      'input-h-sm': size === 'sm',
      'input-h-lg': size === 'base' || size === 'lg',
      'input-h-lg md:input-h-sm': size === 'auto'
    },
    readOnly
      ? 'bg-gray-0'
      : changed
      ? 'bg-primary-0'
      : transparentBackground
      ? 'bg-transparent'
      : 'bg-white'
  )
}

export const getInputClassName = ({
  disabled,
  readOnly,
  changed = false,
  transparentBackground,
  prefix,
  prefixVariant,
  suffix,
  suffixVariant,
  className,
  roundedClassName = 'rounded-input'
}: {
  disabled?: boolean
  readOnly?: boolean
  changed?: boolean
  transparentBackground?: boolean
  prefix?: React.ReactNode
  prefixVariant?: Variant
  suffix?: React.ReactNode
  suffixVariant?: Variant
  className?: string
  roundedClassName?: string
}) => {
  return cx(
    'py-0 border-none hover:outline-none',
    'm-0 outline-none',
    'type-default',
    'min-w-0 h-full w-full flex-grow',
    disabled ? 'text-disabled' : readOnly ? 'text-secondary' : 'text-default',
    disabled ? 'placeholder-25' : 'placeholder-75',
    {
      'cursor-default': disabled,
      'rounded-r-none': suffix && suffixVariant === Suffix.Variant.bgGray,
      'rounded-l-none': prefix && prefixVariant === Prefix.Variant.bgGray
    },
    roundedClassName,
    readOnly && !disabled
      ? 'bg-gray-0'
      : changed
      ? 'bg-primary-0'
      : transparentBackground
      ? 'bg-transparent'
      : 'bg-white',
    className
  )
}
