import * as React from 'react'
import { DateRange, isSameDay } from '@toasttab/buffet-pui-date-utilities'
import cx from 'classnames'
import { ScreenSize } from '@toasttab/use-screen-size'
import { CustomRangeForm } from './CustomRangeForm'
import { defaultStaticRanges } from './presets'
import { useDatePickerContext } from '../useDatePickerContext'
import { DateFormatter } from './helpers'
import { t, loadStrings, DefaultStrings } from '../defaultStrings'

export interface DefaultStaticRange {
  label: string
  range: { from: Date; to: Date }
}

export interface DatePickerWithDefinedRangesProps {
  testId?: string | number
  definedRanges?: DefaultStaticRange[]
  onCustomDateChange?: (name: string, value: Date) => void
  closeMenu: () => void
}

const getActiveIndex = (
  definedRanges: DefaultStaticRange[],
  currentValue: DateRange | undefined,
  enableManualInput: boolean
) => {
  if (enableManualInput) {
    return definedRanges.length
  }
  const index = definedRanges.findIndex(
    (d) =>
      currentValue &&
      currentValue?.from &&
      isSameDay(d.range.from, currentValue.from) &&
      currentValue?.to &&
      isSameDay(d.range.to, currentValue?.to)
  )
  if (index !== -1) {
    return index
  }
  if (currentValue && currentValue.from && currentValue.to) {
    return definedRanges.length
  }
  return -1
}

export const DatePickerWithDefinedRanges: React.FC<
  React.PropsWithChildren<DatePickerWithDefinedRangesProps>
> = ({
  testId = `DatePickerDefinedRanges`,
  definedRanges = defaultStaticRanges,
  onCustomDateChange,
  closeMenu,
  children
}) => {
  const listRef = React.useRef<HTMLDivElement | null>(null)
  const {
    locale,
    onSelect,
    screenSize,
    datePickerState,
    setDatePickerState,
    currentValue
  } = useDatePickerContext<DateRange>()

  loadStrings()

  const isMediumScreen = React.useMemo(
    () => screenSize >= ScreenSize.SM,
    [screenSize]
  )

  const { enableManualInput, isMobileManualInput } = datePickerState

  const activeIndex = getActiveIndex(
    definedRanges,
    currentValue,
    enableManualInput
  )

  const [focusIndex, setFocusIndex] = React.useState<number>(
    isMediumScreen ? -1 : activeIndex !== -1 ? activeIndex : 0
  )

  // Only one of the ranges should be focusable
  const focusableIndex =
    focusIndex !== -1 ? focusIndex : activeIndex !== -1 ? activeIndex : 0

  const resolvedActiveIndex = focusIndex !== -1 ? focusIndex : activeIndex

  const clickHandler = React.useCallback(
    ({ range }: DefaultStaticRange) => {
      closeMenu()
      setDatePickerState((prev) => ({
        ...prev,
        enableManualInput: false,
        manualInputValue: range
      }))
      onSelect?.(range)
    },
    [closeMenu, setDatePickerState, onSelect]
  )

  const scrollHandler = React.useCallback((ref: HTMLDivElement) => {
    if (!ref) return
    listRef.current = ref
    requestAnimationFrame(() => {
      const dom = ref.getElementsByClassName(
        'active-range'
        // eslint-disable-next-line no-undef
      ) as HTMLCollectionOf<HTMLDivElement>
      if (dom.length) {
        ref.scrollTo({
          top: dom[0].offsetTop - 8
        })
      }
    })
  }, [])

  React.useEffect(() => {
    if (enableManualInput && listRef.current) {
      scrollHandler(listRef.current)
    }
  }, [enableManualInput, scrollHandler])

  const handleCustomDateClick = React.useCallback(() => {
    // Transition from a dropdown menu to a modal on small screens
    isMobileManualInput.current = !isMediumScreen
    if (isMobileManualInput.current) {
      closeMenu()
    }
    setDatePickerState((prev) => ({
      ...prev,
      enableManualInput: true
    }))
  }, [setDatePickerState, isMediumScreen, closeMenu, isMobileManualInput])

  const handleDefinedRangeKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowDown':
          if (focusIndex < definedRanges.length) {
            setFocusIndex(focusIndex + 1)
          }
          break
        case 'ArrowUp':
          if (focusIndex > 0) {
            setFocusIndex(focusIndex - 1)
          }
          break
        case 'Enter':
        case ' ':
          if (focusIndex < definedRanges.length) {
            clickHandler(definedRanges[focusIndex])
          } else {
            handleCustomDateClick()
          }
          event.preventDefault()
          event.stopPropagation()
          break
        default:
          break
      }
    },
    [clickHandler, definedRanges, focusIndex, handleCustomDateClick]
  )

  return (
    <div className='flex flex-col'>
      <div
        className={cx('relative flex h-full min-h-full overflow-x-auto', {
          'pl-40': isMediumScreen
        })}
      >
        <div
          className={cx('min-h-full border-r', {
            // Absolutely position so it is only as tall as the calendar container to its right
            'w-40 absolute h-full left-0 top-0': isMediumScreen,
            'min-w-full w-full': !isMediumScreen
          })}
          // Similar to the maxHeight: 256px that our Select component has
          style={{
            maxHeight: !isMediumScreen
              ? 'min(100vh - 16px, 20.5rem)'
              : undefined
          }}
        >
          <div
            className='w-full bg-full flex flex-col relative max-h-full'
            data-testid={testId}
          >
            <div
              className='overflow-y-auto py-2 select-none'
              ref={scrollHandler}
            >
              {/* m-0 is to harden this component against bootstrap global stylesheet */}
              <ul className='flex flex-col m-0'>
                {definedRanges.map((d, i) => {
                  const translatedLabel = t(d.label as keyof DefaultStrings)
                  return (
                    <DefinedRangeItem
                      onClick={() => clickHandler(d)}
                      key={d.label}
                      onFocus={() => setFocusIndex(i)}
                      onKeyDown={handleDefinedRangeKeyDown}
                      tabIndex={focusableIndex === i ? 0 : -1}
                      isFocused={focusIndex === i}
                      isActive={resolvedActiveIndex === i}
                      label={translatedLabel}
                      subLabel={DateFormatter({ ...d.range, locale })}
                    />
                  )
                })}
                <DefinedRangeItem
                  onClick={handleCustomDateClick}
                  onKeyDown={handleDefinedRangeKeyDown}
                  onFocus={() => setFocusIndex(definedRanges.length)}
                  tabIndex={focusableIndex === definedRanges.length ? 0 : -1}
                  isActive={resolvedActiveIndex === definedRanges.length}
                  isFocused={focusIndex === definedRanges.length}
                  label={t('custom-date')}
                />
              </ul>
            </div>
          </div>
        </div>
        {isMediumScreen && children}
      </div>
      {enableManualInput && (
        <CustomRangeForm onChange={onCustomDateChange} closeMenu={closeMenu}>
          {children}
        </CustomRangeForm>
      )}
    </div>
  )
}

const DefinedRangeItem = ({
  isActive,
  isFocused,
  label,
  subLabel,
  ...props
}: Omit<React.HTMLAttributes<HTMLButtonElement>, 'children' | 'className'> & {
  isActive?: boolean
  isFocused?: boolean
  label: React.ReactNode
  subLabel?: React.ReactNode
}) => {
  const ref = React.useRef<HTMLButtonElement>(null)

  React.useEffect(() => {
    if (ref.current && isFocused) {
      ref.current.focus()
    }
  }, [isFocused])

  return (
    <li>
      <button
        ref={ref}
        {...props}
        className={cx(
          'w-full',
          'flex flex-col cursor-pointer hover:bg-darken-4 p-3 transition-all ease-in',
          'outline-none',
          'text-left',
          {
            'bg-darken-4': isFocused || isActive,
            'active-range': isActive
          }
        )}
      >
        <span className='type-default sm:type-subhead'>{label}</span>
        {subLabel && (
          <span className='type-subhead sm:type-caption text-secondary'>
            {subLabel}
          </span>
        )}
      </button>
    </li>
  )
}
