import { useCallback } from 'react'
import type { Filters } from './employee-list-filters-types'

const KEY = 'employee-list-filters'

const MIN = 60 * 1000

type Payload = {
  filters: Filters
  storedAt: number
}

const getStored = (companyCode: string): Filters | undefined => {
  try {
    const str = sessionStorage.getItem(`${KEY}--${companyCode}`)
    if (str) {
      const payload = JSON.parse(str) as Payload
      // check if the data stored is fresher than 12 hours, otherwise ignore
      if (Date.now() - payload.storedAt < 5 * MIN) {
        return payload.filters
      }
    }
  } catch {
    return undefined
  }
}

const store = (companyCode: string, filters: Filters | null) => {
  try {
    if (filters) {
      const payload: Payload = {
        filters,
        storedAt: Date.now()
      }
      sessionStorage.setItem(`${KEY}--${companyCode}`, JSON.stringify(payload))
    } else {
      sessionStorage.removeItem(`${KEY}--${companyCode}`)
    }
  } finally {
  }
}

const useEmployeeListFilterStorage = (companyCode: string | undefined) => {
  const getStoredFilters = useCallback(() => {
    if (companyCode) {
      return getStored(companyCode)
    }
  }, [companyCode])

  const storeFilters = useCallback(
    (filters: Filters | null) => {
      if (companyCode) {
        return store(companyCode, filters)
      }
    },
    [companyCode]
  )

  return { getStoredFilters, storeFilters }
}

export default useEmployeeListFilterStorage
