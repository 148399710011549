import * as React from 'react'
import cx from 'classnames'
import {
  DayPicker,
  DayPickerProps,
  DayPickerSingleProps,
  DayPickerMultipleProps,
  DayPickerRangeProps,
  DayPickerBase,
  CustomComponents,
  Formatters,
  SelectRangeEventHandler,
  SelectMultipleEventHandler,
  SelectSingleEventHandler,
  DateRange
} from 'react-day-picker'
import { BuffetCaption } from './BuffetCaption'
import { BuffetRow } from './BuffetRow'
import {
  selectDateFnsLocale,
  dateFnsFormat as format
} from '@toasttab/buffet-pui-date-utilities'
import { Locale, getLocale } from '@toasttab/buffet-pui-locale-utilities'
import { useDeprecationWarning } from '@toasttab/buffet-utils'
import { RemoveFields } from '@toasttab/buffet-shared-types'
import { useCalendarNumberOfMonths } from './useCalendarNumberOfMonths'
import { useUniqueId } from '@toasttab/buffet-utils'
import { getDynamicRangeValue } from './utils'

export type CalendarProps =
  | SingleCalendarProps
  | MultipleCalendarProps
  | RangeCalendarProps

/**
 * The calendar has a few modes it can be used with, for selecting single dates,
 * multiple dates, or a date range. We don't know at compile time what the mode will
 * be, and each mode uses a different handler signature where the first argument can
 * be Date, Date[] or DateRange, depending on the mode, but the other remaining args
 * appear to remain conisitent across modes. The TypeScript Paramaters utility lets
 * us unionise them here so that we can type our onSelect handler.
 */
export type CalendarOnSelectHandler =
  | SelectSingleEventHandler
  | SelectMultipleEventHandler
  | SelectRangeEventHandler

export type CalendarOnSelectHandlerParameters =
  Parameters<CalendarOnSelectHandler>

export interface BuffetCustomComponents extends CustomComponents {
  Legend?: () => JSX.Element | null
}

export interface SingleCalendarProps
  extends Omit<BaseCalendarProps, 'selected'>,
    Omit<
      DayPickerSingleProps,
      'locale' | 'onSelect' | 'numberOfMonths' | 'components'
    > {
  mode: 'single'

  /** @deprecated OnSelect will be removed in the future, use onChange instead */
  onSelect?: DayPickerSingleProps['onSelect']
  onChange?: DayPickerSingleProps['onSelect']
}

export interface MultipleCalendarProps
  extends Omit<BaseCalendarProps, 'selected'>,
    Omit<
      DayPickerMultipleProps,
      'locale' | 'onSelect' | 'numberOfMonths' | 'components'
    > {
  mode: 'multiple'

  /** @deprecated OnSelect will be removed in the future, use onChange instead */
  onSelect?: DayPickerMultipleProps['onSelect']
  onChange?: DayPickerMultipleProps['onSelect']
}

export interface RangeCalendarProps
  extends Omit<BaseCalendarProps, 'selected'>,
    Omit<
      DayPickerRangeProps,
      'locale' | 'onSelect' | 'numberOfMonths' | 'components'
    > {
  mode: 'range'

  /** @deprecated OnSelect will be removed in the future, use onChange instead */
  onSelect?: DayPickerRangeProps['onSelect']
  onChange?: DayPickerRangeProps['onSelect']
}

export interface BaseCalendarProps
  extends RemoveFields<
    DayPickerBase,
    'disabled' | 'hidden' | 'locale' | 'numberOfMonths' | 'components'
  > {
  testId?: string | number
  containerClassName?: string
  components?: BuffetCustomComponents
  /**
   * Apply the `disabled` modifier to the matching days.
   */
  disabledDays?: DayPickerProps['disabled']
  /**
   * Apply the `hidden` modifier to the matching days. Will hide them from the
   * calendar.
   */
  hiddenDays?: DayPickerProps['hidden']
  /*
   * We have chosen to not show outside days by default,
   * but are supporting this flag for the alpha. Please let the buffet team
   * know if you have a use case for it in the  #ui-quick-questions slack channel.
   */
  showOutsideDays?: boolean
  /*
   * Together with showOutsideDays, this can be used to make
   * the calendar not change height when navigating between months.
   * This component will support this flag for the alpha.
   * Please discuss your use case in #ui-quick-questions slack
   * channel.
   */
  fixedWeeks?: boolean
  /*
   * The number of displayed months. Defaults to 1.
   */
  numberOfMonths?: 1 | 2
  locale?: Locale
  /*
   * On range selection mode this will prevent the range being deselected when either end
   * of the range is clicked. For other modes it doesn't seem to have much effect.
   * Defaults to false.
   */
  required?: boolean
}

// react-day-picker has made weekday names upper case by default: https://github.com/gpbl/react-day-picker/pull/1658#pullrequestreview-1305724440
// the custom formatter here is so that we don't use upper case weekday names
const dayPickerFormatters: Partial<Formatters> = {
  formatWeekdayName: (weekday, options) => format(weekday, 'cccccc', options)
}

export const Calendar = ({
  containerClassName,
  testId = `Calendar`,
  disabledDays,
  hiddenDays,
  locale: localeOverride,
  numberOfMonths,
  onChange,
  onSelect, // eslint-disable-line
  id,
  required,
  ...props
}: CalendarProps) => {
  useDeprecationWarning({ onChange, onSelect }, 'onSelect', 'onChange')

  const { selected, mode } = props
  const locale = localeOverride ?? getLocale()
  const calendarId = useUniqueId(id, 'calendar-')
  const numberOfMonthsShowing = useCalendarNumberOfMonths(numberOfMonths)
  const Legend = props.components?.Legend
  const actualOnSelect = (onChange ?? onSelect) as (
    ...args: CalendarOnSelectHandlerParameters
  ) => void

  const onSelectWrapper = React.useCallback(
    (...args: CalendarOnSelectHandlerParameters) => {
      let [value, selectedDay, activeModifiers, event] = args

      if (mode === 'range') {
        value = getDynamicRangeValue(
          selected as DateRange,
          value as DateRange,
          selectedDay,
          required
        )
        actualOnSelect(value, selectedDay, activeModifiers, event)
      } else {
        actualOnSelect(...args)
      }
    },
    [actualOnSelect, mode, required, selected]
  )

  return (
    <div
      data-testid={testId}
      className={cx('type-default', containerClassName)}
    >
      {Legend && <Legend />}
      <DayPicker
        {...props}
        numberOfMonths={numberOfMonthsShowing}
        onSelect={onSelectWrapper}
        locale={selectDateFnsLocale(locale)}
        disabled={disabledDays}
        hidden={hiddenDays}
        components={{
          Row: BuffetRow,
          Caption: BuffetCaption,
          ...props.components
        }}
        formatters={dayPickerFormatters}
        classNames={{
          month:
            'pt-2 pb-3.5 last:border-0 border-r border-default sm:px-2 mx-auto',
          table: numberOfMonthsShowing > 1 ? 'mx-6' : '',
          months: 'flex h-full',
          head_cell: cx(
            'text-secondary font-normal h-12 text-center align-center p-0',
            numberOfMonthsShowing === 1 &&
              (props.dir === 'rtl'
                ? 'xs:first:pr-6 xs:last:pl-6'
                : 'xs:first:pl-6 xs:last:pr-6')
          ),
          cell: 'p-0',
          vhidden: 'sr-only'
        }}
        id={calendarId} // The id is used by react-day-picker to assign `aria-labelledby` to the grid
      />
    </div>
  )
}
