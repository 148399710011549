import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const CheckboxUnselectedIcon = React.forwardRef(
  function CheckboxUnselectedIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'sm'].className
              )}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19 3c1 0 1.9.8 2 1.9V19c0 1-.8 1.9-1.9 2H5c-1 0-1.9-.8-2-1.9V5c0-1 .8-1.9 1.9-2H19zm0 1.5H5c-.2 0-.4.2-.5.4V19c0 .2.2.4.4.5H19c.2 0 .4-.2.5-.4V5c0-.2-.2-.4-.5-.5z'
                fill='currentColor'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
