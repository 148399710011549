import * as React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { TeamPagesNavBar } from '../../components'
import { useTranslation } from '../../hooks'
import { EcTitle } from '@toasttab/ec-title'
import { Portal } from '@toasttab/ec-layout'
import { useNavigationVersion } from '@toasttab/ec-features'

type Props = {
  subHeader?: React.ReactNode
  onTitleClick?: () => void
  children: React.ReactNode
}

const EmployeeListPageLayout = (props: Props) => {
  const { children } = props

  const { t } = useTranslation()

  const title = t('myEmployees')

  const { version } = useNavigationVersion()

  return (
    <>
      {version === 'v3' ? (
        <Portal id='page-header' component={<TeamPagesNavBar />} />
      ) : (
        <div className='pt-10 pb-6'>
          <div className='flex flex-row items-center justify-between'>
            <div className='pb-4 type-headline-2'>
              {title}
              <EcTitle title={title} />
            </div>
          </div>
        </div>
      )}

      <CardContainer>{children}</CardContainer>
    </>
  )
}

export default EmployeeListPageLayout
