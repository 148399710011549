import * as React from 'react'
import cx from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useMenuDropdownContext } from '../MenuDropdown/MenuDropdownContext'
import { useListItemOnKeyDown } from '../MenuDropdown/useListItemOnKeyDown'

const colorVariants = {
  white: {
    childItemClassName: 'hover:bg-darken-4 focus-visible:bg-darken-4',
    childIconClassName: '',
    childTextClassName: ''
  },
  gray: {
    childItemClassName: 'hover:bg-darken-4 focus-visible:bg-darken-4',
    childIconClassName: 'group-hover:text-link',
    childTextClassName: 'group-hover:text-link'
  }
}

export interface ListItemProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    TestIdentifiable {
  /** onClick > useful for analytics in this case */
  onClick?: () => void
  /** an icon name > buffet-pui-icon package  */
  icon?: React.ReactElement
  /** The item label */
  label?: string
  /** Additional text to provide context */
  subLabel?: string
  /** disable the list item; onClick will not be called */
  disabled?: boolean
  /**
   * class name for li
   * @deprecated list items no longer use an li as markup, please use `className` instead
   * */
  liClassName?: string
  /** class name for icon */
  iconClassName?: string
  /** class name for text label */
  textClassName?: string
}

export const ListItem = React.forwardRef<HTMLButtonElement, ListItemProps>(
  (
    {
      onClick,
      icon,
      label,
      subLabel,
      className,
      liClassName, // eslint-disable-line
      iconClassName,
      textClassName,
      testId,
      disabled,
      ...props
    }: ListItemProps,
    ref
  ) => {
    testId = useUniqueId(testId, 'menu-dropdown-item-')
    const { variant, preventCloseOnClick, closeDropdown } =
      useMenuDropdownContext()
    const variantProps = colorVariants[variant]

    const extendedOnClick = React.useCallback(() => {
      onClick && onClick()
      if (!preventCloseOnClick) {
        closeDropdown()
      }
    }, [closeDropdown, onClick, preventCloseOnClick])

    const onKeyDown = useListItemOnKeyDown<HTMLButtonElement>({
      onEnter: (event) => {
        event.preventDefault() // in case there's a form submit etc
        extendedOnClick()
      }
    })

    return (
      <button
        role='menuitem'
        ref={ref}
        data-testid={testId}
        className={cx(
          'whitespace-nowrap w-full text-left',
          'type-default font-normal',
          'px-3 py-3 md:py-2.5 m-0 flex items-center space-x-2',
          'outline-none',
          'group',
          className,
          {
            [variantProps.childItemClassName]: !disabled,
            [liClassName || '']: !disabled,
            'cursor-pointer text-default': !disabled,
            'text-disabled': disabled
          }
        )}
        onClick={() => {
          if (!disabled) {
            extendedOnClick()
          }
        }}
        data-disabled={disabled}
        onKeyDown={onKeyDown}
        {...props}
        tabIndex={disabled ? -1 : 0}
      >
        <div className='flex gap-2'>
          {icon &&
            React.cloneElement(icon, {
              ...icon.props,
              className: cx(disabled ? 'text-disabled' : 'text-secondary', {
                [variantProps.childIconClassName]: !disabled,
                [iconClassName || '']: !disabled
              })
            })}
          <div
            className={cx(
              {
                [variantProps.childTextClassName]: !disabled,
                [textClassName || '']: !disabled
              },
              'flex flex-col'
            )}
          >
            {label}
            {subLabel && (
              <div
                className={cx(
                  {
                    'text-secondary': !disabled,
                    'text-disabled': disabled
                  },
                  'type-subhead whitespace-normal'
                )}
              >
                {subLabel}
              </div>
            )}
          </div>
        </div>
      </button>
    )
  }
)
