import * as React from 'react'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { DateRange } from '@toasttab/buffet-pui-date-utilities'
import { Locale, getLocale } from '@toasttab/buffet-pui-locale-utilities'
import { ActiveModifiers } from 'react-day-picker'
import { DefaultStaticRange } from '../DatePickerWithDefinedRanges'
import { useRangeDescription } from './useRangeDescription'

export type DatePickerValueType = Date | DateRange | null

export type OnSelectEventType<TValue extends DatePickerValueType> = (
  value:
    | TValue
    | undefined /** The day that was selected (or clicked) triggering the event. */,
  selectedDay?: Date /** The modifiers of the selected day. */,
  activeModifiers?: ActiveModifiers,
  e?: React.MouseEvent
) => void

/**
 * DatePickerState refers to the values that are updated internally via the date picker components
 */
export type DatePickerState<TValue> = {
  // whether or not the custom range form is showing (also affects how we manage the values)
  enableManualInput: boolean
  // If we've transitioned to a mobile modal for picking manual input
  isMobileManualInput: React.MutableRefObject<boolean>
  // the values used for custom date entry (via the custom date range form)
  manualInputValue: TValue
  // We need to track which of the two date fields (for a range) is currently active
  // (so that we can update the correct input field when a calendar date is selected and vice versa)
  activeInput?: keyof DateRange
}

export interface DatePickerContextType<TValue extends DatePickerValueType> {
  // internal and changeable state (as outlined above)
  datePickerState: DatePickerState<TValue>
  // setter for our state
  setDatePickerState: React.Dispatch<
    React.SetStateAction<DatePickerState<TValue>>
  >
  // we store the `onSelect` in context for convenience (to avoid prop drilling)
  onSelect?: OnSelectEventType<TValue>
  // our UI changes based on screen size (e.g. modal for mobile)
  // having screen size in context means we only have to monitor it in one place
  screenSize: ScreenSize
  // locale used for formatting etc
  locale: Locale
  /**
   * allows customization of the portal in which the mobile modal is rendered
   * @deprecated Please use the default portal behavior to ensure the tooltip contents are correctly css scoped
   */
  modalParentSelector?: () => HTMLElement
  // avoids prop drilling for the set of defined ranges
  definedRanges?: DefaultStaticRange[]
  // avoids prop drilling for the show defined ranges flag
  showDefinedRanges?: boolean
  // if a date range matches one of the defined ranges, we set a label to show in the button
  selectedRangeLabel?: string
  // the current value is stored in context - used for managing the UI around ranges
  currentValue?: TValue

  // NOTE: currentValue and selectedRangeLabel are derived from the value (since date pickers
  // are controlled components). They are _not_ part of datePickerState because they are read-only.
}

export const defaultDatePickerState = {
  enableManualInput: false,
  isMobileManualInput: { current: false },
  manualInputValue: null,
  activeInput: undefined
}

export const defaultDatePickerContext = {
  datePickerState: defaultDatePickerState,
  setDatePickerState: () => null,
  onSelect: () => null,
  screenSize: ScreenSize.LG,
  locale: getLocale(),
  selectedRangeLabel: 'Custom',
  currentValue: null
}

const DatePickerContext = React.createContext<
  DatePickerContextType<DatePickerValueType>
>(defaultDatePickerContext)

export function useDatePickerContext<T extends DatePickerValueType>() {
  const context = React.useContext(
    DatePickerContext as unknown as React.Context<DatePickerContextType<T>>
  )
  if (context === undefined)
    throw new Error(
      'useDatePickerContext must be used inside a DatePickerContext component'
    )

  return context
}

export interface DatePickerContextProviderProps<
  TValue extends DatePickerValueType
> {
  locale?: Locale
  onSelect?: OnSelectEventType<TValue>
  modalParentSelector?: () => HTMLElement
  value?: TValue
  definedRanges?: DefaultStaticRange[]
  showDefinedRanges?: boolean
}

export const DatePickerContextProvider = <TValue extends DatePickerValueType>({
  locale: localeOverride,
  onSelect,
  modalParentSelector,
  value,
  definedRanges,
  showDefinedRanges,
  children
}: React.PropsWithChildren<DatePickerContextProviderProps<TValue>>) => {
  const screenSize = useScreenSize()
  const locale = localeOverride || getLocale()

  const isMobileManualInput = React.useRef(false)

  const [datePickerState, setDatePickerState] = React.useState<
    DatePickerState<TValue | null>
  >({
    ...defaultDatePickerState,
    isMobileManualInput
  })

  const selectedRangeLabel = useRangeDescription({
    value,
    definedRanges,
    showDefinedRanges
  })

  const datePickerContextValue = {
    datePickerState,
    // at compile time, we don't know whether the state will be for a date range or a date
    // so we're using looser types here for state and onSelect to avoid a typescript error
    // where we assign the value to our context provider
    setDatePickerState: setDatePickerState as (state: any) => void,
    onSelect: onSelect as (...args: any) => void,
    screenSize,
    locale,
    modalParentSelector,
    selectedRangeLabel,
    currentValue: value,
    definedRanges,
    showDefinedRanges
  }

  return (
    <DatePickerContext.Provider
      value={{
        ...datePickerContextValue
      }}
    >
      {children}
    </DatePickerContext.Provider>
  )
}
