import {
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameMonth
} from '@toasttab/buffet-pui-date-utilities'
import { isDateRange } from 'react-day-picker'
import { DatePickerValueType } from '../useDatePickerContext'

/**
 * Does the range start on the 1st and end on the last day of the same month?
 */
export const isFullSingleMonthDateRange = (
  datePickerValue?: DatePickerValueType
): boolean => {
  if (!isDateRange(datePickerValue)) return false
  const { from, to } = datePickerValue
  return (
    !!from &&
    !!to &&
    isFirstDayOfMonth(from) &&
    isLastDayOfMonth(to) &&
    isSameMonth(from, to)
  )
}
