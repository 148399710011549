//

const translations = {
  aboutThisEmployee: `About this employee`,
  acaPlan: `Affordable Care Act (ACA) plan`,
  actions: `Actions`,
  add: `Add`,
  addEmployee: `Add to team`,
  addEmployeeSuccess: `{{ name }} was successfully hired as a paid employee`,
  addEmail: `+ Add email`,
  addJob: `Add job`,
  addTeamMember: `Add employee`,
  apply: `Apply`,
  back: `Back`,
  basicInfo: `Basic information`,
  benefitPlan: `Benefit plan`,
  benefits: `Benefits`,
  bulkPayChange: `Bulk pay change`,
  business: `Business`,
  cancel: `Cancel`,
  cantReviewDocsNoEmailWarning: `{{ name }} won't be able review these documents because an email was not provided. Add an email later to ensure they can create their account and finish onboarding. You may also input their information yourself.`,
  cantReviewOnboardingNoEmailWarning: `{{ name }} won't be able complete these tasks because an email was not provided. Add an email later to ensure they can create their account and finish onboarding. You may also input their information yourself.`,
  createNewProfile: `Add employee to Payroll`,
  chosenName: `Chosen name`,
  clear: `Clear`,
  clearFilters: `Clear filters`,
  completePayrollAccount: `Complete payroll account`,
  completePayrollAccountSubtext: `Create payroll login`,
  completePersonalInformation: `Complete personal information`,
  completePersonalInformationSubtext: `Address, contact information, etc.`,
  continue: `Continue`,
  contractor1099: `Contractor (1099)`,
  contactAdmin: `Please contact admin`,
  deleteJob: `Remove job`,
  discardExit: `Discard & exit`,
  documents: `Documents`,
  eeoClassification: `EEO-1 Job Category`,
  eeoClassificationSubtext: `This can be updated in the employee profile`,
  emailAddress: `Email address`,
  employmentDetails: 'Employment details',
  taxDesignation: 'Tax designation',
  emailAddressInvalid: `Please provide a valid email address`,
  emailAddressRequired: `Email address is required`,
  emailAddressSubtext: `Don't have an email address?`,
  emailAddressErrorSubtext: `Please provide an email address`,
  emailAlreadyInUse: `This email address is already used by another employee`,
  employeeAddedEmailSubtext: `A welcome email was sent to {{ name }} with onboarding tasks to complete their profile.`,
  employeeAddedHeader: `{{ name }} was successfully added`,
  employeeAddedNoEmailSubtext: `Add an email later so {{ name }} can start their onboarding process.`,
  employeeDetails: `Employee details`,
  employeeIdAlreadyTaken: `The employee ID you entered was already in use! Generate an available ID above.`,
  employeeNumber: `Employee ID number`,
  employeeNumberAlreadyInUse: `This Employee ID number is already in use`,
  employeeNumberRegexFailed: `Employee Number must be 4-8 digits`,
  employeeNumberRequired: `Employee Number is required`,
  employeeNumberSubtext: `Autofilled with the next available Employee ID`,
  employeeOnboarding: `Employee onboarding`,
  employeeOnboardingSubtext: `The steps your employee will complete when they first log in to Toast Payroll`,
  employees: `Employees`,
  employeesPerPage: `Employees per page`,
  employeeW2: `Employee (W-2)`,
  errorTooltip: `Ensure your employee is correctly assigned or added as a Payroll employee`,
  exitingAddEmployeeBody: `Exiting now will stop your progress and it will not be saved.`,
  exitingAddEmployeeHeader: `Woah there, this employee has not been added yet`,
  fieldRequired: `This field is required`,
  fillOutI9: `Fill out federal I-9`,
  fillOutI9Subtext: `Required to begin working`,
  fillOutW4: `Fill out federal and state W-4`,
  fillOutW4Subtext: `Required tax documents`,
  filter: `Filter`,
  filters: `Filters`,
  firstName: `First Name`,
  firstNameRegexFailed: `Please enter a valid first name`,
  firstNameRequired: `First Name is required`,
  generalJobInfo: `General job information`,
  hideDocuments: `Hide documents`,
  hourAbbr: `hr`,
  hourly: `Hourly`,
  hourlyPay: `Hourly pay`,
  hourlyHelpText: 'Pay an hourly rate for all jobs',
  howEmployeeTaxed: `What type of team member are you adding?`,
  howEmployeePaid: `How will {{ name }} be paid?`,
  ignoreEmployee: `Ignore in Payroll`,
  ignoreEmployeeSuccess: `{{ name }} was successfully ignored to be be a paid employee`,
  ignoreEmployeeError: `Ignore unsuccessful.`,
  isOvertimeEligible: `Is {{ name }} eligible to earn overtime?`,
  itinNotAccepted: `Individual Taxpayer Identification Numbers are not accepted`,
  job: `Job`,
  jobsAndPay: `Jobs & pay`,
  lastName: `Last Name`,
  lastNameRegexFailed: `Please enter a valid last name`,
  lastNameRequired: `Last Name is required`,
  learnMore: `Learn more`,
  learnMoreDocuments: `Add a document`,
  learnMoreOnboarding: `Learn more about new hire onboarding`,
  lengthOfEmployment: `Length of employment`,
  location: `Location`,
  mergeEmployee: `Assign Payroll profile`,
  mobilePhoneNumber: `Mobile phone number`,
  myEmployees: `My Employees`,
  name: `Name`,
  newEmployee: `New Employee`,
  no: `No`,
  noEmployeeAddedToTeam: `You have no employees added to your team`,
  noEmployeeAddedToTeamDescription: `Add all employees you intend to pay at your restaurant business`,
  noDocuments: `There are no documents saved in Toast Payroll. Add documents to help your new team members onboard.`,
  onboarding: `Onboarding`,
  onboardingDocuments: `Onboarding documents`,
  onboardingDocumentsSubHeader: `{{ name }} will be assigned to review these documents during their onboarding. `,
  onboardingTasks: `Onboarding tasks`,
  onboardingTasksSubHeader: `These tasks are required for each employee to complete during onboarding in Toast Payroll.`,
  overtimeEligibleHelpText: `They'll be classified as a non-exempt employee`,
  overtimeIneligibleHelpText: `They'll be classified as an exempt employee`,
  payGroup: `Pay group`,
  payGroupLocked: `An employee can only be paid out of one pay group per location`,
  payType: `Pay type`,
  paymentMethod: `Payment method`,
  permissions: `Permissions`,
  personalInformation: `Personal information (address, contact information)`,
  phoneNumber: `Phone number`,
  phoneNumberInvalid: `Phone number is not valid`,
  posAccessCode: `POS access code`,
  posAccessCodeAlreadyInUse: `This POS access code is already in use`,
  posAccessCodeRegexFailed: `POS Access Code must be 4-8 digits`,
  posAccessCodeRequired: `POS Access Code is required`,
  posAccessCodeSubtext: `This is defaulted to the employee ID but you can select a new 4-8 digit passcode`,
  position: `Position`,
  positions: `Positions`,
  reportEmployeeIncident: `Report employee incident`,
  returnToTeamPage: `Return to Team page`,
  review: `Review`,
  reviewAndSignDocuments: `Review and sign documents`,
  salary: `Salary`,
  salaryHelpText: 'Pay an annual salary for all jobs',
  salaryPerPay: 'Salary per pay period',
  securityHelperText: `This grants permission for Payroll. POS permissions will be automatically assigned based on the employee’s jobs`,
  securityRole: `Payroll security role`,
  selectACA: `Select an Affordable Care Act plan`,
  selectAll: `Select all`,
  selectBenefitPlan: `Select a benefit plan`,
  selectLocations: `Select Locations`,
  selectPaymentMethod: `Select payment method`,
  selectPaymentMethodSubtext: `Choose how to receive payment`,
  selectPositions: `Select Positions`,
  selectStatuses: `Select statuses`,
  selectTAFW: `Select a time away from work policy`,
  selectWLI: `Select a Washington Labor & Industries option`,
  selectWorkersComp: `Select a workers compensation option`,
  socialSecurityNumber: `Social security number`,
  socialSecurityNumberAlreadyInUse: `This SSN is already in use by another employee`,
  socialSecurityNumberNotProvided: `Add a social security number to avoid fees when filing taxes`,
  socialSecurityNumberRegexFailed: `Please enter a valid SSN. SSN's cannot start with 666, 000, or 9, cannot have -00- as the middle number, and must be in the format 3-2-4`,
  socialSecurityNumberRequired: `Social Security Number is required`,
  socialSecurityNumberSection: `Will you provide the social security number?`,
  socialSecurityNumberSubtext: `SSN's are used for tax filing, and must be accurate. This can be completed later by you or the employee.`,
  somethingIsWrong: `Something is wrong`,
  somethingIsWrongSubtext: `We were unable to save this employee. Please try again`,
  startDate: `Start date`,
  startDateSixMonths: `Please choose a date within the next 6 months`,
  startDateSubtext: `This is the first date your employee will be eligible for pay and/or, if applicable, benefits.`,
  status: `Status`,
  submit: `Submit`,
  successfullyCopied: `Successfully copied to clipboard`,
  taxationTypeRequired: `This field is required`,
  team: `Team`,
  teamActions: `Team Actions`,
  tellUsAboutThisTeamMember: `Tell us about this team member`,
  tafwPolicy: `Time away from work policy`,
  training: `Training`,
  tryAgain: `Try again`,
  unpaidEmployee: `Unpaid Employee`,
  unmappedEmployeeError: `{{ docCount }} employee is not set up to be paid through Payroll and will not appear in searches or reporting.`,
  unmappedEmployeesError: `{{ docCount }} employees are not set up to be paid through Payroll and will not appear in searches or reporting.`,
  viewDocuments: `View documents`,
  viewEmployeeProfile: `View {{ name }}'s employee profile`,
  w4TaxDocument: `W-4 tax document`,
  willYouProvideEmail: `Will you provide an email address?`,
  willYouProvideEmailSubtext: `Add your employee's email address so they can log in, self-onboard, and opt to receive electronic tax forms`,
  willYouProvideSsn: `Will you enter a social security number now?`,
  willYouProvideSsnSubtext: `Accurate SSNs are essential for tax filing`,
  willProvideYesSsnToggle: `Yes, I have it`,
  willProvideNoSsnToggle: `No, it will be entered later`,
  wli: `Washington Labor & Industries`,
  workersComp: `Workers compensation`,
  workTaxLocation: `Work tax location`,
  workTaxLocationLocked: `An employee can only have one work tax location per pay group`,
  yearAbbr: `yr`,
  yes: `Yes`,
  pleaseRetry: 'Please reload the page and try again.',
  hmmSomethingWentWrong: `Hmm...We couldn't load this page.`
}

type Translations = typeof translations

export type { Translations }

export { translations }
