export type Sibling = HTMLElement | null

export const nextEnabledSibling = (sibling: Sibling): Sibling => {
  const element = sibling
  if (element) {
    if (element.dataset.disabled) {
      return nextEnabledSibling(element.nextSibling as Sibling)
    } else {
      return element
    }
  }
  return null
}
export const previousEnabledSibling = (sibling: Sibling): Sibling => {
  const element = sibling
  if (element) {
    if (element.dataset.disabled) {
      return previousEnabledSibling(element.previousSibling as Sibling)
    } else {
      return element
    }
  }
  return null
}
