import * as React from 'react'
import { useTeamPageItems } from '../hooks'
import { TeamPageHeader } from '../components'
import { withNavV3Page } from './withNavV3Page'
import { useHideEsxElement } from '@toasttab/ec-legacy-esx-hooks'
const TeamAddIncidentNavigation = () => {
  const { teamPagesByName } = useTeamPageItems()

  const backHref = teamPagesByName['incident']?.url

  useHideEsxElement({ selector: 'h2' })
  return <TeamPageHeader backHref={backHref} title={'Add incident'} />
}

export default withNavV3Page(TeamAddIncidentNavigation)
