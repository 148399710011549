import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { SizeTypes as Size } from '@toasttab/buffet-pui-text-base'

export type SelectItemContainerProps = TestIdentifiable &
  React.LiHTMLAttributes<HTMLLIElement> & {
    index: number
    highlightedIndex: number | null
    selectedIndex?: number | null
    itemProps: any
    itemIconLeft?: React.ReactNode
    size?: Size
    children: React.ReactNode
    listRef: React.MutableRefObject<(HTMLElement | null)[]>
    listContentRef: React.MutableRefObject<(string | null)[]>
  }

const getLiSizeClass = (size: Size) => {
  switch (size) {
    case 'sm':
      return 'py-2.5'
    case 'lg':
    case 'auto':
    default:
      return 'py-3 md:py-2.5'
  }
}

export const SelectItemContainer: React.FC<
  React.PropsWithChildren<SelectItemContainerProps>
> = ({
  testId = 'SelectItemContainer',
  index,
  highlightedIndex,
  selectedIndex,
  itemProps,
  itemIconLeft,
  size = 'auto',
  className,
  children,
  listRef,
  listContentRef,
  ...props
}) => {
  const { disabled: isDisabled } = itemProps

  return (
    <li
      data-testid={testId}
      className={cx(
        'px-3 type-default font-normal',
        'flex flex-row items-top',
        'focus:outline-none',
        getLiSizeClass(size),
        {
          'bg-darken-4': highlightedIndex === index && !isDisabled,
          'bg-info-0': selectedIndex === index,
          'text-disabled': isDisabled,
          'cursor-pointer text-default': !isDisabled
        },
        className
      )}
      {...itemProps}
      {...props}
      ref={(node: HTMLElement) => {
        listRef.current[index] = node
        listContentRef.current[index] = node?.textContent ?? null
      }}
    >
      {itemIconLeft && (
        <div className='mr-2 leading-none text-secondary'>{itemIconLeft}</div>
      )}
      {children}
    </li>
  )
}
