import { useState } from 'react'
import { useEcProps } from '@toasttab/ec-banquet-props'
import {
  getRedirectUrl,
  Url,
  getEmployeeTabUrl
} from '../redirect-links/helpers'
import { PrefetchLink } from '../redirect-links/PrefetchLink'
import { useTranslation } from '../hooks'

type Props = {
  recordLink: Url
  email: String
  mappingStatusFeature?: Boolean
}

// Add invalid patterns for emails to the array
const INVALID_PATTERNS = ['@example.com']

const EmployeeEmail = ({ recordLink, email, mappingStatusFeature }: Props) => {
  const [hasBeenHovered, setHasBeenHovered] = useState<boolean>(false)
  const { ecNavigate } = useEcProps()

  const { t } = useTranslation()

  const onMouseEnter = () => {
    if (!hasBeenHovered) setHasBeenHovered(true)
  }

  const foundInvalidPattern = INVALID_PATTERNS.some((INVALID_PATTERNS) =>
    email.includes(INVALID_PATTERNS)
  )

  if (!foundInvalidPattern) {
    return <>{email}</>
  }

  const handleClick = async (url: string) => {
    if (!url) return
    const redirectUrl = await getRedirectUrl(url)
    const tabRedirectUrl = getEmployeeTabUrl(redirectUrl, '/account')

    if (tabRedirectUrl) {
      ecNavigate(tabRedirectUrl)
    } else {
      window.location.href = url
    }
  }

  const fallback = (
    <a
      onMouseEnter={onMouseEnter}
      href={recordLink}
      onClick={async (evt) => {
        if (recordLink) {
          evt.preventDefault()
          handleClick(recordLink)
        }
      }}
      className={mappingStatusFeature && 'text-link'}
    >
      {t('addEmail')}
    </a>
  )

  if (hasBeenHovered) {
    return (
      <PrefetchLink url={recordLink} fallback={fallback} employeeTab='/account'>
        {t('addEmail')}
      </PrefetchLink>
    )
  }

  return fallback
}

export default EmployeeEmail
