import * as React from 'react'
import { Portal } from '@toasttab/ec-layout'
import { TeamPagesNavBar } from '../components'
import { withNavV3Page } from './withNavV3Page'
import { useHideEsxElement } from '@toasttab/ec-legacy-esx-hooks'

const TeamTrainingNavigation = () => {
  useHideEsxElement({ selector: '.col-sm-3' })
  useHideEsxElement({ selector: 'h2' })
  return <Portal id='page-header' component={<TeamPagesNavBar />} />
}

export default withNavV3Page(TeamTrainingNavigation)
