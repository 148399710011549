import * as React from 'react'
import cx from 'classnames'
import { TruncateOption } from '../types'
import { pickTruncateOption } from '../utils'

export type SelectItemDefaultContentProps = {
  label: string
  subLabel?: string | null
  className?: string
  isSelectButton?: boolean
  showSubLabel?: boolean
  disabled?: boolean
  truncateOption?: TruncateOption
}

export const SelectItemDefaultContent = ({
  label,
  subLabel,
  className,
  isSelectButton,
  showSubLabel,
  disabled,
  truncateOption
}: SelectItemDefaultContentProps) => {
  if (!subLabel || !showSubLabel) {
    return <>{label || <div>&nbsp;</div>}</>
  }
  const {
    labelClassName,
    subLabelClassName,
    labelTitle,
    subLabelTitle,
    contentHeader
  } = pickTruncateOption(
    truncateOption || 'none',
    label,
    subLabel,
    isSelectButton || false
  )
  return (
    <div className={cx('flex-row', contentHeader, className)}>
      <div
        className={cx(
          isSelectButton ? 'type-subhead truncate' : 'type-default',
          labelClassName
        )}
        title={labelTitle}
      >
        {label}
      </div>
      <div
        className={cx(
          disabled ? 'text-disabled' : 'text-secondary',
          isSelectButton ? 'type-caption truncate' : 'type-subhead',
          subLabelClassName
        )}
        title={subLabelTitle}
      >
        {subLabel}
      </div>
    </div>
  )
}
