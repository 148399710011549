import * as React from 'react'
import EmployeeListProviders from './EmployeeListProviders'
import EmployeeListApp from './EmployeeListApp'
import { useCompanyCode } from '@toasttab/ec-session'
import { useRedirect } from '../../hooks'
import { useNavigationVersion } from '@toasttab/ec-features'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'

const EmployeeListPage = () => {
  const companyCode = useCompanyCode()

  const { version } = useNavigationVersion()

  const hasNavV3 = version === 'v3'

  useRedirect({
    href: `/mvc/${companyCode}/Team/Dashboard/Index`,
    enabled: !hasNavV3
  })

  if (hasNavV3) {
    return (
      <EmployeeListProviders>
        <SnackBarProvider>
          <EmployeeListApp companyCode={companyCode} />
        </SnackBarProvider>
      </EmployeeListProviders>
    )
  }

  return null
}

export default EmployeeListPage
