import { makeEcSpaCompanyLocalStorageState } from '@toasttab/ec-storage'
import type { Configuration } from '@toasttab/ec-storage'
import isString from 'lodash/isString'
import { NavItem } from './types'

const isNavItem = (item: any): item is NavItem => {
  return isString(item.path) && isString(item.url)
}

/*
NOTE: Never _change_ the type of a LS value once it's been released to the wild
instead, create a new key with your new type
*/

type LocalStorageTypes = {
  'last-team-navigation-item': NavItem | null
}

const CONFIGURATION: Configuration<LocalStorageTypes> = {
  'last-team-navigation-item': {
    default: null,
    isValid: (value: any) => isNavItem(value)
  }
}

const ecTeamSpaCompanyLocalStorage =
  makeEcSpaCompanyLocalStorageState<LocalStorageTypes>({
    spaName: 'ec-team-spa',
    configuration: CONFIGURATION
  })

const useEcTeamSpaCompanyLocalStorageState =
  ecTeamSpaCompanyLocalStorage.useEcSpaCompanyLocalStorageState

export { useEcTeamSpaCompanyLocalStorageState }
