import { useCallback, useState } from 'react'
import { useEmployeesListInitializeQuery } from '../../data'
import EmployeeListPageLayout from './EmployeeListPageLayout'
import { EmployeeListPagination } from '../../components'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { FilterIcon, PayrollEmployeeIcon } from '@toasttab/buffet-pui-icons'
import EmployeeListFiltersModal from './EmployeeListFiltersModal'
import useEmployeeListFiltersState from './useEmployeeListFiltersState/useEmployeeListFiltersState'
import { useRedirect, useTranslation } from '../../hooks'
import ResponsiveEmployeeTable from './ResponsiveEmployeeTable'
import TeamActions from '../../components/TeamPagesNavBar/TeamActions'
import { Alert } from '@toasttab/buffet-pui-alerts'
import AddEmployeeModalWrapper from '../../AddEmployeeModal/AddEmployeeModalWrapper'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useFeature } from '@toasttab/ec-features'
import { useUser } from '@toasttab/ec-session'
import { PosEmployeeModal } from '@toasttab/ec-employee-management'
import {
  useInvalidateEmployeeList,
  useCombinedEmployeeListQuery
} from '../../data/useCombinedEmployeeListQuery'
import { useSearchParams } from 'react-router-dom'
import { useGeneratePayrollLink } from '../../hooks/useGeneratePayrollLink'
import { useIgnorePosUserInPayrollMutation } from '@local/api'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { v4 as uuidv4 } from 'uuid'

type Props = {
  companyCode: string
}

const EmployeeListApp = ({ companyCode }: Props) => {
  const { t } = useTranslation()
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const mappingStatusFeature = useFeature('ec-elm-teams-list-mapping-status')
  const ignoreMappingFeature = useFeature('ec-elm-ignore-in-payroll')

  const [ignorePosUser] = useIgnorePosUserInPayrollMutation()

  const [searchParams] = useSearchParams()
  const redirectLink = useGeneratePayrollLink(
    searchParams.get('toastIdentityGuid'),
    searchParams.get('toastRestaurantGuid')
  )

  useRedirect({
    href: redirectLink || '/',
    enabled: redirectLink !== null
  })

  const { invalidateEmployeeList } = useInvalidateEmployeeList()
  const { showSuccessSnackBar, showErrorSnackBar, closeSnackBar } =
    useSnackBar()

  const initializeQuery = useEmployeesListInitializeQuery({ companyCode })

  const initialFilters = initializeQuery.data?.obj?.filters
  const isInitalizing = initializeQuery.isLoading

  const {
    filters,
    setFilters,
    clearFilters,
    defaultFilters,
    numActiveFilters,
    canClearFilters
  } = useEmployeeListFiltersState({ companyCode, initialFilters })

  const positions = initializeQuery.data?.obj.datasources?.positions
  const locations = initializeQuery.data?.obj.datasources?.locations
  const statuses = initializeQuery.data?.obj.datasources?.status

  const listQuery = useCombinedEmployeeListQuery({
    companyCode,
    pageIndex: filters.page,
    locationIds: filters.locationIds,
    positionIds: filters.positionIds,
    statusIds: filters.statusIds,
    pageSize: filters.pageSize,
    from: filters.from,
    until: filters.until,
    enabled: !!initialFilters,
    mappingStatusFeature
  })

  const records = listQuery.data?.obj?.records

  const posEmployeesLength = listQuery.data?.posLength

  const totalRecordsCount = listQuery.data?.obj?.recordsCount

  const pageCount = listQuery.data?.obj?.pageCount

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isPosModalOpen, setIsPosModalOpen] = useState(false)
  const [locationGuid, setLocationGuid] = useState('')
  const [userGuid, setUserGuid] = useState('')
  const [displayName, setDisplayName] = useState('')

  const DisplayErrorSnackbar = (
    callback: (userGuid: string, displayName: string) => void
  ) => {
    const snackBarId = uuidv4()
    showErrorSnackBar(t('ignoreEmployeeError'), {
      key: snackBarId,
      isDismissable: true,
      action: (
        <button
          onClick={() => {
            closeSnackBar(snackBarId)
            callback(userGuid, displayName)
          }}
        >
          {t('tryAgain')}
        </button>
      )
    })
  }

  const onOpen = useCallback(() => setIsOpen(true), [])

  const onClose = useCallback(() => setIsOpen(false), [])

  const onPosOpen = useCallback(() => setIsPosModalOpen(true), [])
  const onPosClosed = useCallback(() => setIsPosModalOpen(false), [])

  const openPosModal = (
    locationGuid: string,
    userGuid: string,
    name: string
  ) => {
    setLocationGuid(locationGuid)
    setUserGuid(userGuid)
    setDisplayName(name)
    onPosOpen()
  }

  const loading = isInitalizing || listQuery.isLoading

  const user = useUser()
  const hrAndAboveUser = user.isPureUserHRPlusOrAbove

  const filterButton = (
    <ButtonGroup>
      <Button
        iconLeft={<FilterIcon accessibility='decorative' />}
        variant='link'
        onClick={() => setShowFilters((val) => !val)}
      >
        {t('filters')}
        {!isInitalizing && numActiveFilters ? ` (${numActiveFilters})` : ''}
      </Button>
      {false && (
        <Button
          as='a'
          href={`/mvc/${companyCode}/Management/NewHire/Hire`}
          variant='primary'
        >
          {t('newEmployee')}
        </Button>
      )}
    </ButtonGroup>
  )

  const displayFilters = showFilters && (
    <EmployeeListFiltersModal
      filters={filters}
      defaultFilters={defaultFilters}
      onCancel={() => setShowFilters(false)}
      onApply={(filters) => {
        setFilters({ ...filters, page: 1 })
        setShowFilters(false)
      }}
      positions={positions}
      locations={locations}
      statuses={statuses}
    />
  )
  const pagination = (
    <EmployeeListPagination
      page={filters.page}
      pageCount={pageCount}
      onPageChange={(page) => setFilters({ page })}
      totalRecordsCount={totalRecordsCount}
      loading={loading}
    />
  )

  const ignorePosEmployeeInPayroll = async (userGuid: string, name: string) => {
    try {
      setDisplayName(name)
      const { data: ignoreMutationData } = await ignorePosUser({
        variables: { userGuid }
      })
      const ignored = ignoreMutationData?.ignorePosUserInPayroll
      if (ignored) {
        invalidateEmployeeList()
        showSuccessSnackBar(
          t('ignoreEmployeeSuccess', { name: name.split(', ')[1] })
        )
      }
    } catch (e) {
      DisplayErrorSnackbar(ignorePosEmployeeInPayroll)
    }
  }

  return (
    <EmployeeListPageLayout
      onTitleClick={canClearFilters ? clearFilters : undefined}
    >
      {loading ? (
        <div className='flex items-center justify-center'>
          <MerryGoRound />
        </div>
      ) : records?.length ? (
        <>
          <div className='mb-4'>
            {mappingStatusFeature && posEmployeesLength > 0 && (
              <Alert variant='error' testId={'unmapped-pos-alert'}>
                {t(
                  posEmployeesLength === 1
                    ? 'unmappedEmployeeError'
                    : 'unmappedEmployeesError',
                  {
                    docCount: posEmployeesLength
                  }
                )}
                {hrAndAboveUser && (
                  <a
                    href={
                      'https://central.toasttab.com/s/article/Toast-Payroll-How-to-Use-the-Employee-Mapping-Tool#how_to_map_employees'
                    }
                    target='_blank'
                    rel='noreferrer'
                    className='text-default'
                  >
                    <button className='inline-link-inherit'>
                      {t('learnMore')}
                    </button>
                  </a>
                )}
              </Alert>
            )}
            <div className='float-right'>
              {filterButton}
              {displayFilters}
            </div>
          </div>
          <PosEmployeeModal
            isOpen={isPosModalOpen}
            onCancel={onPosClosed}
            onSuccess={() => {
              invalidateEmployeeList()
              showSuccessSnackBar(
                t('addEmployeeSuccess', { name: displayName.split(', ')[1] })
              )
            }}
            locationGuid={locationGuid}
            toastUserGuid={userGuid}
          />
          <ResponsiveEmployeeTable
            employees={records}
            companyCode={companyCode}
            isLoading={loading}
            mappingStatusFeature={mappingStatusFeature}
            hrAndAboveUser={hrAndAboveUser}
            ignoreMappingFeature={ignoreMappingFeature}
            hirePosEmployee={(locationGuid, userGuid, name) => {
              openPosModal(locationGuid, userGuid, name)
            }}
            ignorePosEmployee={(guid, name) =>
              ignorePosEmployeeInPayroll(guid, name)
            }
          />
          {pagination}
        </>
      ) : (
        <>
          <div className='flex flex-row justify-end'>{filterButton}</div>
          {displayFilters}
          <PayrollEmployeeIcon
            accessibility='decorative'
            className='flex items-center justify-center p-4 type-default'
            size='md'
          />
          <div className='flex items-center justify-center pb-2 font-medium type-default'>
            {t('noEmployeeAddedToTeam')}
          </div>
          <div className='flex items-center justify-center pb-6 type-default'>
            {t('noEmployeeAddedToTeamDescription')}
          </div>
          <div className='flex items-center justify-center pb-4'>
            <TeamActions
              showTeamActionDropdown={false}
              onOpenAddEmployeeModal={onOpen}
              isButtonVariantSecondary={true}
            />
            <AddEmployeeModalWrapper isOpen={isOpen} onClose={onClose} />
          </div>
        </>
      )}
    </EmployeeListPageLayout>
  )
}

export default EmployeeListApp
