import * as React from 'react'
import { format, Formats, Masks } from '@toasttab/buffet-pui-date-utilities'
import { Locale, getLocale } from '@toasttab/buffet-pui-locale-utilities'
import { Calendar, SingleCalendarProps } from '@toasttab/buffet-pui-calendar'
import {
  DatePickerContainer,
  DatePickerContainerProps,
  DayPickerContainerBaseProps
} from '../DatePickerContainer'
import {
  DatePickerContextProvider,
  OnSelectEventType
} from '../useDatePickerContext'
import { useDeprecationWarning, useUniqueId } from '@toasttab/buffet-utils'
import { useCalendarConstraints } from '../useCalendarConstraints/useCalendarConstraints'
import { t, loadStrings } from '../defaultStrings'

export interface DatePickerProps
  extends Omit<DayPickerContainerBaseProps<Date>, 'formatValue'>,
    Omit<SingleCalendarProps, 'disabled' | 'mode' | 'onChange' | 'onSelect'> {
  value?: Date
  required?: boolean
  formatValue?: string | ((value?: Date) => string | undefined)
  disabled?: boolean
  showDateStepperButtons?: boolean
  mask?: string
  // We redefine our own type for onChange and onSelect so that we can pass `undefined` as a value for formik
  // (see `onValueChangeHandler` in DatePickerContainer)
  onChange?: OnSelectEventType<Date>
  /** @deprecated OnSelect will be removed in the future, use onChange instead */
  onSelect?: OnSelectEventType<Date>
}

const createFormatValueFunction = (
  formatValue: string | ((value?: Date) => string | undefined),
  locale: Locale
) => {
  return typeof formatValue === 'string' // eslint-disable-next-line @toasttab/buffet/date-formats
    ? (date?: Date) => (date && format(date, formatValue, locale)) || ''
    : formatValue
}

export const DatePicker = ({
  testId = `DatePicker`,
  containerClassName,
  disabled,
  invalid,
  required,
  errorText,
  helperText,
  helperIconButton,
  name,
  value,
  label,
  'aria-label': ariaLabel,
  preserveHelpSpace,
  placeholder,
  size = 'auto',
  showDateStepperButtons = false,
  placement = 'bottom-start',
  inlineBlock,
  formatValue = Formats.date.long, // 'MMMM d, yyyy'
  locale: localeOverride,
  mask = Masks.short,
  id,
  onOpenChange,
  ...calendarProps
}: DatePickerProps) => {
  const uniqueId = useUniqueId(id, 'date-picker-')
  const locale = localeOverride || getLocale()

  loadStrings()
  const translatedPlaceholder = placeholder ?? t('select-dates')

  // eslint-disable-next-line
  const actualOnChange = calendarProps.onChange || calendarProps.onSelect
  const { minDate, maxDate } = useCalendarConstraints(calendarProps)

  const containerProps: Partial<DatePickerContainerProps<Date>> = {
    testId,
    containerClassName,
    placement,
    disabled,
    invalid,
    required,
    inlineBlock,
    placeholder: translatedPlaceholder,
    size,
    name: name,
    id: uniqueId,
    value,
    label,
    errorText,
    helperText,
    helperIconButton,
    preserveHelpSpace,
    ariaLabel,
    showDateStepperButtons,
    minDate,
    maxDate,
    onOpenChange
  }

  useDeprecationWarning(calendarProps, 'onSelect', 'onChange')

  return (
    <DatePickerContextProvider<Date>
      locale={locale}
      onSelect={actualOnChange}
      value={value}
    >
      <DatePickerContainer<Date>
        mask={mask}
        formatValue={createFormatValueFunction(formatValue, locale)}
        {...containerProps}
        renderCalendar={(value, closeDatePicker, { onSelect }) => (
          <Calendar
            {...calendarProps}
            initialFocus
            mode='single'
            selected={value}
            defaultMonth={value || calendarProps.defaultMonth}
            locale={locale}
            required={required}
            onChange={(day, selectedDay, modifiers, e) => {
              if (day) {
                closeDatePicker()
              }
              onSelect(day, selectedDay, modifiers, e)
            }}
            id={id}
          />
        )}
      />
    </DatePickerContextProvider>
  )
}
