import * as React from 'react'
import { NumberInput, NumberInputProps } from '../NumberInput'
import { CalendarTodayIcon } from '@toasttab/buffet-pui-icons'
import { selectMask, Mask, Masks } from '@toasttab/buffet-pui-date-utilities'
import { Locale, getLocale } from '@toasttab/buffet-pui-locale-utilities'
import { useUniqueId } from '@toasttab/buffet-utils'
export interface DateInputProps extends NumberInputProps {
  /** Assigns the prefix to the default calendar prefix, overrides prefix prop */
  disablePrefix?: boolean
  /** represents the strings which identify the geo location, ex: en-US, en-GB... */
  locale?: Locale
  mask?: string
}

export const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  (
    {
      autoComplete = 'false',
      prefix,
      disablePrefix = false,
      locale: localeOverride,
      mask = Masks.short,
      required,
      name,
      id,
      ...restProps
    },
    ref
  ) => {
    const uniqueId = useUniqueId(id, 'date-input-')
    const locale = localeOverride ?? getLocale()

    const maskLocale: Mask | undefined = selectMask(mask, locale)

    return (
      <NumberInput
        ref={ref}
        allowLeadingZeros={true}
        autoComplete={autoComplete}
        format={maskLocale?.pattern?.join(maskLocale?.delimiter)}
        mask={maskLocale?.mask}
        placeholder={maskLocale?.placeholder?.join(maskLocale?.delimiter)}
        prefix={
          disablePrefix
            ? null
            : prefix || (
                <CalendarTodayIcon
                  className='text-gray-100'
                  accessibility='decorative'
                />
              )
        }
        required={required}
        id={uniqueId}
        name={name}
        {...restProps}
      />
    )
  }
)
