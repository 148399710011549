import * as React from 'react'
import { VARIANTS, SIZES } from '../Button/constants'
import {
  ButtonContent,
  ButtonCommonProps,
  useButtonStyles
} from '../ButtonContent'
import { useUniqueId } from '@toasttab/buffet-utils'

/**
 * These are all of the attributes of an <a> combined
 * to the common props
 */
export interface LinkButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    ButtonCommonProps {}

/**
 * The shape of an LinkButton. It allows you to do LinkButton.Variant and LinkButton.Size
 */
interface LinkButtonComponent
  extends React.ForwardRefExoticComponent<LinkButtonProps> {
  displayName?: string
  Variant?: typeof VARIANTS
  Size?: typeof SIZES
}

/**
 * @deprecated - use `<Button as='a' [variant='link'] ...` instead.
 *
 * This component predated the `as` prop on Button.
 * We are deprecating because react-router-dom has a Link component,
 * which makes this LinkButton component name ambiguous.
 **/
export const LinkButton: LinkButtonComponent = React.forwardRef<
  HTMLAnchorElement,
  LinkButtonProps
>(
  (
    {
      variant = 'link',
      iconRight = null,
      iconLeft = null,
      size = 'auto',
      testId = undefined,
      className = '',
      children,
      ...anchorProps
    },
    ref
  ) => {
    testId = useUniqueId(testId, 'button-')
    // eslint-disable-next-line
    const classes = useButtonStyles({
      size,
      hasRightIcon: !!iconRight,
      hasLeftIcon: !!iconLeft,
      variant,
      className,
      disabled: false
    })
    return (
      <a ref={ref} data-testid={testId} className={classes} {...anchorProps}>
        {/* eslint-disable-next-line */}
        <ButtonContent iconRight={iconRight} iconLeft={iconLeft}>
          {children}
        </ButtonContent>
      </a>
    )
  }
)

// eslint-disable-next-line
LinkButton.displayName = 'LinkButton'
// eslint-disable-next-line
LinkButton.Variant = Object.freeze(VARIANTS)
// eslint-disable-next-line
LinkButton.Size = Object.freeze(SIZES)
