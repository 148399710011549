import { useFindPayrollIdsQuery } from '@local/api'

export const useGeneratePayrollLink = (
  toastIdentityGuid: string | null,
  toastRestaurantGuid: string | null
): string | null => {
  const redirectToEEProfile = toastRestaurantGuid && toastIdentityGuid
  const { data } = useFindPayrollIdsQuery({
    variables: {
      toastIdentityGuid: toastIdentityGuid || '',
      toastRestaurantGuid: toastRestaurantGuid || ''
    },
    skip: !redirectToEEProfile
  })
  if (data?.findEmployeeForRestaurantByToastIdentityGuid) {
    const { companyCode, id } =
      data.findEmployeeForRestaurantByToastIdentityGuid
    if (companyCode && id) {
      return `/${companyCode}/employees/${id}/employment`
    }
  }
  return null
}
