import { isSameDay } from '@toasttab/buffet-pui-date-utilities'
import { DateRange } from 'react-day-picker'

/**
 * The default behavior of the calendar picker (via the third-party react-day-picker library)
 * is that a third click will _extend_ a date range. e.g. if you already have a range between
 * May 3 and May 5 and then you click May 7, the resulting range will be May 3 to 7.
 *
 * In buffet, we always want the third click to start a new range. So this logic updates the
 * range set in the calendar to make that happen.
 */
export const getDynamicRangeValue = (
  previousDateRange?: DateRange,
  newDateRange?: DateRange,
  dateClicked?: Date, // This can be undefined in some cases, hence the additional check
  required?: boolean
) => {
  const { from, to } = previousDateRange ?? UNDEFINED_DATE_RANGE
  const isRangeSelected = from && to

  /**
   * We need to be able to let the user clear the date by clicking
   * on either the start or the end of the selected range.
   */
  const shouldClearSelection =
    !required &&
    dateClicked &&
    isRangeSelected &&
    (isSameDay(dateClicked, from) || isSameDay(dateClicked, to))

  if (shouldClearSelection) return undefined

  return isRangeSelected && dateClicked ? { from: dateClicked } : newDateRange
}

const UNDEFINED_DATE_RANGE = { from: undefined, to: undefined }
