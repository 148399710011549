import memoize from 'lodash/memoize'

const formatPart = (str: string) => {
  const [numStr, unitPlural, extra] = str.split(' ')

  if (!numStr || !unitPlural || extra) {
    return str
  }

  const num = parseInt(numStr)

  const pluralTail = unitPlural.slice(-3)

  if (pluralTail !== '(s)') {
    return str
  }

  const unitSingular = unitPlural.slice(0, -3)

  if (num === 1) {
    return `${num} ${unitSingular}`
  }

  return `${num} ${unitSingular}s`
}

const formatTenure = memoize((str: string) => {
  if (!str) return str

  try {
    const parts = str.split(', ')

    return parts.map(formatPart).join(', ')
  } catch {
    return str
  }
})

export default formatTenure
