import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { HelperText, Label } from '@toasttab/buffet-pui-text-base'
import { SelectButtonMultiple } from '@toasttab/buffet-pui-select-button'
import {
  RenderToggleProps,
  SelectBaseProps,
  SelectItem,
  SelectOption
} from '../types'
import { itemToStringFn, itemToValueFn, ItemToValueFn } from '../utils'
import { useSelectMultiple } from '../useSelect'
import { useSelectSearch } from '../useSelectSearch'
import { SelectSearch } from '../SelectSearch'
import { SelectListContainer } from '../SelectListContainer'
import { SelectMultipleListOptions } from './SelectMultipleListOptions'

export type SelectMultipleProps<
  TValue extends any = string,
  TItem extends SelectItem<any> = SelectOption<TValue>
> = SelectBaseProps<TValue, TItem> &
  TestIdentifiable & {
    onChange: (arg: TValue[]) => void
    value?: TValue[]
    variant?: 'chips' | 'comma-separated'
    /** maximum number of chips to display before switching to show the number of chips and the `maxChipsPlaceholder` */
    maxChips?: number
    /** The text to append to the number of selected chips when selected chips meets or exceeds `maxChip` */
    maxChipsPlaceholder?: string
    /** enables wrapping of chips to two or more lines */
    enableMultiLineChips?: boolean
    /** a function used to determine what the value of an object is. Use `itemToValue={item => item}` if you want to use a full option as the value */
    itemToValue?: ItemToValueFn<TValue, TItem>
    /** Display select all option */
    enableSelectAll?: boolean
    /** Alternative label for select all */
    selectAllLabel?: string
  }

export const SelectMultiple = <
  TValue extends any = string,
  TItem extends SelectItem<any> = SelectOption<TValue>
>({
  testId = 'select',
  size = 'auto',
  placement = 'bottom-end',
  placeholder = 'Select one',
  itemToString = itemToStringFn,
  itemToValue = itemToValueFn,
  isCircularKeyboardNav,
  options = [],
  required = false,
  value = [],
  onChange,
  inlineBlock,
  containerClassName,
  label,
  helperIconButton,
  disabled,
  hideLabel,
  renderToggle,
  invalid,
  enableSearch,
  iconLeft,
  transparentBackground,
  searchPlaceholder,
  onSearch, // eslint-disable-line
  filterOptions,
  onSearchChange,
  searchAnyPartOfString,
  renderItem,
  truncateOption,
  additionalActions,
  errorText,
  helperText,
  preserveHelpSpace,
  variant,
  maxChips,
  maxChipsPlaceholder,
  enableMultiLineChips,
  enableSelectAll,
  selectAllLabel,
  id,
  onOpenChange,
  autoFocus,
  ...restProps
}: SelectMultipleProps<TValue, TItem>) => {
  const {
    isOpen,
    setIsOpen,
    activeIndex,
    refs,
    floatingStyles,
    context,
    listRef,
    listContentRef,
    handleSelect,
    testId: _testId,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    getLabelProps,
    selectedItems,
    isAllSelected,
    optionsToRender,
    closeMenu
  } = useSelectMultiple({
    placement,
    testId,
    options,
    itemToValue,
    isCircularKeyboardNav,
    value,
    onChange,
    enableSelectAll,
    enableSearch,
    matchReferenceWidth: !inlineBlock,
    hasLabel: !!label,
    ariaLabel: restProps['aria-label'],
    ariaLabelledBy: restProps['aria-labelledby'],
    disabled,
    onOpenChange
  })

  const { filteredOptions, ...searchProps } = useSelectSearch({
    itemToString,
    options,
    onSearch,
    filterOptions,
    onSearchChange,
    searchAnyPartOfString
  })

  const commonToggleProps = {
    ...getReferenceProps(),
    'data-buffet-select': true
  }

  return (
    <div
      data-testid={testId}
      className={cx(
        {
          'inline-block': inlineBlock
        },
        'relative',
        containerClassName
      )}
    >
      <Label
        {...getLabelProps({
          useInInlineBlock: inlineBlock,
          helperIconButton: helperIconButton,
          required,
          className: hideLabel ? 'sr-only' : undefined
        })}
      >
        {label}
      </Label>

      {!!renderToggle ? (
        <div ref={refs.setReference}>
          {renderToggle({
            ...(commonToggleProps as unknown as RenderToggleProps)
          })}
        </div>
      ) : (
        <SelectButtonMultiple
          testId={testId}
          invalid={invalid}
          isOpen={isOpen}
          disableFocusShadow={enableSearch && isOpen}
          size={size}
          inlineBlock={inlineBlock}
          iconLeft={iconLeft}
          variant={variant}
          placeholder={placeholder}
          itemToString={itemToString}
          selectedItems={selectedItems}
          maxChips={maxChips}
          maxChipsPlaceholder={maxChipsPlaceholder}
          enableMultiLineChips={enableMultiLineChips}
          transparentBackground={transparentBackground}
          ref={refs.setReference}
          autoFocus={autoFocus}
          label={label}
          {...commonToggleProps}
        />
      )}
      <SelectListContainer
        setFloatingRef={refs.setFloating}
        required={required}
        isOpen={isOpen}
        floatingStyles={floatingStyles}
        inlineBlock={inlineBlock}
        getFloatingProps={getFloatingProps}
        context={context}
        hasAdditionalActions={!!additionalActions}
      >
        {enableSearch && (
          <SelectSearch
            {...searchProps}
            searchPlaceholder={searchPlaceholder}
            setIsOpen={setIsOpen}
            onSearch={onSearch}
            enableSelectAll={enableSelectAll}
            isOpen={isOpen}
            activeIndex={activeIndex}
            options={filteredOptions}
            handleSelect={handleSelect}
            testId={`${testId}-search`}
            isMultipleSelect
            hasAdditionalActions={!!additionalActions}
          />
        )}
        <SelectMultipleListOptions
          getItemProps={getItemProps}
          highlightedIndex={activeIndex}
          itemToString={itemToString}
          renderItem={renderItem}
          options={!searchProps.searchTerm ? optionsToRender : filteredOptions}
          itemToValue={itemToValue}
          selectedItems={selectedItems}
          selectAllLabel={selectAllLabel}
          handleSelect={handleSelect}
          showSelectAll={enableSelectAll}
          isAllSelected={isAllSelected}
          isOpen={isOpen}
          truncateOption={truncateOption}
          listRef={listRef}
          listContentRef={listContentRef}
          enableSearch={enableSearch}
        />
        {additionalActions && (
          <div className='px-3 py-2 border-t border-default'>
            {typeof additionalActions === 'function'
              ? additionalActions({ closeMenu })
              : additionalActions}
          </div>
        )}
      </SelectListContainer>
      <HelperText
        testId={`${testId}-helper-text`}
        disabled={disabled}
        invalid={invalid}
        errorText={errorText}
        helperText={helperText}
        preserveHelpSpace={preserveHelpSpace}
      />
    </div>
  )
}
