import * as React from 'react'
import cx from 'classnames'
import {
  Button,
  DayProps,
  useDayRender,
  useDayPicker,
  isDateRange,
  useNavigation,
  useSelectSingle,
  useSelectRange
} from 'react-day-picker'
import { differenceInDays } from '@toasttab/buffet-pui-date-utilities'

type BuffetDayProps = DayProps & {
  isLastInWeek: boolean
  isFirstInWeek: boolean
}
export const BuffetDay = ({
  date,
  displayMonth,
  isFirstInWeek,
  isLastInWeek
}: BuffetDayProps) => {
  const { showOutsideDays, dir, numberOfMonths, mode } = useDayPicker()
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const { goToDate } = useNavigation()
  const selectSingle = useSelectSingle()
  const selectRange = useSelectRange()
  const {
    buttonProps,
    divProps,
    activeModifiers,
    isButton,
    isHidden,
    selectedDays
  } = useDayRender(date, displayMonth, buttonRef)

  const {
    range_start: isRangeStart,
    range_middle: isRangeMiddle,
    range_end: isRangeEnd,
    selected: isSelected,
    today: isToday,
    disabled: isDisabled,
    outside: isOutside
  } = activeModifiers

  const textColor = React.useMemo(() => {
    if (isOutside) {
      if (isSelected && numberOfMonths === 1) {
        if (!isRangeMiddle) {
          return 'text-white'
        }
        return 'text-secondary'
      }
      return 'text-disabled'
    }
    if (isDisabled) return 'text-disabled'
    if (isSelected && !isRangeMiddle) return 'text-white'
    return 'text-default'
  }, [isDisabled, isOutside, isRangeMiddle, isSelected, numberOfMonths])

  const leftEdgeClasses = React.useMemo(() => {
    if (numberOfMonths === 1 && isFirstInWeek) {
      if (isRangeStart) {
        return dir === 'rtl' ? 'mr-6' : 'ml-6'
      } else {
        return dir === 'rtl' ? 'mr-0 xs:pr-6' : 'ml-0 xs:pl-6'
      }
    } else if (isRangeEnd) {
      return dir === 'rtl' ? 'mr-0 xs:pr-0.5' : 'ml-0 xs:pl-0.5'
    } else {
      return ''
    }
  }, [dir, isRangeEnd, isRangeStart, numberOfMonths, isFirstInWeek])

  const rightEdgeClasses = React.useMemo(() => {
    if (numberOfMonths === 1 && isLastInWeek) {
      if (isRangeEnd) {
        return dir === 'rtl' ? 'xs:ml-6' : 'xs:mr-6'
      } else {
        return dir === 'rtl' ? 'ml-0 xs:pl-6' : 'mr-0 xs:pr-6'
      }
    } else if (isRangeStart) {
      return dir === 'rtl' ? 'ml-0 xs:pl-0.5' : 'mr-0 xs:pr-0.5'
    } else {
      return ''
    }
  }, [dir, isRangeEnd, isRangeStart, numberOfMonths, isLastInWeek])

  const outerDivHighlightClasses = React.useMemo(() => {
    if (isOutside && numberOfMonths !== 1) {
      return ''
    }
    if (showOutsideDays || !isOutside) {
      const isSameDay =
        isDateRange(selectedDays) &&
        selectedDays?.from &&
        selectedDays?.to &&
        differenceInDays(selectedDays?.from, selectedDays?.to) === 0
      return cx({
        'bg-primary-0':
          (isRangeStart && !isRangeEnd) ||
          (!isSameDay && isRangeMiddle) ||
          (isRangeEnd && !isRangeStart),
        'rounded-l-full':
          (dir !== 'rtl' && isRangeStart) || (dir === 'rtl' && isRangeEnd),
        'mx-0 px-0.5': isRangeMiddle,
        'rounded-r-full':
          (dir !== 'rtl' && isRangeEnd) || (dir === 'rtl' && isRangeStart)
      })
    }
    return ''
  }, [
    dir,
    isOutside,
    isRangeEnd,
    isRangeMiddle,
    isRangeStart,
    numberOfMonths,
    selectedDays,
    showOutsideDays
  ])

  const outerDivClassName = React.useMemo(
    () =>
      cx(
        'group m-0.5',
        leftEdgeClasses,
        rightEdgeClasses,
        outerDivHighlightClasses
      ),
    [leftEdgeClasses, outerDivHighlightClasses, rightEdgeClasses]
  )

  const dayClassNames = React.useMemo(() => {
    return cx(
      buttonProps.className,
      'relative', // relative allows the focus ring to extend over the following day
      'w-10 h-10 flex items-center justify-center type-default cursor-pointer rounded-full select-none',
      'outline-none',
      isSelected && isOutside && numberOfMonths === 1 && !isRangeMiddle
        ? 'focus-visible:shadow-focus'
        : 'focus-visible:shadow-focus-inset',
      textColor,
      {
        'line-through': isDisabled,
        'font-semibold': isToday,
        'hover:bg-darken-4': !isDisabled,
        'bg-primary-75 outline-none hover:bg-primary-100':
          isSelected && (!isOutside || numberOfMonths === 1) && !isRangeMiddle,
        'pointer-events-none cursor-not-allowed': isDisabled
      }
    )
  }, [
    isDisabled,
    numberOfMonths,
    isOutside,
    isSelected,
    buttonProps.className,
    isRangeMiddle,
    isToday,
    textColor
  ])

  const outsideDayClickHandler = React.useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      goToDate(date)
      if (mode === 'range') {
        selectRange?.onDayClick?.(date, activeModifiers, e)
      } else {
        selectSingle?.onDayClick?.(date, activeModifiers, e)
      }
    },
    [activeModifiers, goToDate, mode, date, selectRange, selectSingle]
  )

  if (isHidden) {
    return (
      <div className={outerDivClassName}>
        <div className='w-10 h-10' />
      </div>
    )
  }
  if (!isButton) {
    return (
      <div className={outerDivClassName}>
        <div
          {...divProps}
          className='flex items-center justify-center w-10 h-10 type-default'
        />
      </div>
    )
  }

  return (
    <div className={outerDivClassName}>
      <Button
        ref={buttonRef}
        {...buttonProps}
        {...(isOutside &&
          numberOfMonths !== 1 && {
            onClick: outsideDayClickHandler
          })}
        className={dayClassNames}
      />
    </div>
  )
}
