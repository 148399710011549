import * as React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { ApolloProvider } from '@apollo/client'
import apolloClient from '../../apolloClient'

const fallback = (
  <div className='p-12'>
    <MerryGoRound />
  </div>
)

type Props = {
  children: React.ReactNode
}

const EmployeeListProviders = ({ children }: Props) => {
  return (
    <ApolloProvider client={apolloClient}>
      <React.Suspense fallback={fallback}>{children}</React.Suspense>
    </ApolloProvider>
  )
}

export default EmployeeListProviders
