import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import {
  format,
  Formats,
  differenceInDays,
  DateRange,
  isSameDay
} from '@toasttab/buffet-pui-date-utilities'

export const sanitizeFormattedValue = (date = '') => date.replace(/[\\/-]/g, '')

interface DateFormatterArgs {
  locale?: Locale
  from: Date
  to: Date
}
export const DateFormatter = ({ from, to, locale }: DateFormatterArgs) => {
  if (differenceInDays(to, from) === 0) {
    // eslint-disable-next-line @toasttab/buffet/date-formats
    return format(from, Formats.date.medium, locale)
  }
  // eslint-disable-next-line @toasttab/buffet/date-formats
  return `${format(from, Formats.date.medium_without_year, locale)} - ${format(
    to,
    Formats.date.medium,
    locale
  )}`
}

const isSameDate = (start?: Date, end?: Date) => {
  if (start && end) return isSameDay(start, end)
  if (!start && end) return false
  if (start && !end) return false
  return true
}
export const isSameRange = (start: DateRange, end: DateRange) => {
  const sameStartingDay = isSameDate(start.from, end.from)
  const sameEndingDay = isSameDate(start.to, end.to)
  return sameStartingDay && sameEndingDay
}
