import * as React from 'react'
import { useTeamPageItems } from '../hooks'
import { useHideEsxElement } from '@toasttab/ec-legacy-esx-hooks'
import { TeamPageHeader } from '../components'
import { withNavV3Page } from './withNavV3Page'
import { getLabel } from '../helpers'

const TeamBulkPayChangeNavigation = () => {
  const { teamPagesByName, teamUrl } = useTeamPageItems()

  useHideEsxElement({ selector: '.panel-heading' })

  const title = getLabel(teamPagesByName['payChange'])

  return (
    <TeamPageHeader backHref={teamUrl} title={title || 'Bulk pay change'} />
  )
}

export default withNavV3Page(TeamBulkPayChangeNavigation)
