import * as React from 'react'
import { Label } from '@toasttab/buffet-pui-text-base'

type Props = {
  label: React.ReactNode
  children: React.ReactNode
}

const Field = ({ label, children }: Props) => {
  return (
    <div className='pb-4'>
      <Label className='display-block type-subhead'>{label}</Label>
      <div className='w-full'>{children}</div>
    </div>
  )
}

export default Field
