export default {
  'pagination-navigation': {
    message: 'Pagination navigation',
    description: 'Label for the pagination navigation'
  },
  'go-to-previous-page': {
    message: 'Go to previous page',
    description: 'Label for the go to previous page button'
  },
  'go-to-next-page': {
    message: 'Go to next page',
    description: 'Label for the go to next page button'
  }
}
