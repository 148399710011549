import * as React from 'react'
import {
  DateStepperContainer,
  DateStepperContainerProps
} from './DateStepperContainer'

export interface DateStepperButtonsProps extends DateStepperContainerProps {
  showDateStepperButtons: boolean
}

/**
 * DateStepperButtons
 * Simple wrapper to return children early if the stepper is not required
 */
export const DateStepperButtons: React.FC<
  React.PropsWithChildren<DateStepperButtonsProps>
> = ({ showDateStepperButtons, ...props }) =>
  showDateStepperButtons ? (
    <DateStepperContainer {...props} />
  ) : (
    <>{props.children}</>
  )
