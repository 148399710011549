import { useQuery } from '@tanstack/react-query'
import { apiCall } from './apiCall'
import type { EmployeeListResp } from './types'

type Args = {
  companyCode: string | undefined
}

const MIN = 60 * 1000

const useEmployeesListInitializeQuery = (args: Args) => {
  const { companyCode } = args

  const query = useQuery({
    queryFn: async () => {
      if (companyCode) {
        const resp = await apiCall<EmployeeListResp>({
          url: `/mvc/${companyCode}/Team/Dashboard/IndexInitialize`,
          method: 'POST',
          body: {
            pageSize: 0
          }
        })

        return resp
      }
      return undefined
    },

    queryKey: ['company', companyCode, 'employeesListInitialize'],

    enabled: !!companyCode,

    staleTime: 5 * MIN,

    cacheTime: 5 * MIN
  })
  return query
}

export default useEmployeesListInitializeQuery
