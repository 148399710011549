import * as React from 'react'
import apolloClient from '../../apolloClient'
import { ApolloProvider } from '@apollo/client'
import TeamOnboardingNavigation from './TeamOnboardingNavigation'
import { useNavigationVersion } from '@toasttab/ec-features'

const TeamOnboardingPage = () => {
  const { version } = useNavigationVersion()

  return (
    <>
      {version === 'v3' && (
        <ApolloProvider client={apolloClient}>
          <TeamOnboardingNavigation />
        </ApolloProvider>
      )}
    </>
  )
}

export default TeamOnboardingPage
