import * as React from 'react'
import { useTeamPageItems } from '../hooks'
import { TeamPageHeader } from '../components'
import { withNavV3Page } from './withNavV3Page'

const TeamBulkPayChangeSetupNavigation = () => {
  const { teamPagesByName } = useTeamPageItems()

  const item = teamPagesByName['payChange']

  const backHref = item?.url

  // todo: match this up to a node in the nav tree

  return <TeamPageHeader backHref={backHref} title={'Bulk pay change'} />
}

export default withNavV3Page(TeamBulkPayChangeSetupNavigation)
