import * as React from 'react'
import {
  TooltipTextLayout,
  TooltipTextLayoutProps
} from '@toasttab/buffet-pui-tooltip'
import { getBuffetConfig } from '@toasttab/buffet-utils'
import cx from 'classnames'

export interface LabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  name?: string
  disabled?: boolean
  required?: boolean
  className?: string
  useInInlineBlock?: boolean
  helperIconButton?: TooltipTextLayoutProps['helperIconButton']
}

/**
 * [View in Storybook]{@link https://doc.toasttab.com/buffet-storybook/index.html?path=/story/abstract-base-text-base-label--label-usage}
 */
export const Label = ({
  helperIconButton,
  children,
  useInInlineBlock,
  ...props
}: LabelProps) => {
  const inputStyle = getBuffetConfig().inputStyle

  if (!children || inputStyle === 'contained') {
    return null
  }

  const label = (
    <BaseLabel
      {...props}
      className={cx(props.className, { 'mb-1': !helperIconButton })}
    >
      {children}
    </BaseLabel>
  )

  const labelWithHelper = helperIconButton ? (
    <TooltipTextLayout
      className='mb-1'
      helperIconButton={helperIconButton}
      disabled={props.disabled}
      iconSize='xs'
    >
      {label}
    </TooltipTextLayout>
  ) : (
    label
  )

  return useInInlineBlock ? <div>{labelWithHelper}</div> : labelWithHelper
}

const BaseLabel: React.FC<LabelProps> = ({
  children,
  name,
  disabled,
  required,
  className,
  ...props
}) => {
  return (
    <label
      className={cx(
        'input-label-text',
        'inline-flex flex-row',
        disabled ? 'text-disabled' : 'input-label-color',
        className
      )}
      htmlFor={name}
      {...props}
    >
      {required && (
        <span
          className={cx('mr-0.5', disabled ? 'text-disabled' : 'text-error')}
        >
          *
        </span>
      )}
      {children}
    </label>
  )
}
