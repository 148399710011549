import * as React from 'react'
import TeamAppProviders from './TeamAppProviders'
import AppRouter from './AppRouter'

const TeamApp = () => {
  return (
    <TeamAppProviders>
      <AppRouter />
    </TeamAppProviders>
  )
}

export default TeamApp
