import * as React from 'react'
import TeamDashboardNavigation from './TeamDashboardNavigation'
import { ApolloProvider } from '@apollo/client'
import apolloClient from '../../apolloClient'
import { useRedirect } from '../../hooks'
import { useNavigationVersion } from '@toasttab/ec-features'
import { useHideEsxElement } from '@toasttab/ec-legacy-esx-hooks'
import { useCompanyCode } from '@toasttab/ec-session'

const TeamDashboardPage = () => {
  const companyCode = useCompanyCode()

  const { version } = useNavigationVersion()

  const hasNavV3 = version === 'v3'

  const shouldRedirect = !!(companyCode && hasNavV3)

  useHideEsxElement({ selector: '.panel-heading', enabled: hasNavV3 })

  useRedirect({
    href: `/${companyCode}/team/employees`,
    enabled: shouldRedirect
  })

  if (hasNavV3) {
    return (
      <ApolloProvider client={apolloClient}>
        <TeamDashboardNavigation />
      </ApolloProvider>
    )
  }

  return null
}

export default TeamDashboardPage
