import * as React from 'react'
import cx from 'classnames'
import { NavLink as RRNavLink } from 'react-router-dom'
import { useUniqueId } from '@toasttab/buffet-utils'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useMenuDropdownContext } from '../MenuDropdown/MenuDropdownContext'
import { useListItemOnKeyDown } from '../MenuDropdown/useListItemOnKeyDown'

const colorVariants = {
  white: {
    childItemClassName: 'hover:bg-darken-4 focus-visible:bg-darken-4',
    childIconClassName: '',
    childTextClassName: ''
  },
  gray: {
    childItemClassName: 'hover:bg-darken-4 focus-visible:bg-darken-4',
    childIconClassName: 'group-hover:text-link',
    childTextClassName: 'group-hover:text-link'
  }
}

type LinkProps = {
  /** react router nav link's to or href if link is external */
  to: string
  /** The button role for accessibility. It will wrap an element with `link` role */
  role?: string
  /** onClick > useful for analytics in this case */
  onClick?: () => void
  children?: React.ReactNode
  /**
   * class name for li (except that there is no li)
   * @deprecated list items have never used li as markup, please use `className` instead
   * */
  liClassName?: string
  iconClassName?: string
  textClassName?: string
  className?: string | ((props: { isActive: boolean }) => string)
  target?: string
} & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'className'>

export interface NavLinkProps extends TestIdentifiable, LinkProps {
  /** whether the link is for a path within the router or for an external path */
  external?: boolean
  /** an icon name > buffet-pui-icon package  */
  icon?: React.ReactElement
  /** The links label */
  label?: string
}

const ExternalLink = ({
  children,
  className = '',
  to,
  ...props
}: LinkProps) => {
  const classNameString =
    typeof className === 'function' ? className({ isActive: false }) : className
  return (
    <a {...props} href={to} className={classNameString}>
      {children}
    </a>
  )
}

const InternalLink = ({ children, ...props }: LinkProps) => {
  return <RRNavLink {...props}>{children}</RRNavLink>
}

/**
 * This is a styled wrapper around the react router nav link button
 */
export const NavLink = ({
  onClick,
  icon,
  label,
  liClassName, // eslint-disable-line
  className,
  iconClassName,
  textClassName,
  to,
  external = false,
  testId,
  ...props
}: NavLinkProps) => {
  const LinkComponent = external ? ExternalLink : InternalLink
  testId = useUniqueId(testId, 'menu-dropdown-item-')

  const { variant } = useMenuDropdownContext()
  const variantProps = colorVariants[variant]

  const linkClassName = cx(
    'whitespace-nowrap',
    'cursor-pointer type-default font-normal',
    'px-3 py-3 md:py-2.5 flex items-center space-x-2',
    'text-default',
    'outline-none',
    'group',
    'hover:no-underline',
    variantProps.childItemClassName,
    liClassName,
    typeof className === 'function' ? className({ isActive: false }) : className
  )

  const onKeyDown = useListItemOnKeyDown<HTMLAnchorElement>({
    onEnter: () => onClick?.()
  })

  return (
    <LinkComponent
      to={to}
      onClick={onClick}
      className={linkClassName}
      data-testid={testId}
      onKeyDown={onKeyDown}
      role='menuitem'
      {...props}
    >
      {icon &&
        React.cloneElement(icon, {
          ...icon.props,
          className: cx(
            'text-secondary',
            variantProps.childIconClassName,
            iconClassName
          )
        })}
      <span className={cx(variantProps.childTextClassName, textClassName)}>
        {label}
      </span>
    </LinkComponent>
  )
}
