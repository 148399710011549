import * as React from 'react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { SelectItemContainer } from './SelectItemContainer'
import { SelectItemDefaultContent } from '../Select/SelectItemDefaultContent'
import {
  RenderItemProps,
  SelectBaseProps,
  SelectItem,
  TruncateOption
} from '../types'
import { isSelectOption, ItemToStringFn } from '../utils'

export type SelectListOptionsProps<
  TValue,
  TItem extends SelectItem<TValue>
> = TestIdentifiable & {
  highlightedIndex: number | null
  selectedIndex: number | null
  options: TItem[]
  /** a function that determines how each item (option) will be rendered */
  renderItem?: (arg: RenderItemProps<TValue, TItem>) => React.ReactElement
  /** a function used to determine what the text value of an object is*/
  itemToString: ItemToStringFn<TValue, TItem>
  getItemProps: (a: any) => any
  /** the label for the whole select */
  label?: SelectBaseProps<TValue, TItem>['label']
  size?: SelectBaseProps<TValue, TItem>['size']
  isOpen: boolean
  /** a prop to set truncate for options 'label' | 'subLabel' | 'labelAndSubLabel' | 'none' . defaults to 'none' */
  truncateOption?: TruncateOption
  handleSelect: (item: TItem) => void
  listRef: React.MutableRefObject<(HTMLElement | null)[]>
  listContentRef: React.MutableRefObject<(string | null)[]>
  hasAdditionalActions?: boolean
  enableSearch?: boolean
}

export const SelectListOptions = <TValue, TItem extends SelectItem<TValue>>({
  testId = 'selectListOptionsContainer',
  highlightedIndex,
  selectedIndex,
  options,
  itemToString,
  getItemProps,
  renderItem,
  label,
  size,
  truncateOption,
  handleSelect,
  listRef,
  listContentRef,
  hasAdditionalActions,
  enableSearch
}: SelectListOptionsProps<TValue, TItem>) => {
  return (
    // a11y guidelines suggest adding a tabIndex here (because the ul can be scrollable). But a) that breaks the tab order and b) the up and down
    // arrows move up and down the options (i.e. they determine scroll by selection rather than acting directly on the ul)
    <ul
      className='overflow-auto p-0 m-0 py-2'
      // 16px matches the "shift: { padding: 8 }" middleware in floating-ui
      style={{ maxHeight: 'min(100vh - 16px, 16rem)' }}
      role='group'
    >
      {options.map((item, index) => {
        const itemLabel = itemToString(item)
        const isItemASelectOption = isSelectOption(item)
        const itemDisabled = isItemASelectOption ? item.disabled : false
        const itemIconLeft = isItemASelectOption ? item.iconLeft : null
        const itemSubLabel = isItemASelectOption
          ? `${item.subLabel || ''}`
          : null
        const itemProps = {
          ...getItemProps({
            // Handle pointer select.
            onClick() {
              if (!itemDisabled) {
                handleSelect(item)
              }
            },
            // Handle keyboard select.
            onKeyDown(event: any) {
              if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault()
                handleSelect(item)
              } else if (event.key === 'Tab' && !hasAdditionalActions) {
                handleSelect(item)
              }
            },
            disabled: itemDisabled,
            // with search enabled we never put the focus on the items themselves
            tabIndex: index === highlightedIndex && !enableSearch ? 0 : -1,
            'aria-selected':
              index === selectedIndex && index === highlightedIndex,
            role: 'option'
          })
        }

        if (renderItem) {
          return renderItem({
            item,
            index,
            itemProps,
            highlightedIndex,
            listRef,
            listContentRef
          })
        }
        return (
          <SelectItemContainer
            testId={`${testId}-option-${index}`}
            key={`${label ?? ''}-${itemLabel}-${index}`}
            itemIconLeft={itemIconLeft}
            size={size}
            index={index}
            highlightedIndex={highlightedIndex}
            selectedIndex={selectedIndex}
            listRef={listRef}
            listContentRef={listContentRef}
            itemProps={itemProps}
          >
            <SelectItemDefaultContent
              label={itemLabel}
              subLabel={itemSubLabel}
              showSubLabel={!!itemSubLabel}
              disabled={itemDisabled}
              truncateOption={truncateOption}
            />
          </SelectItemContainer>
        )
      })}
    </ul>
  )
}
