import * as React from 'react'
import { useTeamPageItems } from '../hooks'
import { TeamPageHeader } from '../components'
import { withNavV3Page } from './withNavV3Page'
import { useHideEsxElement } from '@toasttab/ec-legacy-esx-hooks'
import { getLabel } from '../helpers'

const TeamIncidentPage = () => {
  const { teamPagesByName, teamUrl } = useTeamPageItems()

  useHideEsxElement({ selector: 'h2' })

  const title = getLabel(teamPagesByName['incident'])

  return (
    <TeamPageHeader
      backHref={teamUrl}
      title={title || 'Report employee incident'}
    />
  )
}

export default withNavV3Page(TeamIncidentPage)
