import * as React from 'react'
import cx from 'classnames'

export interface StepperContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  transparentBackground?: boolean
}

export const StepperContainer: React.FC<
  React.PropsWithChildren<StepperContainerProps>
> = ({ children, className, transparentBackground = false, ...props }) => {
  return (
    <div
      className={cx(
        'flex rounded-input',
        transparentBackground ? 'bg-transparent' : 'bg-white',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
