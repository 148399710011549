import { useLastDefinedValue } from '../hooks'
import { usePagination, Pagination } from '@toasttab/buffet-pui-pagination'

type Props = {
  page: number | undefined
  pageCount: number | undefined
  onPageChange: (page: number) => void
  totalRecordsCount?: number
  loading?: boolean
}

const EmployeeListPagination = (props: Props) => {
  const {
    page = 1,
    pageCount,
    onPageChange,
    totalRecordsCount,
    loading
  } = props

  const totalPageCount = useLastDefinedValue(pageCount)

  const paginationProps = usePagination({
    visiblePages: 6,
    currentPage: page - 1,
    shouldWrap: false,
    onChangePage: (index) => onPageChange(index + 1),
    totalPageCount
  })

  const text =
    loading || totalRecordsCount === undefined
      ? 'Loading...'
      : totalRecordsCount === 0
      ? 'No Records'
      : `${totalRecordsCount} Records, ${pageCount} Pages`

  return (
    <div className='flex justify-between'>
      <span className='type-subhead text-secondary p-2'>{text}</span>
      <Pagination {...paginationProps} />
    </div>
  )
}

export default EmployeeListPagination
